import { useEffect, useState } from "react";
import InputDate from "../components/inputDate";
import PINCodeCalculator from "../helper/pincode";

const default_codes = ["914787", "914256", "666666", "914757"];

export default function Pincode() {
	const [ADNumber, setADNumber] = useState("");
	const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

	const [pinCodes, setPinCodes] = useState<string[]>(
		JSON.parse(localStorage.getItem("pinCodes") || JSON.stringify(default_codes)),
	);

	const [bestPinCodes, setBestPinCodes] = useState<string[]>(
		JSON.parse(
			localStorage.getItem("pinCodes-" + new Date().toDateString()) || JSON.stringify([]),
		),
	);

	const getPincode = () => {
		if (ADNumber.length < 6) return "----";

		return PINCodeCalculator.calculatePinCodeFromString(ADNumber + date.replaceAll("-", ""));
	};

	const handleADNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 6) return;

		if (isNaN(Number(e.target.value))) return;

		setADNumber(e.target.value);
	};

	const addPinCode = () => {
		if (ADNumber.length < 6) return;

		const newPinCodes = [...pinCodes, ADNumber];
		setPinCodes(newPinCodes);
		localStorage.setItem("pinCodes", JSON.stringify(newPinCodes));
	};

	const removePinCode = (index: number) => {
		const newPinCodes = pinCodes.filter((_, i) => i !== index);
		setPinCodes(newPinCodes);
		localStorage.setItem("pinCodes", JSON.stringify(newPinCodes));
	};

	useEffect(() => {
		const temp_best = [];

		for (let i = 0; i < 999999; i++) {
			const pin = PINCodeCalculator.calculatePinCodeFromString(
				i.toString().padStart(6, "0") + date.replaceAll("-", ""),
			);

			// Check if all numbers in pin are the same.
			if (pin.toString().padStart(6, "0") === "000000") {
				temp_best.push(i.toString().padStart(6, "0"));
				break;
			}
		}

		setBestPinCodes(temp_best);
	}, []);

	return (
		<div className="flex flex-col flex-1">
			<div className="flex flex-row justify-between mt-20">
				<h1 className="font-bold text-2xl">Pinkod</h1>
				<InputDate
					value={date}
					onChange={(value: string) => setDate(value)}
					placeholder="Datum"
				/>
			</div>

			<div className="flex flex-col flex-1">
				<div className="flex flex-col justify-center items-center">
					<input
						className="border border-slate-200 rounded px-4 py-2 text-5xl placeholder:font-medium placeholder:text-4xl text-center w-full font-bold bg-slate-100 dark:bg-stone-900 dark:border-stone-900 mt-4 dark:text-stone-200 dark:placeholder:text-stone-400"
						placeholder="Anställningsnummer"
						value={ADNumber}
						onChange={(e) => handleADNumberChange(e)}
					/>

					<h1 className="mt-10 text-8xl font-bold">{getPincode()}</h1>
					{ADNumber.length === 6 ? (
						<button
							className="self-end mt-10 rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
							onClick={() => addPinCode()}
						>
							Spara pinkod
						</button>
					) : null}
				</div>
			</div>

			<div className="w-full mb-5">
				<div className="w-full flex flex-1 justify-between gap-2 items-center lg:flex-row flex-col flex-wrap">
					{bestPinCodes.map((item, i) => (
						<div key={"bpc-" + item} className="bg-slate-100 dark:bg-stone-950 cursor-pointer flex-1 w-full flex rounded justify-center min-w-[24%] items-center flex-col py-6">
							<p className="text-lg dark:text-stone-400">{item}</p>
							<p className="font-bold text-6xl">
								{PINCodeCalculator.calculatePinCodeFromString(
									item + date.replaceAll("-", ""),
								)}
							</p>
						</div>
					))}
				</div>
			</div>

			<div className="w-full mb-5">
				<div className="w-full flex flex-1 justify-between gap-2 items-center lg:flex-row flex-col flex-wrap">
					{pinCodes.map((item, i) => (
						<div
							className="bg-slate-100 hover:text-red-500 dark:bg-stone-950 dark:hover:text-red-600 cursor-pointer flex-1 w-full flex rounded justify-center min-w-[24%] items-center flex-col py-6"
							onClick={() => removePinCode(i)}
						>
							<p className="text-lg dark:text-stone-400">{item}</p>
							<p className="font-bold text-6xl">
								{PINCodeCalculator.calculatePinCodeFromString(
									item + date.replaceAll("-", ""),
								)}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
