import { useState, useEffect, Dispatch, SetStateAction } from "react";

type SetPersistentState<T> = Dispatch<SetStateAction<T>>;

function usePersistentState<T>(key: string, initialValue: T): [T, SetPersistentState<T>] {
	const storedValue = localStorage.getItem(key);
	const initial = storedValue ? JSON.parse(storedValue) : initialValue;

	const [value, setValue] = useState<T>(initial);

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
}

export default usePersistentState;
