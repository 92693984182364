// Array of ramps with each ramp four corner points [topLeft, topRight, bottomRight, bottomLeft]
const depot_pos: any = {
	taga: [
		{
			name: "A",
			slots: 31,
			points: [
				[59.45137947357416, 18.083999103309054],
				[59.45148240140498, 18.08424921927789],
				[59.45058882700019, 18.085294217304778],
				[59.450477034864264, 18.085044101335942],
			],
		},
		{
			name: "B",
			slots: 43,
			points: [
				[59.451602012327925, 18.084825382491143],
				[59.45164154729811, 18.085147918097068],
				[59.4504024231252, 18.086340977593427],
				[59.45035641090914, 18.086059345671412],
			],
		},
		{
			name: "C",
			slots: 42,
			points: [
				[59.45164583728342, 18.085161386814192],
				[59.45166560474866, 18.085434972109862],
				[59.45047397515856, 18.086670136024026],
				[59.45040478654156, 18.086365705327378],
			],
		},
		{
			name: "D",
			slots: 16,
			points: [
				[59.45260190327595, 18.085733981852368],
				[59.452729633567166, 18.08603067184829],
				[59.45216548780577, 18.086140621671984],
				[59.45204928680253, 18.085863129264027],
			],
		},
		{
			name: "E",
			slots: 11,
			points: [
				[59.4525735187039, 18.084955606900113],
				[59.45275003489109, 18.084927683135792],
				[59.45278995048409, 18.08578633888871],
				[59.452596581172656, 18.085730491360067],
			],
		},
		{
			name: "VKS",
			points: [
				[59.45213247683685, 18.083364701732062],
				[59.45253892211883, 18.085061941005602],
				[59.4520173638294, 18.085668697054047],
				[59.45154411732538, 18.083817671666996],
			],
		},
		{
			name: "TVÄ",
			points: [
				[59.451817330199745, 18.085666188656063],
				[59.45195070872656, 18.08616291400046],
				[59.45139813711897, 18.08651593264145],
				[59.451321919636875, 18.086266007939866],
			],
		},
		{
			name: "TÄGA",
			points: [
				[59.45270491440962, 18.082739417094245],
				[59.45282984721797, 18.086045144115136],
				[59.450425886648816, 18.087469149654254],
				[59.44997781775659, 18.085578951896153],
			],
		},
		{
			name: "ÅKGA",
			points: [
				[59.491673368992366, 18.276544466763628],
				[59.492974110689325, 18.277750236777557],
				[59.491732869412296, 18.282766601045193],
				[59.49070472626933, 18.278764848702757],
			],
		},
		{
			name: "LIGA",
			points: [
				[59.47330241003703, 18.516591637686513],
				[59.47368079081537, 18.517736296277945],
				[59.472924025021015, 18.518472148229574],
				[59.472612546370556, 18.516968648254323],
			],
		},
		{
			name: "VLGA",
			points: [
				[59.526462091045964, 18.10205813207869],
				[59.527704680905146, 18.102666084955537],
				[59.52789363499062, 18.10538052882933],
				[59.525985597069436, 18.105355815909512],
			],
		},
		{
			name: "DASp",
			points: [
				[59.391409234937214, 18.043066128003833],
				[59.39138330524029, 18.043684022342784],
				[59.38994158287651, 18.043789268084215],
				[59.3899208382179, 18.0432596443652],
			],
		},
	],
	noga: [
		{
			name: "A",
			slots: 32,
			points: [
				[59.76806984592818, 18.692952155011007],
				[59.767802629916034, 18.693274450526285],
				[59.76834873066768, 18.695060544964853],
				[59.76858997851902, 18.694706019898046],
			],
		},
		{
			name: "B",
			slots: 17,
			points: [
				[59.768487282035, 18.69241370165213],
				[59.768349790003946, 18.69183638305369],
				[59.7678767365959, 18.6924558199049],
				[59.76802062208729, 18.692915628173363],
			],
		},
		{
			name: "C",
			slots: 24,
			points: [
				[59.769005478721674, 18.691906077739198],
				[59.76880699386563, 18.692206221695024],
				[59.76918591848054, 18.693451595138214],
				[59.76938891203855, 18.693223127350944],
			],
		},
		{
			name: "TVÄ",
			points: [
				[59.76873363896804, 18.692968881204646],
				[59.768924094042376, 18.693566640870497],
				[59.76868191024172, 18.6937441007713],
				[59.76850556170879, 18.69320705107151],
			],
		},
		{
			name: "VKS",
			points: [
				[59.76847734585711, 18.692777411311674],
				[59.76855258807532, 18.69317436108978],
				[59.76832450957933, 18.69346390092793],
				[59.76822105077886, 18.69308096114199],
			],
		},
		{
			name: "NOGA",
			points: [
				[59.76885098644001, 18.691390326327962],
				[59.769483604661396, 18.69342462544106],
				[59.76834433549769, 18.6950836126857],
				[59.76767883095699, 18.692706637518793],
			],
		},
		{
			name: "RIGA",
			points: [
				[59.749291851730504, 18.37920761637303],
				[59.749706082745114, 18.380450617120285],
				[59.74915916727278, 18.38103839266744],
				[59.748731984464364, 18.379808239473125],
			],
		},
		{
			name: "HAGA",
			points: [
				[60.051199657867876, 18.593706840162174],
				[60.051209515124704, 18.594664476839952],
				[60.049173929133524, 18.595187721622654],
				[60.04933658347551, 18.59376113914906],
			],
		},
		{
			name: "ÄLGA",
			points: [
				[59.970903289132586, 18.805127069678026],
				[59.97050183457043, 18.807867915538996],
				[59.969616792550966, 18.80738173445501],
				[59.9697810289397, 18.805145301468674],
			],
		},
		{
			name: "BLGA",
			points: [
				[59.63152016423917, 18.96149677328295],
				[59.63200885772696, 18.962847126907928],
				[59.63059125395276, 18.96460332451856],
				[59.630195806433115, 18.962802853018584],
			],
		},
		{
			name: "ÖSGA",
			points: [
				[59.834614648721214, 19.07419507785596],
				[59.83509902727387, 19.077648282890884],
				[59.83308738550912, 19.077744677776916],
				[59.83313867533568, 19.075164697003697],
			],
		},
	],
	maga: [
		{
			name: "A",
			points: [
				[59.62932957679194, 17.877257308119386],
				[59.62937752226999, 17.8775114536246],
				[59.62824874505073, 17.878279333793596],
				[59.628236278813965, 17.878013808638894],
			],
		},
		{
			name: "B",
			points: [
				[59.62938692597969, 17.87753207354218],
				[59.62943391246857, 17.877886739284538],
				[59.62836944454302, 17.878611212136057],
				[59.62825639958998, 17.878323857440787],
			],
		},
		{
			name: "C",
			points: [
				[59.629711369322585, 17.878005584812502],
				[59.6297938345647, 17.878455080967253],
				[59.62835584864032, 17.879353358521094],
				[59.62829831230774, 17.878991106345744],
			],
		},
		{
			name: "VKS",
			points: [
				[59.62882744086373, 17.8766195602715],
				[59.62891296775035, 17.877172147977078],
				[59.62838839941517, 17.8775510652592],
				[59.628315415345334, 17.877045842214105],
			],
		},
		{
			name: "MÄGA",
			points: [
				[59.62940964689526, 17.875638646903553],
				[59.62989384507172, 17.87857906811222],
				[59.6283088266207, 17.879544244539495],
				[59.62783217159373, 17.876596341343017],
			],
		},
		{
			name: "UPVp",
			points: [
				[59.52182742955056, 17.90005837363736],
				[59.52153042109654, 17.90131806823783],
				[59.520171419503356, 17.900291839665503],
				[59.52036556594127, 17.89919349773404],
			],
		},
		{
			name: "MÄRSTp",
			points: [
				[59.629762272055395, 17.860421555556478],
				[59.62979784588021, 17.86123657803446],
				[59.62827110199536, 17.861242441505528],
				[59.6282058803221, 17.8605505519199],
			],
		},
	],
	gaga: [
		{
			name: "GÄGA",
			points: [
				[60.66102371834445, 17.185908380099495],
				[60.660540244288704, 17.188736524072098],
				[60.65858244063527, 17.18694374484622],
				[60.65913744749714, 17.184344909844366],
			],
		},
		{
			name: "SAGA",
			points: [
				[60.62645207371474, 16.762728215446607],
				[60.62628732565211, 16.76539897062885],
				[60.625250987895846, 16.765209363262972],
				[60.625128750990555, 16.76274988485985],
			],
		},
		{
			name: "HOGA",
			points: [
				[60.541508228176916, 16.262996659266147],
				[60.54138159516278, 16.26897262039318],
				[60.53927097197389, 16.268736586021916],
				[60.539603404258706, 16.262760624894877],
			],
		},
		{
			name: "GYGA",
			points: [
				[60.29168454411142, 16.88709063669018],
				[60.291631075271546, 16.888290916475597],
				[60.290802297070485, 16.888459495097145],
				[60.290895869855014, 16.88705692096587],
			],
		},
	],
};

export default depot_pos;
