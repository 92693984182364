import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { fetchVehicleMap } from "../../helper/network";
import { CustomMarker } from "../../components/CustomMarker";

export default function VehicleMap() {
	const { data } = fetchVehicleMap();

	return (
		<div className="flex">
			<MapContainer center={[59.45137947357416, 18.083999103309054]} zoom={12}>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{data && data?.length !== 0
					? data.map((marker: any, i: number) => {
							return (
								<CustomMarker
									key={`${marker.latitude}-${i}-${marker.longitude}`}
									position={[marker.latitude, marker.longitude]}
								>
									<div
										className="bg-white"
										style={{
											width: "46px",
											transform: "rotate(" + (marker.heading + 60) + "deg)",
										}}
									>
										<h1 className=" font-bold">{marker.name}</h1>
									</div>
								</CustomMarker>
							);
					  })
					: null}
			</MapContainer>
		</div>
	);
}
