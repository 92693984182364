import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import { fetchInfoPlaylist, fetchInfoPlaylists } from "../helper/network";
import postman from "../helper/postman";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../components/input";
import InputSelect from "../components/inputSelect";
import Textarea from "../components/textarea";

const SLIDE_TYPES: any = [
	["basic", "Bas"],
	["basic-w-image", "Bas med bild"],
	["image", "Bild fullskärm"],
	["video", "Video fullskärm"],
	["ft-eco-ec", "EcoDriving EC"],
	["ft-eco-summary", "EcoDriving område sammanfattning", true],
];

export const DEPOT: any = [
	["taga", "Täbygaraget"],
	["vlga", "Vallentunagaraget"],
	["akga", "Åkersbergagaraget"],
	["marsta", "Märsta"],
	["norrtalje", "Norrtälje"],
];

export default function InfoPlaylist() {
	const { playlistId } = useParams<{ playlistId: string }>();

	const { data, mutate: mutatePlaylist } = fetchInfoPlaylist(playlistId);
	const { mutate: mutatePlaylists } = fetchInfoPlaylists();

	const navigate = useNavigate();

	// Name, description, content, duration.
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [content, setContent] = useState<any[]>([]);
	const [duration, setDuration] = useState("10");

	const [showAddSlide, setShowAddSlide] = useState(false);

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!name || !description) {
			alert("Fyll i alla fält");
			return;
		}

		try {
			const res = await postman.put("/info/playlists/" + playlistId, {
				name,
				description,
				content,
				duration,
			});

			mutatePlaylists(
				(data: any) =>
					data.map((d: any) =>
						d.Id === playlistId
							? {
									Id: playlistId,
									Name: name,
									Description: description,
									Content: content,
									Duration: duration,
							  }
							: d
					),
				false
			);

			mutatePlaylist((data: any) => ({
				...data,
				Name: name,
				Description: description,
				Content: content,
				Duration: duration,
			}));

			// Navigate to the new display.
			navigate("/infoplaylists/" + res.data.Id);
		} catch (error: any) {
			// Check if network error or badrequest.
			if (error?.response?.status === 400) {
				alert("Något gick fel. Kontrollera att du har fyllt i alla fält korrekt");
			} else {
				alert("Något gick fel");
			}
		}
	};

	const handleCreateSlide = async (type: string, data: any) => {
		setContent([...content, { type, data }]);
	};

	const handleDeletePlaylist = async () => {
		if (window.confirm("Är du säker på att du vill radera denna spellista?")) {
			try {
				await postman.delete("/info/playlists/" + playlistId);

				mutatePlaylists((data: any) => data.filter((d: any) => d.Id !== playlistId), false);

				navigate("/infoplaylists");
			} catch (error: any) {
				alert("Något gick fel");
			}
		}
	};

	useEffect(() => {
		if (data) {
			setName(data.Name);
			setDescription(data.Description);
			setContent(data.Content);
			setDuration(data.Duration);
		}
	}, [data]);

	console.log(content);

	if (!data) return <div>Loading...</div>;

	return (
		<div className="flex flex-1 flex-col">
			<form
				className="flex flex-col justify-betweem w-full h-full items-center py-12 px-20"
				onSubmit={handleSubmit}
			>
				<div className="flex flex-col mb-6 self-start">
					<h1 className="font-semibold text-xl">{name}</h1>
					<p className="font-medium text-sm text-gray-600">{description}</p>
				</div>
				<div className="mt-6 w-full">
					<Input placeholder="Namn" value={name} onChange={setName} />
				</div>
				<div className="mt-6 w-full">
					<Input
						placeholder="Beskrivning"
						value={description}
						onChange={setDescription}
					/>
				</div>

				<div className="mt-6 w-full">
					<Input
						placeholder="Slide varaktighet (sekunder)"
						value={duration}
						onChange={setDuration}
					/>
				</div>

				<div className="mt-6 w-full">
					<h2 className="font-bold">Slides</h2>

					<div className="flex flex-col rounded min-h-[10rem] relative">
						<div className="relative">
							{content && content.length ? (
								content.map((slide: any, index: number) => (
									<div
										key={index}
										className="flex flex-col bg-gray-100 rounded px-4 py-2 mt-2"
									>
										{slide.type === "basic" ? (
											<div className="flex flex-col">
												<div className="flex flex-col justify-center items-center">
													<h1 className="font-bold">
														{slide.data["basic-title"]}
													</h1>
													<p className="text-sm mt-1">
														{slide.data["basic-text"]}
													</p>
												</div>
											</div>
										) : null}

										{slide.type === "basic-w-image" ? (
											<div className="flex flex-row">
												<div className="flex flex-col flex-1 justify-center items-center">
													<h1 className="font-medium">
														{slide.data["basic-title"]}
													</h1>
													<p className="text-sm mt-1">
														{slide.data["basic-text"]}
													</p>
												</div>
												<div className="mt-4 flex-1">
													<img
														src={slide.data["image-url"]}
														alt=""
														className="w-full h-40 object-cover rounded"
													/>
												</div>
											</div>
										) : null}

										{slide.type === "image" ? (
											<div className="flex flex-col relative">
												<div className="flex flex-col absolute w-full h-full justify-center items-center">
													{slide.data["basic-title"] ? (
														<h1 className="font-medium text-lg">
															{slide.data["basic-title"]}
														</h1>
													) : null}
													{slide.data["basic-text"] ? (
														<p className="text-sm mt-1">
															{slide.data["basic-text"]}
														</p>
													) : null}
												</div>
												<img
													src={slide.data["image-url"]}
													alt=""
													className="w-full h-40 object-cover rounded mt-4"
												/>
											</div>
										) : null}

										{slide.type === "video" ? (
											<div className="flex flex-col">
												<video
													src={slide.data["video-url"]}
													className="w-full h-40 object-cover"
													muted
													autoPlay
													loop
												/>
											</div>
										) : null}

										{slide.type === "ft-eco-ec" ? (
											<div className="flex flex-col justify-center">
												<h1 className="font-medium text-lg">
													EcoDriving EC {slide.data["ft-eco-ec-depot"]}
												</h1>
											</div>
										) : null}

										{slide.type === "ft-eco-summary" ? (
											<div className="flex flex-col">
												<h1 className="font-medium text-sm">
													{slide.data["ft-eco-summary-depot"]}
												</h1>
											</div>
										) : null}

										<div
											className="bg-gray-200 px-3 text-sm py-1 rounded font-medium cursor-pointer hover:opacity-80 self-end mt-2"
											onClick={() => {
												setContent(content.filter((c) => c !== slide));
											}}
										>
											Radera
										</div>
									</div>
								))
							) : (
								<p className="text-sm text-gray-400">Finns inga slides ännu</p>
							)}
							<CreateSlide
								show={showAddSlide}
								onCreate={handleCreateSlide}
								setClose={() => setShowAddSlide(false)}
							/>
						</div>
						{!showAddSlide ? (
							<div
								className="bg-gray-100 self-start px-4 py-1 font-medium text-sm mt-4 rounded hover:opacity-80 cursor-pointer"
								onClick={() => setShowAddSlide(true)}
							>
								Lägg till slide
							</div>
						) : null}
					</div>
				</div>

				<div className="flex flex-col ] mt-10 justify-center items-center">
					<input
						type="submit"
						className="bg-blue-200 px-6 py-2 rounded font-medium cursor-pointer hover:opacity-80"
						value="Spara ändringar"
					/>
					<div
						className="bg-red-300 px-6 py-2 rounded font-medium cursor-pointer hover:opacity-80 mt-4"
						onClick={() => {
							handleDeletePlaylist();
						}}
					>
						Radera spellista
					</div>
				</div>
			</form>
		</div>
	);
}

const CreateSlide = ({
	oldData = {},
	show,
	onCreate,
	setClose,
}: {
	oldData?: any;
	show: boolean;
	onCreate: (type: string, data: any) => void;
	setClose: () => void;
}) => {
	const [type, setType] = useState("basic");
	const [data, setData] = useState<any>({ ...oldData });

	const handleSubmit = (e: any) => {
		e.preventDefault();

		if (!type) {
			alert("Fyll i alla fält");
			return;
		}

		onCreate(type, data);
		setClose();

		// Reset data.
		setType("basic");
		setData({});
	};

	const handleSetData = (new_data: any, name: string) => {
		setData({ ...data, [name]: new_data });
	};

	return show ? (
		<div className="py-6 rounded bg-white z-50 w-full flex flex-col justify-center">
			<p className="mb-5 font-semibold text-sm">Skapa slide</p>
			<InputSelect
				items={SLIDE_TYPES}
				onChange={(value: string) => setType(value)}
				placeholder="Typ"
				value={type}
				required
			/>

			{type === "basic" ? (
				<div className="flex flex-col mt-6">
					<Input
						placeholder="Titel"
						value={data["basic-title"]}
						onChange={handleSetData}
						name="basic-title"
						required
					/>
					<div className="mt-4">
						<Textarea
							placeholder="Text"
							value={data["basic-text"]}
							onChange={handleSetData}
							name="basic-text"
						/>
					</div>
				</div>
			) : null}

			{type === "basic-w-image" ? (
				<div className="flex flex-col mt-6">
					<Input
						placeholder="Titel"
						value={data["basic-title"]}
						onChange={handleSetData}
						name="basic-title"
						required
					/>
					<div className="mt-4">
						<Textarea
							placeholder="Text"
							value={data["basic-text"]}
							onChange={handleSetData}
							name="basic-text"
						/>
					</div>
					<div className="mt-4">
						<Input
							placeholder="Bild URL"
							value={data["image-url"]}
							onChange={handleSetData}
							name="image-url"
							required
						/>
					</div>
				</div>
			) : null}

			{type === "image" ? (
				<div className="flex flex-col mt-6">
					<Input
						placeholder="Titel"
						value={data["basic-title"]}
						onChange={handleSetData}
						name="basic-title"
						required
					/>
					<div className="mt-4">
						<Textarea
							placeholder="Text"
							value={data["basic-text"]}
							onChange={handleSetData}
							name="basic-text"
						/>
					</div>
					<div className="mt-4">
						<Input
							placeholder="Bild URL"
							value={data["image-url"]}
							onChange={handleSetData}
							name="image-url"
							required
						/>
					</div>
				</div>
			) : null}

			{type === "video" ? (
				<div className="flex flex-col mt-6">
					<Input
						placeholder="Video URL"
						value={data["video-url"]}
						onChange={handleSetData}
						name="video-url"
						required
					/>
				</div>
			) : null}

			{type === "ft-eco-ec" ? (
				<div className="flex flex-col mt-6">
					<InputSelect
						items={DEPOT}
						placeholder="Depå"
						value={data["ft-eco-ec-depot"]}
						onChange={handleSetData}
						name="ft-eco-ec-depot"
						required
					/>
				</div>
			) : null}

			{type === "ft-eco-summary" ? (
				<div className="flex flex-col mt-6">
					<InputSelect
						items={DEPOT}
						placeholder="Depå"
						value={data["ft-eco-summary-depot"]}
						onChange={handleSetData}
						name="ft-eco-summary-depot"
						required
					/>
				</div>
			) : null}

			<div
				className="bg-blue-200 px-3 text-sm py-1 rounded font-medium cursor-pointer hover:opacity-80 mt-4 self-center"
				onClick={handleSubmit}
			>
				Lägg till slide
			</div>
		</div>
	) : null;
};
