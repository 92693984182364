import { useState } from "react";
import BUS_TYPES from "../../data/vehicles/bustypes";
import Bus from "../../components/bus";
import InputSelect from "../../components/inputSelect";
import InputMultiSelect from "../../components/inputMultiSelect";
import Input from "../../components/input";

/* 


	useEffect(() => {
		const busses: any = {};

		for (let i = 0; i < test.length; i++) {
			const row: any = test[i];
			for (let j = 0; j < row.vehicles.length; j++) {
				const bus = row.vehicles[j];
				busses[bus] = row.type.replace(" ", "-") + "-" + row.color;
			}
		}

		console.log(JSON.stringify(busses, undefined, 4));
	}, []);

*/

const PRETTY_BUS_TYPES: any = {
	"MAN-led-blue": "MAN led blå",
	"MAN-led-red": "MAN led röd",
	"Volvo-boggi-red": "Volvo boggi röd",
	"Volvo-boggi-blue": "Volvo boggi blå",
	"Volvo-dd-blue": "Volvo dubbeldäckare blå",
	"Volvo-kort-red": "Volvo kort röd",
	"VDL-kort-red": "VDL kort röd",
	"BYD-el-red": "BYD el röd",
	"Scania-led-red": "Scania led röd",
};

const validBussNummer = (busNumber: string) => {
	if (BUS_TYPES[busNumber]) return true;

	return false;
};

const CATEOGIRES = [
	["driverSeat", "Förarplats"],
	["doors", "Dörrar"],
	["windows", "Fönster"],
	["exterior", "Plåt"],
	["interior", "Interiör"],
	["lights", "Lampor"],
	["wheels", "Däck"],
	["engine", "Motor"],
	["other", "Övrigt"],
];

export default function VehicleDamageReport() {
	const [busNumber, setBusNumber] = useState("5100");
	const [view, setView] = useState<"top" | "left" | "right" | "front" | "back">("top");

	const [whereValue, setWhereValue] = useState("");
	const [catValue, setCatValue] = useState("");

	const handleSubmit = (e: any) => {
		e.preventDefault();
	};

	const handleBusNumberChange = (e: any) => {
		const value = e.target.value;

		// Check if number and max 4 digits.
		if (value.length !== 0 && (value.length > 4 || !/^\d+$/.test(value))) return;

		setBusNumber(value);
	};

	return (
		<div className="flex flex-col flex-1">
			<h1 className="font-bold text-2xl mt-20">Skaderapportering</h1>

			<div className="flex flex-col flex-1">
				<form className="flex flex-col flex-1" onSubmit={(e) => handleSubmit(e)}>
					<div className="flex flex-row justify-center items-center">
						<input
							className="border border-slate-200 rounded px-4 py-2 text-5xl placeholder:font-medium placeholder:text-4xl text-center w-full font-bold bg-slate-100 mt-4"
							placeholder="Vagnsnummer"
							value={busNumber}
							onChange={(e) => handleBusNumberChange(e)}
						/>
					</div>
					{validBussNummer(busNumber) ? (
						<>
							<h2 className="text-lg font-bold my-2 mt-6 flex">
								{PRETTY_BUS_TYPES[BUS_TYPES[busNumber]]}
							</h2>
							{/* <div className="flex flex-row justify-between items-center">
								<Bus id={BUS_TYPES[busNumber]} view={view} setView={setView}/>
							</div> */}
							<div className="flex flex-col justify-center items-center">
								<select
									className="appearance-none cursor-pointer border border-slate-200 text-slate-400 rounded px-4 py-6 text-lg placeholder:font-medium placeholder:text-2xl text-center w-full font-regular bg-slate-100 mt-4"
									onChange={(e) => setCatValue(e.target.value)}
									value={catValue}
								>
									<option disabled value="">
										Välj kateogri
									</option>
									{CATEOGIRES.map(([key, value]) => (
										<option className="font-medium text-slate-400" key={key} value={key}>
											{value}
										</option>
									))}
								</select>
								<input
									className="border border-slate-200 rounded px-4 py-6 text-lg placeholder:font-medium placeholder:text-2xl text-center w-full font-regular bg-slate-100 mt-4"
									placeholder="Beskriv skada"
									value={whereValue}
									onChange={(e) => setWhereValue(e.target.value)}
								/>
								<div className="flex mt-4 w-full relative">
									<p className="absolute w-full text-center mt-6 text-slate-400 font-medium text-2xl pointer-events-none">Lägg till bild</p>
									<input
										type="file"
										name="picture"
										accept="image/*"
										capture="user"
										onChange={(e) => console.log(e.target.value)}
										className="border border-slate-200 rounded px-4 py-10 bg-slate-100 text-center w-full font-regular"
									/>
								</div>
							</div>
							<input
								type="submit"
								value={"Skapa rapport"}
								className="bg-slate-50 text-slate-400 hover:bg-slate-100 hover:text-slate-500 transition-colors ease-in rounded-lg px-10 py-2 mt-10 max-w-xs mx-auto cursor-pointer"
							/>
						</>
					) : null}
				</form>
			</div>
		</div>
	);
}
