const DataFollowupSteps = ({
	steps,
	selected,
}: {
	steps: { key: string; value: string }[];
	selected: string;
}) => {
	return (
		<div className="flex w-full justify-between mt-1 mb-2">
			{steps.map((step) => (
				<span
					key={step.key}
					className={`flex flex-1 text-center rounded text-sm font-semibold leading-4 justify-center items-center mx-1 px-5 py-2 ${
						selected === step.key ? "text-blue-500 bg-blue-100 dark:bg-slate-900" : "bg-stone-100 dark:bg-stone-950"
					}`}
				>
					{step.value}
				</span>
			))}
		</div>
	);
};

export default DataFollowupSteps;
