import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";
import { parseJSON } from "../helper/storage";
import Input from "../components/input";
import { checkIfIsPartialTrip } from "../helper/trips";

export default function TripProgressChecker() {
	const [view, setView] = useState<"start" | "loading" | "success" | "error">("start");

	const [tripsStats, setTripsStats] = useState({ bookingTripsDriven: 0, totalBookingTrips: 0 });
	const [selectedLine, setSelectedLine] = useState("574");

	const handleTrips = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		if (!selectedLine) {
			alert("Skriv en linje");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const data = parseJSON(evt.target.result);

				const unique_trips_map: any = {};
				const unique_trips_keys: string[] = [];

				for (let i = 0; i < data.length; i++) {
					const call = data[i];

					// Check if line is selected.
					if (call.LineNameLong !== selectedLine) {
						console.log("Line not selected", call.LineNameLong, selectedLine);
						continue;
					}

					if (
						(call.JourneyName.charAt(0) !== "1" &&
							call.JourneyName.charAt(0) !== "2") ||
						call.JourneyName.length !== 5
					) {
						continue;
					}

					// Check if call is partial trip.
					if (
						!checkIfIsPartialTrip(
							new Date(
								call.PlannedDepartureTime
									? call.PlannedDepartureTime
									: call.PlannedArrivalTime
							),
							call.LineNameLong,
							call.JourneyName
						)
					) {
						continue;
					}

					// Unique key for each trip
					const trip_key =
						call.OperatingCalendarDay + call.LineNameLong + call.JourneyName;

					// Check if call already exists in map
					if (unique_trips_map[trip_key]) {
						unique_trips_map[trip_key].push(call);
					} else {
						unique_trips_map[trip_key] = [call];

						// Add new key to list.
						unique_trips_keys.push(trip_key);
					}
				}

				// Loop through each unqiue trip and check many calls have progress.

				let bookingTripsDriven = 0;

				for (let i = 0; i < unique_trips_keys.length; i++) {
					let trip = unique_trips_map[unique_trips_keys[i]].sort((a: any, b: any) => {
						if (a.JourneyName.charAt(0) === "1") {
							return a.SequenceInJourney - b.SequenceInJourney;
						} else {
							return b.SequenceInJourney - a.SequenceInJourney;
						}
					});

					// Index at last stop they have to be at.
					let lastStopIndex = trip.findIndex(
						(call: any) => call.StopPointName === "Odensala ip"
					);

					if (lastStopIndex === -1) {
						lastStopIndex = trip.findIndex((call: any) => call.StopPointName === "Rom");
					}

					let optionalStopsDriven = 0;

					trip.slice(lastStopIndex).map((call: any) => {
						if (
							(call.PlannedDepartureTime &&
								call.ActualDepartureTime) ||
							(call.PlannedArrivalTime &&
								call.ActualArrivalTime)
						) {
							optionalStopsDriven++;
						}
					});

					if (optionalStopsDriven > 0) {
						bookingTripsDriven++;
					}
				}


				setTripsStats({
					bookingTripsDriven,
					totalBookingTrips: unique_trips_keys.length,
				});

				setView("success");
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const reset = () => {
		setView("start");
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-20">Special trips helper</h1>
			<p className="mb-3 text-sm">Skapa rapport om turer med godkända avvikelser</p>
			<View show={view === "start"}>
				<p className="my-6 text-sm font-semibold">Trips filen </p>

				<div className="mb-2">
					<Input
						name="line"
						placeholder="Linje"
						value={selectedLine}
						onChange={setSelectedLine}
					/>
				</div>
				<InputFile name="apcdata" accept=".json" onHandleFile={handleTrips} />
			</View>
			<View show={view === "success"}>
				<p className="mt-6 font-semibold">Hantering av filer lyckades!</p>

				<div className="flex flex-col self-start mt-6">
					<p>Totalt Bokningsturer: {tripsStats.totalBookingTrips}</p>
					<p>Bokningsturer som körts delvis: {tripsStats.bookingTripsDriven}</p>
					<p>Andel turer: {((tripsStats.bookingTripsDriven / tripsStats.totalBookingTrips) * 100).toFixed(2)}%</p>
				</div>
			</View>
			<View show={view === "error"}>
				<p className="mt-6 font-semibold text-red-400">Något gick fel!</p>
				<p className="mt-2 text-xs">Ett okänt fel inträffade...</p>
				<button
					className="mt-10 text-xs font-semibold py-3 px-6 bg-slate-200 self-start rounded dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => reset()}
				>
					Försok igen
				</button>
			</View>
		</>
	);
}
