export const safeUserStorage = () => {
	const localStorageUser = localStorage.getItem("user");

	if (
		localStorageUser === "tx" ||
		localStorageUser === "admin" ||
		localStorageUser === "mrnoll"
	) {
		localStorage.removeItem("user");
	}

	return localStorageUser;
};

export const parseJSON = (bstr: string) => {
	return JSON.parse(bstr, (key, value) => {
		if (typeof value === "string") {
			return decodeURIComponent(escape(value));
		}
		return value;
	});
};
