import useFormatSWR from "./format";
import postman from "./postman";

export const vehicleAutoFollowUp = (vehicles: any) =>
	postman.post("/vehicleautofollowup", vehicles);

export const vehicleTinder = (vehicles: any) => postman.post("/vehicletinder", vehicles);

export const fetchVehicleDepotParking = (depot: string) => postman.get("/vehiclesparking/" + depot);

export const fetchResolvedTicketMachines = (cooke: string, startDate: string, endDate: string) =>
	postman.post(
		`/ticketmachines/resolved/${startDate}/${endDate}`,
		JSON.stringify({ cookie: cooke })
	);

export const fetchInfoDisplys = () => useFormatSWR("/info/displays");

export const fetchInfoDisplay = (id: string | undefined) =>
	useFormatSWR(id ? "/info/displays/" + id : null);

export const fetchInfoPlaylists = () => useFormatSWR("/info/playlists");

export const fetchInfoPlaylist = (id: string | undefined) =>
	useFormatSWR(id ? "/info/playlists/" + id : null);

export const fetchUsers = () => useFormatSWR("/users");

export const fetchUser = (user: string | undefined) => useFormatSWR(user ? "/users/" + user : null);

export const fetchVehicleMap = () => useFormatSWR("/vehiclemap");
