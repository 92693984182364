class CRC16 {
	private static readonly ODD_PARITY: number[] = [0, 1, 1, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0];

	private constructor() {}

	static calculateCRC(dataToCrc: number): number {
		return CRC16.calculateCRCWithSeed(dataToCrc, 0);
	}

	static calculateCRCWithSeed(dataToCrc: number, seed: number): number {
		let num1: number = (dataToCrc ^ (seed & 0xff)) & 0xff;
		seed = (seed & 0xffff) >> 8;
		let index1: number = num1 & 0x0f;
		let index2: number = num1 >> 4;

		if ((CRC16.ODD_PARITY[index1] ^ CRC16.ODD_PARITY[index2]) === 1) {
			seed ^= 49153;
		}

		let num2: number = num1 << 6;
		seed ^= num2;
		let num3: number = num2 << 1;
		seed ^= num3;

		return seed;
	}

	static computeCRC(dataToCrc: number[]): number {
		return CRC16.computeCRCWithSeed(dataToCrc, 0, dataToCrc.length, 0);
	}

	static computeCRCWithSeed(dataToCrc: number[], off: number, len: number, seed: number): number {
		for (let index: number = 0; index < len; ++index) {
			seed = CRC16.calculateCRCWithSeed(dataToCrc[index + off], seed);
		}
		return seed;
	}

	static calculateCRCForByteArray(dataToCrc: number[], seed: number): number {
		return CRC16.computeCRCWithSeed(dataToCrc, 0, dataToCrc.length, seed);
	}

	static calculateCRCForString(dataToCrc: string, seed: number): number {
		const dataToCrc1: number[] = Array.from(new TextEncoder().encode(dataToCrc));
		return CRC16.computeCRCWithSeed(dataToCrc1, 0, dataToCrc1.length, seed);
	}
}

export default class PINCodeCalculator {
	private static convertStringToByteArray(str: string): number[] {
		return Array.from(new TextEncoder().encode(str));
	}

	static calculatePinCodeFromString(str: string): string {
		return PINCodeCalculator.calculatePinCodeFromByteArray(
			PINCodeCalculator.convertStringToByteArray(str)
		).toString().padStart(4, "0");
	}

	static calculatePinCodeFromByteArray(aNr: number[]): number {
		return CRC16.computeCRC(aNr) % 10000;
	}
}
