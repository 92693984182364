export default function Modal({
	show,
	children,
	closeModal,
}: {
	show: boolean;
	children: any;
	closeModal: any;
}) {
	return show ? (
		<div className="-top-6 left-0 absolute w-full h-full flex justify-center items-center">
			<div className="bg-white shadow-md z-10 rounded mb-20 dark:bg-stone-950">{children}</div>
			<div
				className="top-0 left-0 absolute w-full h-full bg-black opacity-20"
				onClick={() => closeModal()}
			/>
		</div>
	) : null;
}
