import bg from "../assets/img0.jpg";

export default function TLSimulator() {
	const handleSetDriverFile = (file: any) => {};

	const handleTripsFile = (file: any) => {};

	/* 
	
	Lösa mattetal
	Hålla koll på något som rör sig
	VIlket ord s9j är rättstavat
	Timer

	*/

	return (
		<>
			<div
				className="flex flex-col w-full h-full bg-cover bg-center relative"
				style={{ backgroundImage: `url(${bg})` }}
			>
				<MovableWindow
					title="Hastus - Vehicle Assignments"
					style="w-[600px] h-[500px] left-5 top-7"
				>
					<div className="flex flex-col">
						<div className="flex flex-col">
							{[...Array(50)].map((_, i) => (
								<div className="flex flex-row">
									<div className="flex flex-row"></div>
								</div>
							))}
						</div>
						<div className="flex flex-col"></div>
						<div className="flex flex-col"></div>
					</div>
				</MovableWindow>

				<MovableWindow title="Chrome | Compass" style="w-96 h-96 right-10 top-3">
					<p></p>
				</MovableWindow>

				<MovableWindow
					title="Consat trafik studio - Radio"
					style="w-[500px] h-[300px] bottom-14 left-40"
				>
					<p></p>
				</MovableWindow>

				<MovableWindow
					title={"Hastus - TBYSTR"}
					style="w-[840px] h-[580px] bottom-12 right-5"
				>
					<p></p>
				</MovableWindow>

				<div className="w-full h-14 absolute bottom-0 left-0 bg-black/[0.9]"></div>
			</div>
		</>
	);
}

const MovableWindow = ({
	title,
	children,
	style,
}: {
	title: string;
	children: any;
	style: string;
}) => {
	return (
		<div className={"absolute flex flex-col bg-gray-100 border-gray-300 rounded " + style}>
			<div className="flex items-center justify-between w-full h-10 bg-white rounded-t-md cursor-pointer">
				<p className="ml-4 font-semibold">{title}</p>
			</div>
			<div className="flex items-center justify-center flex-grow">{children}</div>
		</div>
	);
};
