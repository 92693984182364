export function mergeTripsAndData(wsJSON: any, trips: any, allTrips: boolean, newOutput: boolean) {
	const STATISTICS = {
		rows: 0,
		rowsFilled: 0,
		rowsAnrop: 0,
		trends: [],
	};

	const FILTERED_TRIPS: any = [];

	for (let i = 0; i < wsJSON.length; i++) {
		if (wsJSON[i]["Avgångar helt utförda"] === 0) {
			if (allTrips || wsJSON[i]["Ej utförda avgångar"] === 1) {
				FILTERED_TRIPS.push(wsJSON[i]);
			}
		}
	}

	const FILLED_TRIPS: any = [];

	for (let i = 0; i < FILTERED_TRIPS.length; i++) {
		STATISTICS.rows = STATISTICS.rows + 1;

		let ROW = FILTERED_TRIPS[i];
		const DAY = trips[ROW["Trafikdygn"]];

		if (!DAY) {
			FILLED_TRIPS.push(modifyRow(ROW, "", "", "", newOutput));
			continue;
		}

		const LINE = DAY[ROW["Linje"]];

		if (!LINE) {
			FILLED_TRIPS.push(modifyRow(ROW, "", "", "", newOutput));
			continue;
		}

		const TRIP = LINE[ROW["Tur"]];

		if (!TRIP) {
			FILLED_TRIPS.push(modifyRow(ROW, "", "", "", newOutput));
			continue;
		}

		STATISTICS.rowsFilled = STATISTICS.rowsFilled + 1;

		const COMMENT = checkIfSpecialTrip(TRIP, ROW["Tur"], ROW["Linje"]);

		if (COMMENT !== "") {
			STATISTICS.rowsAnrop = STATISTICS.rowsAnrop + 1;
		}

		FILLED_TRIPS.push(
			modifyRow(
				ROW,
				TRIP.block === "" ? "" : parseInt(TRIP.block),
				TRIP.vehicles === "" ? "" : parseInt(TRIP.vehicles),
				COMMENT,
				newOutput
			)
		);
	}

	return [FILLED_TRIPS, STATISTICS];
}

export const checkIfSpecialTrip = (trip: any, tripName: string, line: string) => {
	if ((tripName === "11605" && line === "626") || (tripName === "20630" && line === "626")) {
		return "Almgrens";
	}

	if (
		trip.block.slice(-2) === "99" ||
		trip.block === "55101" ||
		trip.block === "55107" ||
		trip.block === "55110" ||
		trip.block === "55801" ||
		trip.block === "56101" ||
		trip.block === "56801" ||
		trip.block === "15104" ||
		trip.block === "15106" ||
		trip.block === "15801" ||
		trip.block === "16101" ||
		trip.block === "16801"
	) {
		return "Anrop";
	}

	return "";
};

const modifyRow = (
	row: any,
	block: number | string,
	vehicle: number | string,
	comment: string,
	newOutput: boolean
) => {
	if (newOutput) {
		return [
			row["Trafikdygn"],
			row["Linje"],
			row["Tur"],
			row["StartTid"],
			block,
			vehicle,
			row["Avgångar delvis utförda"]
				? "Delvis registrerad"
				: "Ej registerad" + (row["InställdAvgång"] === "1" ? ", Inställd avgång" : ""),
			row["Completeness"],
			comment === "Almgrens" ? "Ja" : "",
			comment,
			comment === "Almgrens" ? "Nej" : "",
		];
	} else {
		return [
			row["Avtal"],
			row["AvtalsOmråde"],
			row["Period"],
			row["Trafikdygn"],
			row["DatedVehicleJourneyGid"],
			row["Tur"],
			row["Linje"],
			row["Riktning"],
			row["StartTid"],
			"",
			block,
			vehicle,
			comment,
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			row["Planerad sträcka"],
			row["Planerad tid"],
		];
	}
};

export const calculateVehicleTripsTimespan = (trips: any[][]) => {
	const vehicles: { vehicle: string; start: number; end: number }[] = [];

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];
		const vehicle = trip[11];

		if (vehicle === "") {
			continue;
		}

		// const start = trip[8].toString();

		const rawStartDate = trip[3].toString();
		const rawStartTime = trip[8].toString().split(":");
		const start = new Date(
			rawStartDate.slice(0, 4),
			parseInt(rawStartDate.slice(4, 6)) - 1,
			rawStartDate.slice(6, 8),
			parseInt(rawStartTime[0]),
			parseInt(rawStartTime[1])
		).getTime();

		const end = new Date(start + trip[20] * 1000).getTime();

		vehicles.push({ vehicle, start, end });
	}

	return vehicles;
};

const BOOKING_TRIPS: any = {
	649: {
		21356: true,
	},
	"640Z": {
		21923: true,
		11900: true,
		21336: true,
		11313: true,
		21117: true,
		11054: true,
		20923: true,
		10900: true,
	},
	646: {
		10905: true,
		11710: true,
	},
	"648Z": {
		10900: true,
		11100: true,
		11300: true,
		11900: true,
		20923: true,
		21123: true,
		21323: true,
		21923: true,
	},
	"648Y": {
		10622: true,
		11503: true,
	},
	"653V": {
		10848: true,
		11143: true,
		11413: true,
		20930: true,
		21225: true,
		21455: true,
	},
	662: {
		10807: true,
		20833: true,
	},
	663: {
		11529: true,
		11614: true,
	},
	667: {
		10824: true,
		11249: true,
		11745: true,
		20756: true,
		21220: true,
		21712: true,
	},
	"669Z": {
		10908: true,
		11103: true,
		11330: true,
		11903: true,
		20931: true,
		21126: true,
		21353: true,
		21926: true,
	},
};

const PARTIALTRIPS: any = {
	632: {
		11645: true,
		11846: true,
	},
	634: {
		11718: true,
		12049: true,
		12021: true, // Söndagar
		12150: true,
	},
	636: {
		11448: true,
		12020: true,
	},
	649: {
		11326: true,
		21356: true,
		11505: true,
	},
	574: {
		11421: true,
		11241: true,
		21320: true,
		11352: true,
		21440: true,
		11537: true,
		21619: true,
	},
};

const EXTERNALTRIPS: any = {
	626: {
		11605: true,
		20630: true,
		20644: true,
		11755: true,
		20928: true,
	},
};

export const checkIfIsBookingTrip = (linje: string, tur: string) => {
	if (BOOKING_TRIPS[linje] && BOOKING_TRIPS[linje][tur]) {
		return true;
	}

	return false;
};

export const checkIfExternalTrip = (linje: string, tur: string) => {
	if (EXTERNALTRIPS[linje] && EXTERNALTRIPS[linje][tur]) {
		return true;
	}

	return false;
};

export const checkIfIsPartialTrip = (date: Date, linje: string, tur: string) => {
	if (PARTIALTRIPS[linje] && PARTIALTRIPS[linje][tur]) {
		return true;
	}

	if (linje === "574") {
		// Check if trip starts after 17:55.
		const hours = date.getHours();
		const minutes = date.getMinutes();

		if (hours > 17 || (hours === 17 && minutes >= 55)) {
			return true;
		}
	}

	return false;
};
