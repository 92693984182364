import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { createXLSXCopyString, getWSJSON } from "../helper/xlsx";
import { checkIfIsBookingTrip, checkIfIsPartialTrip } from "../helper/trips";

export default function APCHelper() {
	const [view, setView] = useState<"start" | "loading" | "success" | "error">("start");

	// APC data.
	const [APCData, setAPCData] = useState<any>(null);

	// Cancelled data.
	const [cancelled, setCancelled] = useState<any>(null);

	// Successfull

	// New copy string.
	const [newCP, setNewCP] = useState("");

	const handleAPCData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				// Parse data
				const bstr = event.target.result;

				const apc = getWSJSON(bstr);

				// Unique trips, 1 sequenceInTrip per trip per line per day.
				const realTrips: any = [];

				for (let i = 0; i < apc.length; i++) {
					const row: any = apc[i];
					const day = new Date(2023, 0, parseInt(row["operatingDay"]) - 44925)
						.toISOString()
						.split("T")[0];
					const trip = row["trip"];

					if (trip.toString().charAt(0) === "1" || trip.toString().charAt(0) === "2") {
						const { __rowNum__, ...rest } = row;

						realTrips.push({
							contract: "",
							line: "",
							trip: "",
							sequenceInTrip: "",
							stopPoint: "",
							stopPointName: "",
							vehicle: "",
							plannedArrivalTime: "",
							plannedDepartureTime: "",
							disturbance: "",
							disturbanceType_2: "",
							disturbanceType_3: "",
							disturbanceType_5: "",
							disturbanceType_6: "",
							disturbanceType_7: "",
							disturbanceType_8: "",
							...rest,
							operatingDay: day,
						});
					}
				}

				setAPCData(realTrips);
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const handleCancelledData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const bstr = evt.target.result;

				const cancelled: any = {};

				const rawCancelled = getWSJSON(bstr);

				for (let i = 0; i < rawCancelled.length; i++) {
					const row: any = rawCancelled[i];

					const rawDay = row["Datum"].toString();

					const key = `${rawDay}-${row["Linje"]}-${row["Tur"]}`;

					if (!cancelled[key]) {
						cancelled[key] = row;
					}
				}

				setCancelled(cancelled);
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const generateReport = () => {
		try {
			if (!cancelled || !APCData) {
				return;
			}

			const trips: any = [];

			for (let i = 0; i < APCData.length; i++) {
				const row: any = APCData[i];
				const line = row["line"];
				const trip = row["trip"];

				const key = `${row["operatingDay"]}-${row["line"]}-${row["trip"]}`;

				if (cancelled[key] !== undefined) {
					continue;
				}

				const dateString = row["operatingDay"].split("-");
				const timeString = parseInt(row["trip"].subString(1, 5))
				


				const date = new Date(dateString[0], dateString[1] - 1, dateString[2])

				if (checkIfIsBookingTrip(line, trip) || checkIfIsPartialTrip(date, line, trip)) {
					continue; 
				}

				trips.push(Object.values(row));
			}

			const copyString = createXLSXCopyString(trips);
			setNewCP(copyString);
			setView("success");
		} catch (error) {
			console.log(error);
			setView("error");
		}
	};

	const reset = () => {
		setView("start");
		setAPCData(null);
		setCancelled(null);
		setNewCP("");
	};

	const writeToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(newCP);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-12">APC-rapport</h1>
			<p className="mb-3 text-sm">Filtera ut data med apc</p>
			<View show={view === "start"}>
				<p className="mt-6 text-sm font-semibold">APC filen </p>

				<InputFile name="apcdata" accept=".xlsx" onHandleFile={handleAPCData} />

				<p className="mt-6 text-sm font-semibold">Inställda</p>

				<InputFile name="trips" accept=".xlsx" onHandleFile={handleCancelledData} />

				<button
					onClick={() => generateReport()}
					disabled={!cancelled || !APCData}
					className="mt-16 text-xs font-semibold py-3 px-12 bg-slate-200 self-center rounded disabled:bg-gray-100 disabled:text-gray-300 hover:opacity-60 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
				>
					Skapa rapport
				</button>
			</View>
			<View show={view === "success"}>
				<p className="mt-6 font-semibold">Hantering av filer lyckades!</p>

				<div className="flex flex-col self-start">
					<button
						onClick={() => writeToClipboard()}
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Kopiera rader
					</button>

					<button
						className="mt-4 text-xs underline font-semibold self-start rounded"
						onClick={() => reset()}
					>
						Starta om
					</button>
				</div>
			</View>
			<View show={view === "error"}>
				<p className="mt-6 font-semibold text-red-400">Något gick fel!</p>
				<p className="mt-2 text-xs">Ett okänt fel inträffade...</p>
				<button
					className="mt-10 text-xs font-semibold py-3 px-6 bg-slate-200 self-start rounded dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => reset()}
				>
					Försok igen
				</button>
			</View>
		</>
	);
}
