import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { API_URL } from "../helper/postman";
import { RAMP_PRETTIER } from "../helper/vehicles";
import EcoDrivingEC from "../data/ecodriving/ec-groups";
import { VASSDUPP_VERSION } from "../config";
import PINCodeCalculator from "../helper/pincode";
import { DisplayBusRamps } from "../components/displayBusRamp";

const postman = axios.create({
	baseURL: API_URL,
	withCredentials: true,
	timeout: 0,
	headers: {
		"Content-type": "application/json",
		"Version-number": VASSDUPP_VERSION,
	},
});

const selfDeopMap: any = {
	taga: "Täbygaraget",
	vlga: "Vallentunagaraget",
	akga: "Åkersbergagaraget",
	marsta: "Märsta",
	norrtalje: "Norrtälje",
};

const TextResponse = ({ children, id }: { children: string; id: string | undefined }) => {
	return (
		<div className="flex flex-1 w-full h-screen justify-center items-center">
			<div>
				<h1 className="font-semibold text-xl">{children}</h1>
				<p className="text-sm">Skärm-ID: {id}</p>
			</div>
		</div>
	);
};

const getColor = (index: number) => {
	return "#000";
};

export default function Display() {
	const { displayId } = useParams();
	const [displayData, setDisplayData] = useState<any>({});
	const [refreshIntervall, setRefreshIntervall] = useState<number>(30000);

	const fetchDisplayData = async () => {
		try {
			// Check when website was last updated today from localstorage.
			const lastUpdated = localStorage.getItem("lastUpdated");

			// If it was updated today, don't update.
			if (lastUpdated) {
				if (new Date().toDateString() !== lastUpdated) {
					console.log("Last updated: " + lastUpdated);

					// If it was not updated today, update.
					localStorage.setItem("lastUpdated", new Date().toDateString());
					window.location.reload();
					console.log("Reloading", new Date().toDateString());
				}
			} else {
				// If it was never updated, update.
				localStorage.setItem("lastUpdated", new Date().toDateString());
			}

			const res = await postman.get("/displays/" + displayId);

			setDisplayData(res.data);

			if (res.data?.data?.RefreshIntervall !== undefined) {
				setRefreshIntervall(res.data?.data?.RefreshIntervall);
			}
		} catch (error) {
			// Every 30min, try to fetch data.
			setRefreshIntervall(1800000);
			// console.log(error);
		}
	};

	useEffect(() => {
		fetchDisplayData();
		const interval = setInterval(fetchDisplayData, refreshIntervall);

		return () => {
			clearInterval(interval);
		};
	}, [displayId, refreshIntervall]);

	if (!displayData) {
		return <TextResponse id={displayId}>Loading...</TextResponse>;
	}

	if (displayData.type === "empty") {
		return (
			<TextResponse id={displayId}>Skärmen har inte någon spellista tillagd.</TextResponse>
		);
	}

	return (
		<div className="flex flex-1 w-full h-screen">
			<View displayData={displayData} displayId={displayId} />
			{/* <img
				className="absolute bottom-4 right-4 w-32 object-contain"
				src="https://images.teamtailor-cdn.com/images/s3/teamtailor-production/logotype-v3/image_uploads/63a9d978-76fe-4d6a-8ab4-f6bf0572ea39/original.png"
				alt=""
			/> */}
		</div>
	);
}

const View = ({ displayData, displayId }: { displayData: any; displayId: any }) => {
	// New slide every duration seconds.
	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
	const [latestUpdate, setLatestUpdate] = useState(new Date().getTime());
	const [currentTime, setCurrentTime] = useState(new Date().getTime());

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const interval = setInterval(
			() => {
				setLatestUpdate(new Date().getTime());
				setCurrentSlideIndex((currentSlideIndex) => {
					if (currentSlideIndex + 1 >= displayData?.data?.Content?.length) {
						return 0;
					} else {
						return currentSlideIndex + 1;
					}
				});
			},
			displayData?.data?.Duration ? displayData?.data?.Duration * 1000 : 20000
		);

		return () => {
			clearInterval(interval);
		};
	}, [displayData]);

	if (displayData.type === "bp") {
		return (
			<div className="flex flex-col flex-1 relative">
				<div className="flex flex-row relative justify-between">
					<h1 className="font-bold text-2xl ml-2 mt-1">
						{RAMP_PRETTIER[displayData.depot] ?? ""}
					</h1>

					<div className="flex flex-row items-center">
						<p className="text-sm dark:text-stone-300 mr-2">
							Senast uppdaterad:{" "}
							{new Date(latestUpdate).toLocaleTimeString("sv-SE", {
								hour: "2-digit",
								minute: "2-digit",
								second: "2-digit",
							})}
						</p>
						<div className="w-24 h-2 dark:bg-stone-800 bg-stone-200 rounded-sm relative overflow-hidden">
							<div
								className="absolute h-full bg-blue-600"
								style={{
									width: `${
										(Math.abs(currentTime - latestUpdate) /
											(displayData?.data?.Duration
												? displayData?.data?.Duration * 1000
												: 10000)) *
										100
									}%`,
								}}
							/>
						</div>
					</div>

					{displayData?.config === "all" ? (
						<p className="flex flex-row mr-2 mt-1">
							Tvättkod:
							<p className="font-semibold ml-1">
								{PINCodeCalculator.calculatePinCodeFromString(
									"666666" +
										new Date().toISOString().split("T")[0].replaceAll("-", "")
								)}
							</p>
						</p>
					) : null}
				</div>

				<div className="flex">
					<DisplayBusRamps
						vehicles={displayData.data}
						depot={displayData.depot}
						extraData={displayData?.config === "all"}
					/>
				</div>
			</div>
		);
	}

	if (displayData.type === "tf") {
		return <p>Trafikteknik</p>;
	}

	if (displayData.type === "info") {
		const slides = displayData.data;

		return <Slide slide={slides.Content[currentSlideIndex]} />;
	}

	return <TextResponse id={displayId}>Laddar.</TextResponse>;
};

const Slide = ({ slide }: { slide: any }) => {
	return slide ? (
		<div className="flex flex-1 justify-center items-center">
			{slide.type === "basic" ? (
				<div className="flex flex-col">
					<div className="flex flex-col">
						<h1 className="font-semibold text-8xl">{slide.data["basic-title"]}</h1>
						<p className="text-xl mt-2 whitespace-pre-wrap">
							{slide.data["basic-text"]}
						</p>
					</div>
				</div>
			) : null}

			{slide.type === "basic-w-image" ? (
				<div className="flex flex-row flex-1">
					<div className="flex flex-col justify-center items-center flex-1 w-2/4 h-screen">
						<h1 className="font-semibold text-8xl">{slide.data["basic-title"]}</h1>
						<p className="text-xl mt-2 whitespace-pre-wrap">
							{slide.data["basic-text"]}
						</p>
					</div>
					<img
						src={slide.data["image-url"]}
						alt=""
						className="h-screen flex flex-1 object-cover w-2/4"
					/>
				</div>
			) : null}

			{slide.type === "image" ? (
				<div className="flex flex-col">
					<div className="flex flex-col absolute w-full h-screen justify-center items-center">
						<h1 className="font-semibold text-8xl">{slide.data["basic-title"]}</h1>
						<p className="text-xl mt-2 whitespace-pre-wrap">
							{slide.data["basic-text"]}
						</p>
					</div>
					<img
						src={slide.data["image-url"]}
						alt=""
						className="w-screen h-screen object-cover"
					/>
				</div>
			) : null}

			{slide.type === "video" ? (
				<div className="flex flex-col">
					<video
						src={slide.data["video-url"]}
						className="w-full h-40 object-cover"
						muted
						autoPlay
						loop
					/>
				</div>
			) : null}

			{slide.type === "ft-eco-ec" ? (
				<div className="flex flex-col flex-1 mx-20">
					<h1 className="font-semibold text-2xl">
						{selfDeopMap[slide.data["ft-eco-ec-depot"]]}
					</h1>
					<div className="flex flex-row w-full flex-wrap mt-4">
						{EcoDrivingEC.map((ec) => {
							const nameSplit = ec.driverGroupName.split(" ");
							const name = nameSplit[0] + " " + nameSplit[1].charAt(0);

							return (
								<div
									className="flex flex-col mr-2 bg-gray-100 w-[32%] mt-2 py-20 rounded first-letter:uppercase justify-center items-center"
									key={"ec-g-" + ec.driverGroupId}
									style={{ color: getColor(ec.performanceIndex) }}
								>
									<p className="text-6xl font-bold">{ec.performanceIndex}</p>
									<h1 className="text-2xl">{name}</h1>
								</div>
							);
						})}
					</div>
				</div>
			) : null}

			{slide.type === "ft-eco-summary" ? (
				<div className="flex flex-col">
					<h1 className="font-medium text-sm">{slide.data["ft-eco-summary-depot"]}</h1>
				</div>
			) : null}
		</div>
	) : null;
};
