import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";
import DataFollowupStats from "../components/dataFollowupStats";
import DataFollowupSteps from "../components/dataFollowupSteps";

import { createWBFromRows, createXLSXFile, getWSJSON } from "../helper/xlsx";
import {
	checkIfReroutedTrip,
	createTripKey,
	getHastusTrip,
	downloadPartialRecordedTrips,
	downloadVehicleDetails,
	createMissingTripDataNoSendRow,
} from "../helper/datafollowup";
import { checkIfExternalTrip, checkIfIsBookingTrip, checkIfIsPartialTrip } from "../helper/trips";
import { parseTripsCSVWithKeys } from "../helper/csv";
import { zeroed } from "../helper/date";
import {
	progressGenerator,
	getSQLQueryRecordedTripData,
	getSQLQueryVehicleHistory,
	downloadAllCompleteRecordedTrips,
	downloadAllFinishedTrips,
} from "../helper/datafollowup";

import Vehicle_Internal_Ids from "../data/vehicles/vehiclesInternalIds";

import {
	CallStub,
	CancelledData,
	DataFollowUpViews,
	DivionsType,
	HastusTrips,
	RecordedCTSTrips,
	ResultsStats,
	SLReportData,
	SLReportDataMissing,
	SLReportDataMissingNoResend,
	StopTypes,
	TripsStats,
} from "../types";
import InputFileMulti from "../components/inputFileMulti";
import { parseJSON } from "../helper/storage";
import { resendReasons } from "../data/retransmission";

const viewSteps = [
	{ key: "start", value: "Start" },
	{ key: "query-all-records", value: "Hämta planerade & registrerade turer" },
	{ key: "query-missing-recorded-trips", value: "Hämta fordonshistorik" },
	{ key: "download", value: "Ladda ner" },
	{ key: "complete", value: "Klar" },
];

export default function DataFollowUp() {
	const [view, setView] = useState<DataFollowUpViews>("start");

	const [slReportDataFileNames, setSLReportDataFileNames] = useState<string[]>([]);
	const [tripsStats, setTripsStats] = useState<TripsStats>({
		totalTrips: 0,
		tripsCompleted: 0,
		tripsPartiallyCompleted: 0,
		tripsNotCompleted: 0,
		tripsCancelled: 0,
		tripsTrafficDiversion: 0,
		tripsReinforment: 0,
		tripsWithException: 0,
		tripsMissingHastus: 0,
		vehicleMissingHastus: 0,
		tripNotResentAndMissingHastus: 0,
		tripMissingCTS: 0,
	});
	const [resultsStats, setResultsStats] = useState<ResultsStats>({
		completeFromCTS: 0,
		inCompleteFromCTS: 0,
		createdCompleteTrips: 0,
	});
	const [SLMissingTrips, setSLMissingTrips] = useState<SLReportDataMissing[]>([]);
	const [SLMissingTripsNoResend, setSLMissingTripsNoResend] = useState<
		SLReportDataMissingNoResend[]
	>([]);
	const [CTSTripsCalls, setCTSTripsCalls] = useState<RecordedCTSTrips[]>([]);
	const [hastusTrips, setHastusTrips] = useState<HastusTrips | null>(null);
	const [cancelledTrips, setCancelledTrips] = useState<any>({});
	const [hasCopiedQuery1, setHasCopiedQuery1] = useState<boolean>(false);
	const [hasCopiedQuery2, setHasCopiedQuery2] = useState<boolean>(false);
	const [vehicleDetails, setVehicleDetails] = useState<any | null>(null);
	const [finishedCreatedTrips, setFinishedCreatedTrips] = useState<any | null>(null);
	const [divisions, setDivisions] = useState<DivionsType>({});

	const handleHastusData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (evt: any) => {
			// Parse data
			const bstr = evt.target.result;

			const trips = parseTripsCSVWithKeys(bstr);

			setHastusTrips({ ...trips });
		};
		reader.readAsBinaryString(file);
	};

	const handleCancelledTrips = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const rows: CancelledData[] = getWSJSON(bstr);

			const newCancelled: any = {};

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i];

				const date = new Date(row["Trafikdatum"]);

				const key = `${date.getFullYear()}-${zeroed(date.getMonth() + 1)}-${zeroed(
					date.getDate(),
				)}-${row["Linje"]}-${row["Tur"]}`;

				newCancelled[key] = row;
			}

			setCancelledTrips({ ...newCancelled });
		};
		reader.readAsBinaryString(file);
	};

	const handleSLReport = (files: FileList) => {
		let tempState: Promise<any>[] = [];

		// Check if hastus data is loaded.
		if (!hastusTrips) {
			alert("Ladda först upp Hastus-data");
			return;
		}

		Array.from(files).map((file: File) => {
			const reader = new FileReader();

			const contentPromise = new Promise<any>((resolve) => {
				reader.onload = (event) => {
					let tempTripsStats = { ...tripsStats };
					let tempRripsMissingData = [...SLMissingTrips];
					let tempTripsMisingDataNoResend = [...SLMissingTripsNoResend];
					let tempDivisions = { ...divisions };

					// Parse data
					const bstr = event.target?.result;

					const rows: SLReportData[] = getWSJSON(bstr);

					for (let i = 0; i < rows.length; i++) {
						const row = rows[i];
						const rawDate = Number(row["Trafikdygn"]);

						const startYear = Math.floor(rawDate / 10000);
						const startMonth = Math.floor((rawDate % 10000) / 100) - 1;
						const startDay = rawDate % 100;
						const [startHourString, endHourString] = row["StartTid"].split(":");

						const startDate = new Date(
							startYear,
							startMonth,
							startDay,
							parseInt(startHourString),
							parseInt(endHourString),
						);

						// Get division E31, E35 OR E35.
						const tempDivKey = row["Avtal"];

						// Convert date to number i.e. 20210101 = 2021-01-01.
						const numberDate = parseInt(
							`${startDate.getFullYear()}${zeroed(startDate.getMonth() + 1)}${zeroed(startDate.getDate())}`,
						);

						const operatingDay = parseInt(
							`${startYear}${zeroed(startMonth + 1)}${zeroed(startDay)}`,
						);

						// Check if division exists. If not, create it.
						if (tempDivisions[tempDivKey] === undefined) {
							tempDivisions[tempDivKey] = {};
						}

						// Check if date exists. If not, create it.
						if (tempDivisions[tempDivKey][numberDate] === undefined) {
							tempDivisions[tempDivKey][numberDate] = 0;
						} else {
							tempDivisions[tempDivKey][numberDate] =
								tempDivisions[tempDivKey][numberDate] + 1;
						}

						// Add to total trips.
						tempTripsStats.totalTrips++;

						// Check progress of triip, if it is complete check next trip.
						if (row["Avgångarheltutförda"] === 1) {
							tempTripsStats.tripsCompleted++;
							continue;
						} else if (row["Avgångardelvisutförda"] === 1) {
							tempTripsStats.tripsPartiallyCompleted++;
						} else {
							tempTripsStats.tripsNotCompleted++;
						}

						// Match to hastus entry to get planned vehicle & block.
						const hastusTrip = getHastusTrip(hastusTrips, {
							line: parseInt(row["Linje"]),
							trip: parseInt(row["Tur"]),
							date: numberDate,
							startTime: row["StartTid"],
							direction: row["Riktning"],
						});

						// Check if trip is a reinforcement trip and to not calculate it.
						if (row["Tur"].toString().charAt(0) === "5") {
							tempTripsStats.tripsReinforment++;
							tempTripsMisingDataNoResend.push(
								createMissingTripDataNoSendRow(operatingDay, "reinforcement", row),
							);

							continue;
						}

						// If trip is missing in hastus, continue to next trip.
						if (!hastusTrip) {
							tempTripsStats.tripsMissingHastus++;
						} else {
							// If trip is missing vehicle, continue to next trip.
							if (!hastusTrip.vehicles || hastusTrip.vehicles === "") {
								tempTripsStats.vehicleMissingHastus++;
							}
						}

						let line = "";
						let trip = "";

						if (hastusTrip?.trip === undefined || hastusTrip?.line === undefined) {
							line = row["Linje"];
							trip = row["Tur"];
						} else {
							line = hastusTrip.line;
							trip = hastusTrip.trip;
						}

						// Check if trip is booking trip. Continue to next trip.
						if (checkIfIsBookingTrip(line, trip)) {
							tempTripsStats.tripsWithException++;
							tempTripsMisingDataNoResend.push(
								createMissingTripDataNoSendRow(operatingDay, "booking", row),
							);

							continue;
						}

						// Check if trip is partial trip. Continue to next trip.
						if (checkIfIsPartialTrip(startDate, line, trip)) {
							tempTripsStats.tripsWithException++;
							tempTripsMisingDataNoResend.push(
								createMissingTripDataNoSendRow(operatingDay, "partial", row),
							);

							continue;
						}

						// Check if trip is external trip. Continue to next trip.
						if (checkIfExternalTrip(line, trip)) {
							tempTripsStats.tripsWithException++;
							tempTripsMisingDataNoResend.push(
								createMissingTripDataNoSendRow(operatingDay, "external", row),
							);

							continue;
						}

						const key = `${startYear}-${zeroed(startMonth + 1)}-${zeroed(startDay)}-${
							row["Linje"]
						}-${row["Tur"]}`;

						// Check if trip is cancelled. Continue to next trip.
						if (cancelledTrips[key]) {
							tempTripsStats.tripsCancelled++;
							tempTripsMisingDataNoResend.push(
								createMissingTripDataNoSendRow(operatingDay, "cancelled", row),
							);

							continue;
						}

						tempRripsMissingData.push({
							date: numberDate,
							operatingDay: operatingDay,
							line,
							trip,
							completeness:
								row["Completeness"] === "-" || row["Completeness"] === undefined
									? 0
									: parseInt(row["Completeness"]),
							cancelled: row["InställdAvgång"],
							controllActionName: row["ControlActionName"],
							plannedTime: row["Planeradtid"],
							observedTime: row["Observeradtid"],
							plannedDistance: row["Planeradsträcka"],
							observedDistance: row["Observeradsträcka"],
							datedVehicleJourneyGid: row["DatedVehicleJourneyGid"].toString(),
						});
					}

					const namesSplited = file.name.split(" ");

					resolve({
						tempTripsStats,
						tempRripsMissingData,
						name: namesSplited[namesSplited.length - 1], // Last word in file name.
						tempDivisions,
						noRsesend: tempTripsMisingDataNoResend,
					});
				};
			});
			tempState.push(contentPromise);
			reader.readAsBinaryString(file);
		});

		Promise.all(tempState).then((promises: any) => {
			const stats: any = {};
			const trips: any = [];
			const names: any = [];
			const divisions: any = {};
			const tempSlMissingTripsNoResend: any = [];

			promises.map((promise: any) => {
				const { tempTripsStats, name, tempRripsMissingData, tempDivisions, noRsesend } =
					promise;

				Object.keys(tempTripsStats).map((key) => {
					if (!stats[key]) {
						stats[key] = 0;
					}
					stats[key] += tempTripsStats[key];
				});

				tempSlMissingTripsNoResend.push(...noRsesend);

				trips.push(...tempRripsMissingData);

				names.push(name);

				Object.keys(tempDivisions).map((key) => {
					if (!divisions[key]) {
						divisions[key] = {};
					}

					Object.keys(tempDivisions[key]).map((date) => {
						if (!divisions[key][date]) {
							divisions[key][date] = 0;
						}

						divisions[key][date] += tempDivisions[key][date];
					});
				});
			});

			setSLMissingTripsNoResend([...tempSlMissingTripsNoResend]);
			setTripsStats({ ...stats });
			setSLMissingTrips([...trips]);
			setSLReportDataFileNames([...slReportDataFileNames, ...names]);
			setDivisions({ ...divisions });
			setSLMissingTripsNoResend;
		});
	};

	const createSQLQueryRecordedTripData = () => {
		getSQLQueryRecordedTripData(SLMissingTrips);
		setHasCopiedQuery1(true);
	};

	const handleRecordedTripData = (file: any) => {
		const tripsMisingDataMapped: any = {};

		for (let i = 0; i < SLMissingTrips.length; i++) {
			const trip = SLMissingTrips[i];
			const key = createTripKey(trip.operatingDay.toString(), trip.line, trip.trip);

			tripsMisingDataMapped[key] = trip;
		}

		const reader = new FileReader();

		reader.onload = (event: any) => {
			let tempTripsMisingDataNoResend = [...SLMissingTripsNoResend];

			// Special parsing för åäö.
			const data = parseJSON(event.target.result);

			const trips: any = {};

			// Merge all calls together.
			for (let i = 0; i < data.length; i++) {
				const trip = data[i];

				const key = createTripKey(
					trip.OperatingCalendarDay,
					trip.LineNameLong,
					trip.JourneyName,
				);

				if (!tripsMisingDataMapped[key]) {
					console.log(
						"Trip is missing in missing trips",
						trip.OperatingCalendarDay,
						trip.LineNameLong,
						trip.JourneyName,
					);

					continue;
				}

				// Check if key exists. If not, create it.
				if (!trips[key]) {
					trips[key] = [];
				}

				// Push trip to key.
				trips[key].push({ ...trip });
			}

			// Convert trips to array.
			const tripsArray = Object.entries(trips).map((trip: any) => [
				trip[0],
				trip[1].sort((a: any, b: any) => a.SequenceInJourney - b.SequenceInJourney),
			]);

			for (let i = 0; i < SLMissingTrips.length; i++) {
				const trip = SLMissingTrips[i];
				const key = createTripKey(trip.operatingDay.toString(), trip.line, trip.trip);

				if (!trips[key]) {
					console.log("Trip is missing", trip);
					tempTripsMisingDataNoResend.push(
						createMissingTripDataNoSendRow(
							trip.operatingDay,
							"missingHastus",
							{
								Linje: trip.line,
								Tur: trip.trip,
								Completeness: trip.completeness,
								DatedVehicleJourneyGid:
								trip.datedVehicleJourneyGid,
							},
						),
					);
					continue;
				}
			}

			let atleastOneFailed = false;
			let completeFromCTS = 0;

			let newCTSTripsCalls = [];

			let tempTripsStats = { ...tripsStats };

			for (let i = 0; i < tripsArray.length; i++) {
				const trip = tripsArray[i];
				const value: any = trip[1];
				const tripKey = createTripKey(
					value[0].OperatingCalendarDay,
					value[0].LineNameLong,
					value[0].JourneyName,
				);
				const tripSLMissingMapped = tripsMisingDataMapped[tripKey];

				let hasFailed = false;
				let hasRerouted = false;

				for (let j = 0; j < value.length; j++) {
					const call = value[j];

					if (call.PlannedArrivalTime !== null && call.ActualArrivalTime === null) {
						if (checkIfReroutedTrip(call)) {
							hasRerouted = true;
						} else {
							hasFailed = true;
							break;
						}
					} else if (
						call.PlannedDepartureTime !== null &&
						call.ActualDepartureTime === null
					) {
						if (checkIfReroutedTrip(call)) {
							hasRerouted = true;
						} else {
							hasFailed = true;
							break;
						}
					}
				}

				if (hasFailed) {
					atleastOneFailed = true;
				} else {
					// IF trip is completed and is rerouted
					if (hasRerouted) {
						tempTripsStats.tripsTrafficDiversion++;
						tempTripsMisingDataNoResend.push(
							createMissingTripDataNoSendRow(
								tripSLMissingMapped.operatingDay,
								"rerouted",
								{
									Linje: tripSLMissingMapped.line,
									Tur: tripSLMissingMapped.trip,
									Completeness: tripSLMissingMapped.completeness,
									DatedVehicleJourneyGid:
										tripSLMissingMapped.datedVehicleJourneyGid,
								},
							),
						);

						continue;
					} else {
						completeFromCTS++;
					}
				}

				newCTSTripsCalls.push({
					date: value[0].OperatingCalendarDay,
					line: value[0].LineNameLong,
					trip: value[0].JourneyName,
					calls: value.sort(
						(a: any, b: any) =>
							parseInt(a.SequenceInJourney) - parseInt(b.SequenceInJourney),
					),
					isCompleted: !hasFailed,
				});
			}

			setSLMissingTripsNoResend(tempTripsMisingDataNoResend);
			setTripsStats(tempTripsStats);
			setResultsStats({
				completeFromCTS,
				inCompleteFromCTS: tripsArray.length - completeFromCTS,
				createdCompleteTrips: 0,
			});
			setCTSTripsCalls(newCTSTripsCalls);
			setView(atleastOneFailed ? "query-missing-recorded-trips" : "complete");
		};

		reader.readAsBinaryString(file);
	};

	const createSQLQueryVehicleHistory = () => {
		const newVehicleDetails = getSQLQueryVehicleHistory(
			SLMissingTrips,
			CTSTripsCalls,
			hastusTrips,
		);

		setVehicleDetails(newVehicleDetails);
		setHasCopiedQuery2(true);
	};

	const handleVehicleHistoryData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const vehiclesHistory = JSON.parse(bstr);

			const trips_map: any = {};

			// Keys for all trips.
			const tripKeysToSort = [];

			// Create trips map.
			for (let i = 0; i < vehiclesHistory.length; i++) {
				const vehicleHistory = vehiclesHistory[i];

				const VehicleId = vehicleHistory.VehicleId;

				// If key does not exist, create it.
				if (!trips_map[VehicleId]) {
					trips_map[VehicleId] = [];

					// Add key to be sorted.
					tripKeysToSort.push(VehicleId);
				}

				trips_map[VehicleId].push({ ...vehicleHistory });
			}

			// Sort all trips.
			for (let i = 0; i < tripKeysToSort.length; i++) {
				const key = tripKeysToSort[i];

				trips_map[key] = trips_map[key].sort(
					(a: any, b: any) => a.SequenceInJourney - b.SequenceInJourney,
				);
			}

			console.log(trips_map);

			setView("download");
		};

		reader.readAsBinaryString(file);
	};

	const handleTripTimeStamps = (file: any) => {
		const reader = new FileReader();

		reader.onload = (event: any) => {
			// Special parsing för åäö.
			const data = parseJSON(event.target.result);

			const trips_map: any = {};

			// Create a map so that filling to already recorded data is easier.
			for (let i = 0; i < data.length; i++) {
				const trip = data[i];
				const tripHeader = trip[0];
				const key = createTripKey(
					tripHeader.OperatingCalendarDay,
					tripHeader.LineNameLong,
					tripHeader.JourneyName,
				);

				const calls_map: any = {};

				for (let j = 0; j < trip.length; j++) {
					const call = trip[j];

					const callKey = `${call.ExternalId}-${call.type}`;

					calls_map[callKey] = call;
				}

				trips_map[key] = {
					date: tripHeader.OperatingCalendarDay.split("T")[0].replaceAll("-", ""),
					line: tripHeader.LineNameLong,
					trip: tripHeader.JourneyName,
					calls: calls_map,
				};
			}

			const finished_trips: CallStub[] = [];
			const missing_trips: any = {};

			// DatedVehicleJourneyGid map.
			const missingMap: any = {};
			for (let i = 0; i < SLMissingTrips.length; i++) {
				const trip = SLMissingTrips[i];

				const key = createTripKey(trip.operatingDay.toString(), trip.line, trip.trip);

				missingMap[key] = trip;
			}

			let tempTripsMisingDataNoResend = [...SLMissingTripsNoResend];

			for (let i = 0; i < CTSTripsCalls.length; i++) {
				const recordedCTSTrip = CTSTripsCalls[i];
				const calls: CallStub[] = [];

				let hasFailed = false;

				// If trip is complete discard it.
				if (recordedCTSTrip.isCompleted) {
					continue;
				}

				const tripKey = createTripKey(
					recordedCTSTrip.date,
					recordedCTSTrip.line,
					recordedCTSTrip.trip,
				);

				const trip = trips_map[tripKey];

				if (!trip) {
					console.log("Trip is missing", recordedCTSTrip);

					const missingTrip = missingMap[tripKey];

					console.log(missingTrip);

					tempTripsMisingDataNoResend.push(
						createMissingTripDataNoSendRow(missingTrip.operatingDay, "missingCTS", {
							Linje: missingTrip.line,
							Tur: missingTrip.trip,
							Completeness: missingTrip.completeness,
							DatedVehicleJourneyGid: missingTrip.datedVehicleJourneyGid,
						}),
					);

					continue;
				}

				for (let j = 0; j < recordedCTSTrip.calls.length; j++) {
					const reocrdedCall = recordedCTSTrip.calls[j];

					// If the call is rerouted, skip it. The trip does not need to stop there.
					if (checkIfReroutedTrip(reocrdedCall)) {
						continue;
					}

					// IF
					const createdCallArrival = trip.calls[reocrdedCall.ExternalId + "-arrival"];
					const createdCallDepature =
						trip.calls[reocrdedCall.ExternalId + "-departure"] ||
						trip.calls[reocrdedCall.ExternalId + "-passage"];

					// IF call already was recorded, push to trips.
					if (
						(reocrdedCall.ActualArrivalTime && reocrdedCall.PlannedArrivalTime) ||
						(reocrdedCall.ActualDepartureTime && reocrdedCall.PlannedDepartureTime)
					) {
						let stopTypes: StopTypes[] = [];

						// Create progress types.
						//
						// Only depature if start station.
						//
						// Only arrival if end station.
						//
						// Only depature if passed a station in the middle.
						//
						// Arrival and depature if pstopped at station in the middle.
						if (j === 0) {
							stopTypes = ["departure"];
						} else if (j === recordedCTSTrip.calls.length - 1) {
							stopTypes = ["arrival"];
						} else if (
							reocrdedCall.ActualArrivalTime !== null &&
							reocrdedCall.ActualDepartureTime != null
						) {
							stopTypes = ["arrival", "departure"];
						} else {
							stopTypes = ["passage"];
						}

						// Loop through progress types.
						for (let k = 0; k < stopTypes.length; k++) {
							const date =
								stopTypes[k] === "arrival"
									? reocrdedCall.ActualArrivalTime
									: reocrdedCall.ActualDepartureTime;

							// If date is missing, skip.
							if (date === null) {
								console.log("Date is missing", reocrdedCall);
								hasFailed = true;
								continue;
							}

							calls.push(
								progressGenerator({
									type: stopTypes[k],
									LineNameLong: reocrdedCall.LineNameLong,
									JourneyName: reocrdedCall.JourneyName,
									Date: date,
									ExternalId: reocrdedCall.ExternalId,
									vehicle: Vehicle_Internal_Ids[reocrdedCall.VehicleId ?? ""],
								}),
							);
						}
					} else if (createdCallArrival) {
						calls.push(progressGenerator(createdCallArrival));
					} else if (createdCallDepature) {
						calls.push(progressGenerator(createdCallDepature));
					} else {
						hasFailed = true;
						missing_trips[tripKey + "-" + reocrdedCall.ExternalId] = trip;
						console.log("Call is missing", reocrdedCall);
					}
				}

				if (!hasFailed) {
					finished_trips.push({
						...trip,
						calls,
					});
				} else {
					console.log("TRIP WIAHA is missing", recordedCTSTrip);
				}
			}

			setSLMissingTripsNoResend(tempTripsMisingDataNoResend);

			// Results for the finished trips.
			setResultsStats({
				...resultsStats,
				createdCompleteTrips: finished_trips.length,
			});

			// Set finished trips.
			setFinishedCreatedTrips(finished_trips);

			setView("complete");
		};

		reader.readAsBinaryString(file);
	};

	const downloadMisingTripsDataStatus = () => {
		const rows = [];

		// DatedVehicleJourneyGid map.
		const missingMap: any = {};

		for (let i = 0; i < SLMissingTripsNoResend.length; i++) {
			const trip = SLMissingTripsNoResend[i];

			rows.push([
				trip.operatingDay,
				trip.line,
				trip.trip,
				resendReasons[trip.type],
				trip.completeness,
				trip.datedVehicleJourneyGid,
			]);
		}

		for (let i = 0; i < SLMissingTrips.length; i++) {
			const trip = SLMissingTrips[i];

			const key = createTripKey(trip.operatingDay.toString(), trip.line, trip.trip);

			missingMap[key] = trip;
		}

		const tempCTSTripsCalls = [...CTSTripsCalls];

		for (let i = 0; i < tempCTSTripsCalls.length; i++) {
			const trip = tempCTSTripsCalls[i];

			// Check if trip is completed. If it isnt continue to next trip.
			if (trip.isCompleted !== undefined && !trip.isCompleted) {
				continue;
			}

			const date = trip.date.split("T")[0].replaceAll("-", "");

			const tripKey = createTripKey(date, trip.line, trip.trip);

			let tripMissingSL = missingMap[tripKey];

			const row = [
				tripMissingSL.operatingDay,
				tripMissingSL.line,
				tripMissingSL.trip,
				resendReasons["resend"],
				100,
				tripMissingSL.datedVehicleJourneyGid,
			];

			rows.push(row);
		}

		const tempFinishedCreatedTrips = [...finishedCreatedTrips];

		for (let i = 0; i < tempFinishedCreatedTrips.length; i++) {
			const trip = tempFinishedCreatedTrips[i];

			// Check if trip is completed. If it isnt continue to next trip.
			if (trip.isCompleted !== undefined && !trip.isCompleted) {
				continue;
			}

			const date = trip.date.split("T")[0].replaceAll("-", "");

			const tripKey = createTripKey(date, trip.line, trip.trip);

			let tripMissingSL = missingMap[tripKey];

			const row = [
				tripMissingSL.operatingDay,
				tripMissingSL.line,
				tripMissingSL.trip,
				resendReasons["resendCreated"],
				100,
				tripMissingSL.datedVehicleJourneyGid,
			];

			rows.push(row);
		}

		const wb = createWBFromRows(rows, [
			"Datum",
			"Linje",
			"Tur",
			"Anledning till avvikelse",
			"Fullständighet",
			"DatedVehicleJourneyGid",
		]);

		createXLSXFile(wb, "turrapport.xlsx");
	};

	return (
		<>
			<h1 className="mt-12 text-2xl font-bold">Eftersändning</h1>
			<p className="mb-3 text-sm">
				Följ upp vilka turer som har genomförts och skapa turdata automatiskt
			</p>

			{slReportDataFileNames.length !== 0 && view !== "complete" ? (
				<DataFollowupStats
					tripsStats={tripsStats}
					resultsStats={resultsStats}
					divisions={divisions}
				/>
			) : null}

			<DataFollowupSteps selected={view} steps={viewSteps} />

			<View show={view === "start"}>
				<p className="mt-4 text-sm font-semibold">
					Turer från Hastus
					<span className="text-[10px] font-normal text-gray-500">
						{" "}
						(Trips.exp från Hastus)
					</span>
				</p>

				<InputFile name="trips" accept=".exp" onHandleFile={handleHastusData} />

				<div className="flex flex-row">
					{hastusTrips ? (
						<p className="text-böack mr-2 mt-2 rounded bg-green-200 px-2 py-1 text-sm dark:bg-green-950 dark:text-green-400">
							Hastus-data laddad
						</p>
					) : null}
				</div>

				{hastusTrips && Object.keys(hastusTrips).length > 0 ? (
					<>
						<p className="mt-6 text-sm font-semibold">
							Inställda turer
							<span className="text-[10px] font-normal text-gray-500">
								{" "}
								(HEL/DEL turer från produktionsunderlag)
							</span>
						</p>

						<InputFile
							name="trips"
							accept=".xlsx"
							onHandleFile={handleCancelledTrips}
						/>

						<div className="flex flex-row">
							{Object.values(cancelledTrips).length !== 0 ? (
								<p className="text-böack mr-2 mt-2 rounded bg-green-200 px-2 py-1 text-sm dark:bg-green-950 dark:text-green-400">
									Inställda turer uppladdade
								</p>
							) : null}
						</div>

						<p className="mt-6 text-sm font-semibold">
							Utförd trafik rapport från SL{" "}
							<span className="text-[10px] font-normal text-gray-500">
								(En eller flera)
							</span>
						</p>

						<InputFileMulti
							name="sldata"
							accept=".xlsx"
							onHandleFile={handleSLReport}
						/>

						<div className="flex w-full flex-row overflow-x-scroll">
							{slReportDataFileNames.length > 0
								? slReportDataFileNames.map((row, i) => (
										<p
											key={row + i + "files"}
											className="text-böack mr-2 mt-2 rounded bg-green-200 px-2 py-1 text-xs dark:bg-green-950 dark:text-green-400"
										>
											{row}
										</p>
									))
								: null}
						</div>
					</>
				) : null}

				{SLMissingTrips.length > 0 ? (
					<button
						className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
						onClick={() => setView("query-all-records")}
					>
						Nästa
					</button>
				) : null}
			</View>

			<View show={view === "query-all-records"}>
				<div
					className="mb-10 flex h-28 w-full cursor-pointer items-center justify-center rounded bg-slate-100 text-center hover:opacity-80 dark:bg-stone-950 dark:text-stone-400"
					onClick={() => createSQLQueryRecordedTripData()}
				>
					<p className="justify-center text-center">
						Klicka för att kopiera SQL-query för att hämta planerad & registerard
						turdata ext4 DB för de turer som saknar data hos SL.
					</p>
				</div>

				{hasCopiedQuery1 ? (
					<>
						<p className="mt-2 text-sm font-semibold">
							Data från registrerade turer{" "}
							<span className="text-[10px] font-normal text-gray-500">
								(Data från ext4 DB)
							</span>
						</p>

						<InputFile
							name="recordedtripdata"
							accept=".json"
							onHandleFile={handleRecordedTripData}
						/>
					</>
				) : null}
			</View>

			<View show={view === "query-missing-recorded-trips"}>
				<div
					className="mb-10 flex h-28 w-full cursor-pointer items-center justify-center rounded bg-slate-100 hover:opacity-80 dark:bg-stone-950 dark:text-stone-400"
					onClick={() => createSQLQueryVehicleHistory()}
				>
					<p className="justify-center text-center">
						Klicka för att kopiera SQL-query hämta fordonshistorik för de turer som
						saknar data hos SL och som inte har någon registerad data i ext4 DB.
					</p>
				</div>

				{hasCopiedQuery2 ? (
					<>
						<p className="mt-2 text-sm font-semibold">
							Data fordonshistorik{" "}
							<span className="text-[10px] font-normal text-gray-500">
								(Data från ext4 DB)
							</span>
						</p>

						<InputFile
							name="vehiclehistory"
							accept=".json"
							onHandleFile={handleVehicleHistoryData}
						/>
					</>
				) : null}
			</View>

			<View show={view === "download"}>
				<button
					className="mt-6 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => downloadPartialRecordedTrips(CTSTripsCalls)}
				>
					Ladda ner turer att komplettera
				</button>

				<button
					className="mb-10 mt-6 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => downloadVehicleDetails(vehicleDetails)}
				>
					Ladda ner fordonsdetailjer
				</button>

				<p className="my-2 text-sm font-semibold">Trip timestamp</p>

				<InputFile
					name="tripTimestamps"
					accept=".json"
					onHandleFile={handleTripTimeStamps}
				/>
			</View>

			<View show={view === "complete"}>
				<DataFollowupStats
					tripsStats={tripsStats}
					resultsStats={resultsStats}
					divisions={divisions}
				/>

				<p className="mt-14 text-sm font-semibold">Skapa turdata</p>
				<button
					className="mt-3 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() =>
						downloadAllFinishedTrips(CTSTripsCalls, finishedCreatedTrips, hastusTrips)
					}
				>
					Ladda alla progresser
				</button>
				<button
					className="mt-3 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() =>
						downloadAllCompleteRecordedTrips(
							[...finishedCreatedTrips, ...CTSTripsCalls],
							SLMissingTrips,
						)
					}
				>
					Ladda ner alla eftersända turer
				</button>
				<button
					className="mt-3 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={downloadMisingTripsDataStatus}
				>
					Ladda ner alla turer som saknade data i SL rapporten
				</button>
			</View>
		</>
	);
}
