export default function Textarea({
	value,
	onChange,
	placeholder,
	name,
	required,
}: {
	value: string;
	onChange: any;
	placeholder: string;
	name?: string;
	required?: boolean;
}) {
	return (
		<div className="relative select-none">
			<span className="font-medium text-slate-400 text-xs ml-1 px-1 absolute bg-white -top-2 dark:bg-black dark:text-stone-200">
				{placeholder}
			</span>
			<textarea
				value={value}
				onChange={(e) => onChange(e.target.value, name)}
				className="bg-white border border-slate-200 py-1 px-2 appearance-none cursor-pointer min-h-[10rem] w-full rounded resize-none dark:bg-stone-900 dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
				name={name}
				required={required}
			/>
		</div>
	);
}
