import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { createWBFromRows, createXLSXCopyString, createXLSXFile, getWSJSON } from "../helper/xlsx";
import { generateCTSReport } from "../helper/ctsReport";

export default function GenerateCTSFormat() {
	const [view, setView] = useState<"start" | "loading" | "success" | "error">(
		"start"
	);

	const [hastusData, setHastusData] = useState<any[] | null>(null);

	const [SLDataB, setSLDataB] = useState<any | null>(null);

	const [newWB, setNewWB] = useState<any | null>(null);
	const [newCP, setNewCP] = useState("");

	const handleTripsData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const bstr = evt.target.result;

				setHastusData(bstr);
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const handleSlData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				// Parse data
				const bstr = event.target.result;

				setSLDataB(bstr);
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const generateReport = () => {
		try {
			if (!hastusData || !SLDataB) {
				return;
			}

			const wsSL = getWSJSON(SLDataB);
			const wsHastus = getWSJSON(hastusData);
			const rows = generateCTSReport(wsSL, wsHastus);
			const copyString = createXLSXCopyString(rows);
			// const wb = createWBFromRows(rows, {});

			setNewCP(copyString);
			// setNewWB(wb);
			setView("success");
		} catch (error) {
			console.log(error)
			setView("error");
		}
	};

	const reset = () => {
		setView("start");
		setSLDataB(null);
		setHastusData(null);
	};

	const handleDownload = () => {
		if (!newWB) {
			console.log(newWB);
			alert("Något gick fel med nedladdningen...");
			return;
		}

		// createXLSXFile(newWB, SLInfo[0].split(".")[0] + "-new.xlsx");
	};

	const writeToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(newCP);
		} catch (err) {
			console.error(
				"Error when trying to use navigator.clipboard.writeText()",
				err
			);
		}
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-12">CTS-avvikelser</h1>
			<p className="mb-3 text-sm">Skapa CTS avvikelser i efterhand</p>
			<View show={view === "start"}>
				<p className="mt-6 text-sm font-semibold">Hastus</p>

				<InputFile
					name="trips"
					accept=".xlsx"
					onHandleFile={handleTripsData}
				/>

				<p className="mt-6 text-sm font-semibold">
					Datauppföljnings filen{" "}
					<span className="text-gray-500 font-normal text-[10px]">
						(Data från SL)
					</span>
				</p>

				<InputFile
					name="sldata"
					accept=".xlsx"
					onHandleFile={handleSlData}
				/>

				<button
					onClick={() => generateReport()}
					disabled={!hastusData || !SLDataB}
					className="mt-16 text-xs font-semibold py-3 px-12 self-center rounded disabled:bg-gray-100 disabled:text-gray-300 hover:opacity-60 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
				>
					Skapa rapport
				</button>
			</View>
			<View show={view === "success"}>
				<p className="mt-6 font-semibold">
					Kombinering av filer lyckades!
				</p>

				<div className="flex flex-col self-start">
					<button
						onClick={() => writeToClipboard()}
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Kopiera rader
					</button>

					<button
						className="mt-4 text-xs underline font-semibold self-start rounded"
						onClick={() => reset()}
					>
						Starta om
					</button>
				</div>
			</View>
			<View show={view === "error"}>
				<p className="mt-6 font-semibold text-red-400">
					Något gick fel!
				</p>
				<p className="mt-2 text-xs">Ett okänt fel inträffade...</p>
				<button
					className="mt-10 text-xs font-semibold py-3 px-6 bg-slate-200 self-start rounded dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => reset()}
				>
					Försok igen
				</button>
			</View>
		</>
	);
}
