import vehicle_info from "../data/vehicles/vehicle_info";
import { DEPOT_FULL, DEPOT_FULL_CONTAINER } from "../routes/busParking";

export const RAMP_PRETTIER: any = {
	"TÄGA": "Täbygaraget",
	"VLGA": "Vallentunagaraget",
	"ÅKGA": "Åkersbergagaraget",
	"LIGA": "Linanäs uppställningsplats",
	"RIGA": "Rimbogaraget",
	"-": "I trafik",
	"DASp": "Danderyds sjukhus parkering",
	"ÄLGA": "Älmstagaraget",
	"HAGA": "Hallstavikgaraget",
	"ÖSGA": "Östersjö uppställningsplats",
	"BLGA": "Blidö uppställningsplats",
	"NOGA": "Norrtäljegaraget",
	"GÄGA": "Gävlegaraget",
	"SAGA": "Sandvikengaraget",
	"HOGA": "Hofors uppställningsplats",
};

export const convertVehiclePerArea = (v: any, depot: string) => {
	const vehicles = Object.entries(v);
	const areas: any = {};
	const main_depot = DEPOT_FULL_CONTAINER[depot];
	const vehicles_in_area = calculateVehiclesInArea(main_depot);

	for (let i = 0; i < vehicles.length; i++) {
		const [vehicle, data]: any = vehicles[i];
		let ramp = data.ramp;

		// Check if vehicle is on a ramp and set it to main depot.
		if (ramp.length < 4 && ramp !== "-") {
			ramp = DEPOT_FULL[main_depot];
		}

		if (ramp.includes("/")) {
			ramp = "I trafik";
		}

		// Check if vehicle is in area.
		if (vehicles_in_area[vehicle] === undefined) {
			continue;
		}

		ramp = prettierRamp(ramp);

		// Check if area is already in object.
		if (areas[ramp] !== undefined) {
			areas[ramp] = [
				...areas[ramp],
				{ vehicle, data, area: ramp, color: vehicles_in_area[vehicle] },
			];
		} else {
			areas[ramp] = [{ vehicle, data, area: ramp, color: vehicles_in_area[vehicle] }];
		}
	}

	return Object.entries(areas).sort((a, b) => {
		if (a[0] === "I trafik") {
			return 1;
		}
		if (b[0] === "I trafik") {
			return -1;
		}
		return a[0].localeCompare(b[0]);
	});
};

export const calculateVehiclesInArea = (depot: any) => {
	const ramps = vehicle_info[depot];
	const v: any = {};

	for (let i = 0; i < ramps.length; i++) {
		const ramp = ramps[i];

		for (let j = 0; j < ramp.length; j++) {
			const subRamp = ramp[j];

			for (let k = 0; k < subRamp.vehicles.length; k++) {
				v[subRamp.vehicles[k]] = subRamp.color;
			}
		}
	}

	return v;
};

const prettierRamp = (ramp: string) => {
	// CHeck if undefined and return same value.
	if (RAMP_PRETTIER[ramp] === undefined) {
		return ramp;
	}

	return RAMP_PRETTIER[ramp];
};
