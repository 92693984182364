const TRIP_TRAFFIC_AREA_OWBER: any = {
	178: "E35",
	529: "E31",
	531: "E31",
	532: "E31",
	533: "E31",
	534: "E31",
	535: "E31",
	536: "E31",
	537: "E31",
	538: "E31",
	"538H": "E31",
	"538V": "E31",
	539: "E31",
	545: "E31",
	560: "E31",
	"560X": "E31",
	562: "E31",
	568: "E31",
	570: "E31",
	571: "E31",
	"571X": "E31",
	572: "E31",
	573: "E31",
	574: "E31",
	575: "E31",
	"575X": "E31",
	576: "E31",
	577: "E31",
	579: "E31",
	580: "E31",
	581: "E31",
	582: "E31",
	583: "E31",
	584: "E31",
	589: "E31",
	593: "E31",
	597: "E31",
	524: "E35",
	601: "E35",
	602: "E35",
	"602X": "E35",
	604: "E35",
	605: "E35",
	606: "E35",
	607: "E35",
	608: "E35",
	609: "E35",
	610: "E35",
	611: "E35",
	612: "E35",
	"612X": "E35",
	613: "E35",
	614: "E35",
	615: "E35",
	616: "E35",
	617: "E35",
	618: "E35",
	619: "E35",
	620: "E38",
	"620X": "E38",
	621: "E38",
	622: "E35",
	"622X": "E35",
	"623V": "E35",
	"623H": "E35",
	624: "E35",
	"624C": "E35",
	625: "E38",
	626: "E35",
	627: "E35",
	628: "E35",
	"628C": "E35",
	629: "E35",
	630: "E38",
	631: "E38",
	"631X": "E38",
	632: "E38",
	633: "E35",
	634: "E38",
	635: "E35",
	636: "E38",
	637: "E38",
	"637Ã": "E38",
	"637Ã": "E38",
	638: "E38",
	639: "E38",
	640: "E38",
	"640Z": "E38",
	641: "E38",
	"641X": "E38",
	642: "E38",
	643: "E38",
	644: "E38",
	645: "E38",
	646: "E38",
	647: "E38",
	648: "E38",
	"648Z": "E38",
	649: "E38",
	650: "E38",
	651: "E38",
	652: "E38",
	653: "E38",
	"653V": "E38",
	654: "E38",
	655: "E38",
	656: "E38",
	657: "E38",
	658: "E38",
	659: "E38",
	660: "E35",
	661: "E35",
	662: "E35",
	663: "E35",
	664: "E35",
	665: "E35",
	666: "E35",
	667: "E35",
	668: "E35",
	669: "E35",
	"669Z": "E35",
	670: "E35",
	"670X": "E35",
	676: "E38",
	"676X": "E38",
	677: "E38",
	680: "E35",
	681: "E35",
	682: "E35",
	683: "E35",
	684: "E35",
	685: "E35",
	687: "E35",
	688: "E35",
	690: "E35",
	691: "E35",
	694: "E35",
	695: "E35",
	696: "E38",
	697: "E35",
	698: "E35",
	699: "E35",
	961: "E35",
	968: "E35",
	969: "E38",
};

export default TRIP_TRAFFIC_AREA_OWBER;
