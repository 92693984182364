export const downloadJsonFile = (file: any) => {
	// Convert JSON to string
	const jsonString = JSON.stringify(file, null, 2);

	// Create a Blob containing the JSON data
	const blob = new Blob([jsonString], { type: "application/json" });

	// Create a temporary URL for the Blob
	const url = URL.createObjectURL(blob);

	// Create a link element
	const link = document.createElement("a");
	link.href = url;
	link.download = "data.json";

	// Append the link to the document
	document.body.appendChild(link);

	// Trigger a click on the link to initiate the download
	link.click();

	// Remove the link from the document
	document.body.removeChild(link);

	// Revoke the Blob URL to free up resources
	URL.revokeObjectURL(url);
};
