import { Link } from "react-router-dom";
import { safeUserStorage } from "../helper/storage";

export default function TrafficTechMessage() {
	const user = JSON.parse(safeUserStorage() || "{}");

	return (
		<div className="flex flex-1 flex-col">
			<h1 className="font-bold text-2xl mt-20 mb-3">Skapa meddelande - Trafikteknik</h1>
			<div className="flex justify-end"></div>

			<div className="flex flex-1 flex-col justify-start items-center mt-6"></div>
		</div>
	);
}
