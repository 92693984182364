const data: any = {
	"5000": "615",
	"5001": "258",
	"5002": "255",
	"5003": "254",
	"5004": "230",
	"5005": "264",
	"5006": "257",
	"5007": "231",
	"5008": "223",
	"5009": "274",
	"5010": "260",
	"5011": "262",
	"5012": "217",
	"5013": "218",
	"5014": "261",
	"5015": "240",
	"5016": "241",
	"5017": "175",
	"5018": "171",
	"5019": "176",
	"5020": "180",
	"5021": "263",
	"5022": "181",
	"5023": "232",
	"5024": "215",
	"5025": "186",
	"5026": "409",
	"5027": "198",
	"5028": "177",
	"5029": "187",
	"5030": "178",
	"5031": "188",
	"5032": "216",
	"5033": "238",
	"5034": "233",
	"5035": "211",
	"5036": "239",
	"5037": "220",
	"5038": "189",
	"5039": "120",
	"5040": "192",
	"5041": "207",
	"5042": "219",
	"5043": "204",
	"5044": "267",
	"5045": "410",
	"5046": "212",
	"5047": "190",
	"5048": "213",
	"5049": "205",
	"5050": "179",
	"5051": "476",
	"5052": "276",
	"5053": "408",
	"5054": "411",
	"5055": "412",
	"5056": "413",
	"5057": "474",
	"5058": "477",
	"5059": "475",
	"5060": "520",
	"5061": "248",
	"5062": "746",
	"5063": "751",
	"5064": "748",
	"5065": "747",
	"5066": "749",
	"5067": "512",
	"5068": "156",
	"5069": "750",
	"5070": "515",
	"5071": "495",
	"5072": "494",
	"5073": "251",
	"5074": "493",
	"5075": "496",
	"5076": "478",
	"5077": "243",
	"5078": "227",
	"5079": "146",
	"5080": "234",
	"5081": "224",
	"5082": "259",
	"5083": "235",
	"5084": "225",
	"5085": "221",
	"5086": "226",
	"5087": "229",
	"5088": "816",
	"5089": "817",
	"5090": "818",
	"5091": "814",
	"5092": "819",
	"5093": "822",
	"5094": "820",
	"5095": "815",
	"5096": "823",
	"5097": "185",
	"5098": "271",
	"5099": "193",
	"5100": "191",
	"5101": "197",
	"5102": "206",
	"5103": "309",
	"5104": "268",
	"5105": "327",
	"5106": "11",
	"5107": "12",
	"5108": "13",
	"5109": "14",
	"5110": "15",
	"5111": "119",
	"5112": "118",
	"5113": "277",
	"5114": "117",
	"5115": "114",
	"5116": "278",
	"5117": "116",
	"5118": "115",
	"5119": "273",
	"5120": "279",
	"5121": "129",
	"5122": "131",
	"5123": "138",
	"5124": "141",
	"5126": "16",
	"5127": "182",
	"5128": "17",
	"5129": "5",
	"5130": "164",
	"5131": "18",
	"5132": "196",
	"5133": "139",
	"5134": "169",
	"5135": "19",
	"5136": "20",
	"5137": "21",
	"5138": "22",
	"5139": "23",
	"5140": "24",
	"5141": "25",
	"5142": "26",
	"5143": "272",
	"5144": "154",
	"5145": "280",
	"5146": "285",
	"5147": "27",
	"5148": "28",
	"5150": "29",
	"5151": "30",
	"5152": "31",
	"5153": "32",
	"5154": "33",
	"5155": "222",
	"5156": "269",
	"5157": "307",
	"5158": "315",
	"5159": "333",
	"5160": "297",
	"5161": "282",
	"5162": "283",
	"5163": "195",
	"5164": "313",
	"5165": "286",
	"5166": "303",
	"5167": "318",
	"5168": "290",
	"5169": "288",
	"5170": "323",
	"5171": "322",
	"5172": "316",
	"5173": "320",
	"5174": "314",
	"5175": "328",
	"5176": "324",
	"5177": "302",
	"5178": "311",
	"5179": "329",
	"5180": "330",
	"5181": "319",
	"5182": "325",
	"5183": "203",
	"5184": "228",
	"5185": "287",
	"5186": "293",
	"5187": "284",
	"5188": "296",
	"5189": "304",
	"5190": "292",
	"5191": "300",
	"5192": "310",
	"5193": "326",
	"5194": "317",
	"5195": "312",
	"5196": "308",
	"5197": "334",
	"5201": "752",
	"5202": "753",
	"5203": "755",
	"5204": "754",
	"5205": "756",
	"5206": "757",
	"5207": "807",
	"5208": "810",
	"5209": "811",
	"5210": "809",
	"5211": "812",
	"5212": "813",
	"5213": "170",
	"5214": "173",
	"5215": "492",
	"5216": "605",
	"5217": "174",
	"5526": "3",
	"5500": "161",
	"5501": "155",
	"5502": "150",
	"5503": "152",
	"5504": "134",
	"5505": "136",
	"5506": "137",
	"5507": "364",
	"5508": "4",
	"5509": "143",
	"5510": "123",
	"5511": "130",
	"5512": "7",
	"5513": "112",
	"5514": "159",
	"5515": "144",
	"5516": "125",
	"5517": "113",
	"5518": "133",
	"5519": "110",
	"5520": "162",
	"5521": "147",
	"5522": "122",
	"5523": "266",
	"5524": "158",
	"5525": "135",
	"5527": "128",
	"5528": "109",
	"5529": "132",
	"5530": "194",
	"5531": "124",
	"5532": "34",
	"5533": "35",
	"5534": "36",
	"5535": "37",
	"5536": "38",
	"5537": "39",
	"5538": "40",
	"5539": "41",
	"5540": "42",
	"5541": "43",
	"5542": "44",
	"5543": "45",
	"5544": "46",
	"5545": "47",
	"5546": "48",
	"5547": "49",
	"5548": "50",
	"5549": "2",
	"5550": "51",
	"5551": "52",
	"5552": "53",
	"5553": "54",
	"5554": "108",
	"5555": "6",
	"5556": "55",
	"5557": "56",
	"5558": "111",
	"5559": "57",
	"5560": "58",
	"5561": "59",
	"5562": "60",
	"5563": "61",
	"5564": "62",
	"5565": "63",
	"5566": "64",
	"5567": "65",
	"5568": "66",
	"5569": "67",
	"5570": "68",
	"5571": "69",
	"5572": "70",
	"5573": "71",
	"5574": "72",
	"5575": "73",
	"5576": "74",
	"5577": "75",
	"5578": "76",
	"5579": "77",
	"5580": "78",
	"5581": "79",
	"5582": "80",
	"5583": "81",
	"5584": "82",
	"5585": "83",
	"5586": "84",
	"5587": "85",
	"5588": "86",
	"5589": "87",
	"5590": "88",
	"5591": "89",
	"5592": "90",
	"5593": "91",
	"5594": "92",
	"5595": "93",
	"5596": "94",
	"5597": "95",
	"5598": "96",
	"5599": "97",
	"5600": "98",
	"5601": "99",
	"5602": "148",
	"5603": "337",
	"5604": "100",
	"5605": "101",
	"5606": "102",
	"5607": "103",
	"5608": "104",
	"5609": "335",
	"5610": "336",
	"6058": "516",
	"6059": "517",
	"6600": "377",
	"6601": "397",
	"6602": "388",
	"6603": "530",
	"6604": "384",
	"6605": "387",
	"6606": "575",
	"6607": "382",
	"6608": "561",
	"6610": "385",
	"6611": "580",
	"6612": "578",
	"6613": "582",
	"6614": "585",
	"6615": "808",
	"6616": "572",
	"0455": "126",
	"0443": "246",
	"0445": "253",
	"0446": "281",
	"0447": "289",
	"0448": "291",
	"0449": "294",
	"0450": "295",
	"0452": "298",
	"0453": "299",
	"0454": "301",
	"0457": "305",
	"0456": "306",
	"0451": "331",
	"0444": "332",
	"0427": "378",
	"0465": "379",
	"0464": "380",
	"0463": "381",
	"0469": "383",
	"0484": "386",
	"0473": "389",
	"0481": "390",
	"0477": "391",
	"0482": "392",
	"0467": "393",
	"0472": "394",
	"0480": "401",
	"0468": "403",
	"0411": "414",
	"0486": "522",
	"0459": "523",
	"0478": "524",
	"0476": "525",
	"0470": "526",
	"0466": "527",
	"0440": "528",
	"0442": "529",
	"0416": "531",
	"0441": "532",
	"0460": "539",
	"0439": "540",
	"0474": "541",
	"0485": "542",
	"0471": "543",
	"0462": "544",
	"0415": "545",
	"0429": "546",
	"0430": "547",
	"0435": "548",
	"0437": "549",
	"0434": "550",
	"0400": "551",
	"0404": "552",
	"0432": "553",
	"0428": "554",
	"0412": "555",
	"0417": "556",
	"0407": "557",
	"0433": "558",
	"0436": "559",
	"0461": "560",
	"0405": "562",
	"0424": "563",
	"0421": "564",
	"0413": "565",
	"0410": "566",
	"0414": "567",
	"0406": "568",
	"0409": "569",
	"0431": "570",
	"0423": "571",
	"0401": "573",
	"0426": "574",
	"0419": "576",
	"0422": "577",
	"0425": "581",
	"0418": "583",
	"0408": "584",
	"0403": "586",
	"0438": "587",
	"0420": "592",
	"0483": "594",
	"0490": "595",
	"0494": "596",
	"0492": "597",
	"0493": "598",
	"0475": "599",
	"0491": "600",
	"0402": "601",
	"0458": "602",
	"0479": "608",
};

export default data;
