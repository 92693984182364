import { Link } from "react-router-dom";
import Input from "../components/input";
import Textarea from "../components/textarea";

export default function CreateTrafficTech() {
	return (
		<div className="flex flex-1 flex-col">
			<h1 className="font-bold text-2xl mt-20">Skapa meddelande</h1>
			<p className="text-slate-500 text-sm font-semibold">Trafikteknik</p>
			<div className="flex flex-col mt-10 max-w-4xl">
				<div className="">
					<Input placeholder="Rubrik" value="" onChange={() => {}} />
				</div>
				<div className="mt-4">
					<Input placeholder="Linjer" value="" onChange={() => {}} />
				</div>
				<div className="mt-4">
					<Input placeholder="Hållplatser" value="" onChange={() => {}} />
				</div>
				<div className="mt-4">
					<Input placeholder="Kategori" value="" onChange={() => {}} />
				</div>
				<div className="mt-4">
					<Textarea placeholder="Meddelande" value="" onChange={() => {}} />
				</div>
				<div className="flex mt-4">
					<div className="flex flex-1 mr-4">
						<Input placeholder="Visas från" type="date" value="" onChange={() => {}} />
					</div>
					<div className="flex flex-1 mr-4">
						<Input placeholder="Gäller från" type="date" value="" onChange={() => {}} />
					</div>
					<div className="flex flex-1">
						<Input placeholder="Gäller till" type="date" value="" onChange={() => {}} />
					</div>
				</div>
				<div className="w-full h-60 border border-slate-200 mt-10 p-10">
					<p>Karta</p>
				</div>
			</div>
		</div>
	);
}
