import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { mergeTripsAndData } from "../helper/trips";
import { parseTripsCSV } from "../helper/csv";
import { createWBFromRows, createXLSXCopyString, createXLSXFile, getWSJSON } from "../helper/xlsx";

import { NEW_XLSX_HEADRS, XLSX_HEADERS } from "../config";

import DataFollowUpAuto from "./old/dataFollowupAuto";
import { vehicleAutoFollowUp } from "../helper/network";

export default function DataFollowUp() {
	const [view, setView] = useState<"start" | "loading" | "auto" | "success" | "error">("start");

	const [auto, setAuto] = useState(true);
	const [allTrips, setAllTrips] = useState(true);
	const [newOutput, setNewOutput] = useState(false);

	const [tripsInfo, setTripsInfo] = useState<null | string[]>(null);
	const [trips, setTrips] = useState<any[] | null>(null);

	const [SLInfo, setSLInfo] = useState<null | string[]>(null);
	const [SLDataB, setSLDataB] = useState<any | null>(null);

	const [newWB, setNewWB] = useState<any | null>(null);
	const [newCP, setNewCP] = useState("");
	const [stats, setStats] = useState({
		rows: 0,
		rowsFilled: 0,
		rowsAnrop: 0,
		trends: [],
	});

	const [autoTrips, setAutoTrips] = useState([]);

	const handleTripsData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const bstr = evt.target.result;

				const [trip_dates, parsed_trips] = parseTripsCSV(bstr);

				setTrips(parsed_trips);
				setTripsInfo([file.name, trip_dates[0], trip_dates[trip_dates.length - 1]]);
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const handleSlData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				// Parse data
				const bstr = event.target.result;

				setSLDataB(bstr);
				setSLInfo([file.name]);
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const handleStart = () => {
		if (auto) {
			handleAuto();
		} else {
			handleMergeManually();
		}
	};

	const handleMergeManually = () => {
		try {
			if (!trips || !SLDataB || !SLInfo) {
				return;
			}

			const ws = getWSJSON(SLDataB);
			const [rows, statistics] = mergeTripsAndData(ws, trips, allTrips, newOutput);
			const copyString = createXLSXCopyString(rows);
			const wb = createWBFromRows(rows, newOutput ? NEW_XLSX_HEADRS : XLSX_HEADERS);

			setStats(statistics);
			setNewCP(copyString);
			setNewWB(wb);
			setView("success");
		} catch (error) {
			console.log(error);
			setView("error");
		}
	};

	const handleAuto = async () => {
		try {
			const ws = getWSJSON(SLDataB);
			const [rows] = mergeTripsAndData(ws, trips, allTrips, false);

			if (rows.length === 0) {
				setView("success");

				return;
			} else {
				const data = rows.map((row: any) => ({
					date: row[3],
					line: row[6],
					trip: row[5],
					startTime: row[8],
					vehicle: row[11],
					omlopp: row[10],
					length: row[21],
					anrop: row[12] === "Anrop",
				}));

				setView("auto");

				const res = await vehicleAutoFollowUp(data);

				console.log(res.data);

				setAutoTrips(res.data);
			}
		} catch (error) {
			console.log(error);
			setView("error");
		}
	};

	const reset = () => {
		setView("start");
		setSLDataB(null);
		setTrips(null);
		setSLInfo(null);
		setTripsInfo(null);
	};

	const handleDownload = () => {
		if (!newWB || !SLInfo) {
			console.log(newWB);
			alert("Något gick fel med nedladdningen...");
			return;
		}

		createXLSXFile(newWB, SLInfo[0].split(".")[0] + "-new.xlsx");
	};

	const writeToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(newCP);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-20">Datauppföljning</h1>
			<p className="mb-3 text-sm">Följ upp vilka turer som har genomförts och</p>
			<View show={view === "start"}>
				<div className="flex self-end select-none">
					<div className="flex bg-gray-100 self-end py-1 px-2 rounded mr-4 dark:bg-stone-900">
						<p
							onClick={() => setAuto(true)}
							className={
								"py-1 px-2 cursor-pointer text-xs mr-2 rounded " +
								(auto
									? "bg-white dark:bg-black dark:text-stone-400"
									: "text-gray-600 dark:text-stone-200")
							}
						>
							Automatisk
						</p>
						<p
							onClick={() => setAuto(false)}
							className={
								"py-1 px-2  cursor-pointer text-xs rounded " +
								(auto
									? "text-gray-600 dark:text-stone-200"
									: "bg-white dark:bg-black dark:text-stone-400")
							}
						>
							Manuell
						</p>
					</div>

					<div className="flex bg-gray-100 self-end py-1 px-2 rounded mr-4 dark:bg-stone-900">
						<p
							onClick={() => setAllTrips(true)}
							className={
								"py-1 px-2  cursor-pointer text-xs rounded " +
								(allTrips
									? "bg-white dark:bg-black dark:text-stone-400"
									: "text-gray-600 dark:text-stone-200")
							}
						>
							Alla
						</p>
						<p
							onClick={() => setAllTrips(false)}
							className={
								"py-1 px-2 cursor-pointer text-xs mr-2 rounded " +
								(allTrips
									? "text-gray-600 dark:text-stone-200"
									: "bg-white dark:bg-black dark:text-stone-400")
							}
						>
							Bara helt ej utförda
						</p>
					</div>

					<div className="flex bg-gray-100 self-end py-1 px-2 rounded mr-4 dark:bg-stone-900">
						<p
							onClick={() => setNewOutput(true)}
							className={
								"py-1 px-2  cursor-pointer text-xs rounded " +
								(newOutput
									? "bg-white dark:bg-black dark:text-stone-400"
									: "text-gray-600 dark:text-stone-200")
							}
						>
							Nya filformatet
						</p>
						<p
							onClick={() => setNewOutput(false)}
							className={
								"py-1 px-2 cursor-pointer text-xs mr-2 rounded " +
								(newOutput
									? "text-gray-600 dark:text-stone-200"
									: "bg-white dark:bg-black dark:text-stone-400")
							}
						>
							Gamla filformat
						</p>
					</div>
				</div>

				<p className="mt-2 text-sm font-semibold">
					Turer
					<span className="text-gray-500 font-normal text-[10px]">
						(Data från Hastus)
					</span>
				</p>

				{tripsInfo ? (
					<>
						<p className="text-xs text-gray-700 mt-1">
							{tripsInfo[0]} - Tidsperiod <b>{tripsInfo[1]}</b> till{" "}
							<b>{tripsInfo[2]}</b>
						</p>
					</>
				) : (
					false
				)}

				<InputFile name="trips" accept=".exp" onHandleFile={handleTripsData} />

				<p className="mt-6 text-sm font-semibold">
					Datauppföljnings filen{" "}
					<span className="text-gray-500 font-normal text-[10px]">(Data från SL)</span>
				</p>

				{SLInfo ? (
					<>
						<p className="text-xs text-gray-700 mt-1">{SLInfo[0]}</p>
					</>
				) : (
					false
				)}

				<InputFile name="sldata" accept=".xlsx" onHandleFile={handleSlData} />

				<button
					onClick={() => handleStart()}
					disabled={!trips || !SLDataB}
					className="mt-16 text-xs font-medium py-3 px-12 bg-slate-200 self-center rounded disabled:bg-gray-100 disabled:text-gray-300 hover:opacity-60 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
				>
					Starta {auto ? "automatisk" : "manuell"} uppföljning
				</button>
			</View>

			<View show={view === "auto"}>
				<DataFollowUpAuto tripsData={autoTrips} />
			</View>

			<View show={view === "success"}>
				<p className="mt-6 font-semibold">Kombinering av filer lyckades!</p>
				<p className="mt-2 text-xs">Antal Rader: {stats.rows}</p>
				<p className="mt-2 text-xs">Antal Rader med omlopp & vagn: {stats.rowsFilled}</p>
				<p className="mt-2 text-xs">Antal Rader med anropstyrd: {stats.rowsAnrop}</p>

				<div className="flex flex-col self-start">
					<button
						onClick={() => writeToClipboard()}
						className="mt-10 text-xs font-medium py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Kopiera rader
					</button>

					<button
						onClick={() => handleDownload()}
						className="mt-4 text-xs font-medium py-3 px-12 bg-slate-200 rounded dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Ladda ner .xlsx
					</button>

					<button
						className="mt-4 text-xs underline font-medium self-start rounded"
						onClick={() => reset()}
					>
						Starta om
					</button>
				</div>
			</View>
			<View show={view === "error"}>
				<p className="mt-6 font-semibold text-red-400">Något gick fel!</p>
				<p className="mt-2 text-xs">Ett okänt fel inträffade...</p>
				<button
					className="mt-10 text-xs font-semibold py-3 px-6 bg-slate-200 self-start rounded dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => reset()}
				>
					Försok igen
				</button>
			</View>
		</>
	);
}
