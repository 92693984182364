const v = {
	taga: [
		[
			{
				type: "MAN Led",
				color: "red",
				vehicles: [
					"5000",
					"5010",
					"5011",
					"5012",
					"5013",
					"5014",
					"5015",
					"5016",
					"5017",
					"5018",
					"5019",
					"5020",
					"5021",
					"5022",
					"5023",
					"5024",
					"5025",
					"5026",
					"5027",
					"5028",
					"5029",
					"5030",
					"5031",
					"5032",
					"5033",
					"5034",
					"5035",
					"5036",
					"5037",
					"5038",
					"5039",
					"5040",
					"5041",
					"5042",
					"5043",
					"5044",
					"5045",
					"5046",
					"5047",
					"5048",
					"5049",
					"5050",
					"5051",
					"5052",
					"5053",
					"5054",
					"5055",
					"5056",
					"5057",
					"5058",
					"5059",
					"5060",
					"5061",
					"5062",
				],
			},
		],
		[
			{
				type: "MAN Led",
				color: "red",
				vehicles: [
					"5063",
					"5064",
					"5065",
					"5066",
					"5067",
					"5068",
					"5069",
					"5070",
					"5071",
					"5072",
					"5073",
					"5074",
					"5075",
					"5076",
					"5077",
					"5078",
					"5079",
					"5080",
					"5081",
					"5082",
					"5083",
					"5084",
					"5085",
					"5086",
					"5087",
					"5213",
					"5507",
					"5508",
					"5509",
					"5510",
					"5511",
					"5512",
					"5513",
					"5514",
					"5515",
					"5516",
					"5517",
					"5518",
					"5519",
					"5520",
					"5521",
					"5522",
					"5523",
					"5524",
					"5525",
					"5526",
					"5527",
					"5528",
					"5529",
					"5530",
					"5531",
				],
			},
		],
		[
			{
				type: "Volvo Boggi",
				color: "red",
				vehicles: [
					"5121",
					"5122",
					"5123",
					"5124",
					"5127",
					"5130",
					"5132",
					"5133",
					"5134",
					"5143",
					"5144",
					"5145",
					"5146",
					"5155",
					"5156",
					"5157",
					"5158",
					"5159",
					"5160",
					"5161",
					"5162",
					"5163",
					"5164",
					"5165",
					"5166",
					"5167",
					"5168",
					"5169",
					"5170",
					"5171",
					"5172",
					"5173",
					"5174",
					"5175",
					"5176",
					"5177",
					"5178",
					"5179",
					"5180",
					"5181",
					"5182",
				],
			},
			{
				type: "Volvo Kort",
				color: "red",
				height: "not-full",
				vehicles: [
					"5096",
					"5097",
					"5098",
					"5099",
					"5100",
					"5101",
					"5102",
					"5103",
					"5104",
					"5105",
					"5532",
					"5533",
					"5534",
					"5535",
					"5536",
					"5537",
					"5538",
					"5539",
					"5540",
				],
			},
		],
		[
			{
				type: "MAN Led",
				color: "blue",
				height: "not-full",
				vehicles: [
					"5001",
					"5002",
					"5003",
					"5004",
					"5005",
					"5006",
					"5007",
					"5008",
					"5009",
					"5500",
					"5501",
					"5502",
					"5503",
					"5504",
					"5505",
					"5506",
				],
			},
			{
				type: "Volvo Dubbeldäckare",
				color: "blue",
				vehicles: [
					"5183",
					"5184",
					"5185",
					"5186",
					"5187",
					"5188",
					"5189",
					"5190",
					"5191",
					"5192",
					"5193",
					"5194",
					"5195",
					"5196",
					"5577",
				],
			},
			{
				type: "VDL Kort",
				color: "red",
				height: "not-full",
				vehicles: [
					"0443",
					"0444",
					"0445",
					"0446",
					"0447",
					"0448",
					"0449",
					"0450",
					"0451",
					"0452",
					"0453",
					"0454",
					"0455",
					"0456",
					"0457",
					"0458",
				],
			},
			{
				type: "Minibuss",
				color: "red",
				vehicles: ["5197", "5609", "5610"],
			},
		],
	],
	noga: [
		[
			{
				type: "Volvo Boggi",
				color: "red",
				height: "full",
				vehicles: [
					"5106",
					"5107",
					"5108",
					"5109",
					"5110",
					"5111",
					"5112",
					"5113",
					"5114",
					"5115",
					"5116",
					"5117",
					"5118",
					"5119",
					"5120",
					"5126",
					"5128",
					"5129",
					"5131",
					"5135",
					"5136",
					"5137",
					"5138",
					"5139",
					"5140",
					"5141",
					"5142",
					"5147",
				],
			},
		],
		[
			{
				type: "Volvo Boggi",
				color: "red",
				height: "not-full",
				vehicles: ["5148", "5150", "5151", "5152", "5153", "5154"],
			},
			{
				type: "Volvo Boggi",
				color: "blue",
				height: "not-full",
				vehicles: ["5560", "5561", "5562", "5563", "5564"],
			},
			{
				type: "Volvo Boggi",
				color: "red",
				height: "full",
				vehicles: [
					"5565",
					"5566",
					"5567",
					"5568",
					"5569",
					"5570",
					"5571",
					"5572",
					"5573",
					"5574",
					"5575",
					"5576",
				],
			},
			{
				type: "Minibuss",
				color: "red",
				height: "full",
				vehicles: ["5602", "5603"],
			},
		],
		[
			{
				type: "Volvo kort",
				color: "red",
				height: "not-full",
				vehicles: [
					"5539",
					"5541",
					"5542",
					"5543",
					"5544",
					"5545",
					"5546",
					"5547",
					"5548",
					"5549",
					"5550",
					"5551",
					"5552",
					"5553",
					"5554",
					"5555",
					"5556",
					"5557",
					"5558",
					"5559",
				],
			},
			{
				type: "BYD EL",
				color: "red",
				height: "full",
				vehicles: ["5604", "5605", "5606", "5607", "5608"],
			},
		],

		[
			{
				type: "Volvo DD",
				color: "blue",
				height: "full",
				vehicles: [
					"5578",
					"5579",
					"5580",
					"5581",
					"5582",
					"5583",
					"5584",
					"5585",
					"5586",
					"5587",
					"5588",
					"5589",
					"5590",
					"5591",
					"5592",
					"5593",
					"5594",
					"5595",
					"5596",
					"5597",
					"5598",
					"5599",
					"5600",
					"5601",
				],
			},
		],
	],
	maga: [
		[
			{
				type: "VDL kort",
				color: "red",
				vehicles: [
					"0400",
					"0401",
					"0402",
					"0403",
					"0404",
					"0405",
					"0406",
					"0407",
					"0408",
					"0409",
					"0410",
					"0411",
					"0412",
					"0413",
					"0414",
					"0415",
					"0416",
					"0417",
					"0418",
					"0419",
					"0420",
					"0421",
					"0422",
					"0423",
					"0424",
					"0425",
				],
			},
		],
		[
			{
				type: "VDL kort",
				color: "red",
				vehicles: [
					"0426",
					"0427",
					"0428",
					"0429",
					"0430",
					"0431",
					"0432",
					"0433",
					"0434",
					"0435",
					"0436",
					"0437",
					"0438",
					"0439",
					"0440",
					"0441",
					"0442",
				],
			},
			{
				type: "BYD EL",
				color: "red",
				vehicles: ["0490", "0491", "0492", "0493", "0494"],
			},
		],
		[
			{
				type: "Scania led",
				color: "red",
				vehicles: [
					"0459",
					"0460",
					"0461",
					"0462",
					"0463",
					"0464",
					"0465",
					"0466",
					"0467",
					"0468",
					"0469",
					"0470",
					"0471",
					"0472",
					"0473",
					"0474",
					"0475",
					"0476",
					"0477",
					"0478",
					"0479",
					"0480",
					"0481",
					"0482",
					"0483",
					"0484",
					"0485",
					"0486",
				],
			},
		],
		[
			{
				type: "MAN led",
				color: "red",
				height: "not-full",
				vehicles: [
					"5201",
					"5202",
					"5203",
					"5204",
					"5205",
					"5206",
					"5207",
					"5208",
					"5209",
					"5210",
					"5211",
					"5212",
				],
			},
			{
				type: "Volvo Kort",
				color: "red",
				height: "not-full",
				vehicles: ["5088", "5089", "5090", "5091", "5092", "5093", "5094", "5095"],
			},
		],
	],
	gaga: [
		[
			{
				type: "VDL boggi",
				color: "red",
				vehicles: ["41711", /*  */ "41712", /*  */ "41714"],
				height: "not-full",
			},
			{
				type: "Volvo boggi",
				color: "red",
				vehicles: [
					"41354", /*  */
					"41734", /*  */
					"41750",/*  */
					"41752",/*  */
					"41753",/*  */
					"41754",
					"41759",
					"41760",
					"41761",
					"41762",
					"41763",
					"41764",
					"41765",
					"41766",
				],
			},
		],
		[
			{
				type: "BYD EL Boggi",
				color: "red",
				vehicles: [
					"7700",
					"7701",
					"7702",
					"7703",
					"7704",
					"7705",
					"7706",
					"7707",
					"7708",
					"7709",
					"7710",
					"7711",
					"7712",
					"7713",
					"7714",
					"7715",
					"7716",
					"7717",
					"7718",
					"7719",
					"7720",
					"7721",
				],
			},
		],
		[
			{
				type: "BYD EL Boggi",
				color: "red",
				vehicles: [
					"7722",
					"7723",
					"7724",
					"7725",
					"7726",
					"7727",
					"7728",
					"7729",
					"7730",
					"7731",
					"7732",
					"7733",
					"7734",
					"7735",
					"7736",
					"7737",
					"7738",
					"7739",
					"7740",
					"7741",
					"7742",
					"7743",
					"7748",
					"7753"
				],
			},
		],
		[
			{
				type: "BYD EL kort",
				color: "red",
				vehicles: ["7773", "7774", "7775", "7776", "7777", "7778", "7779", "7780"],
			},
			{
				type: "VDL kort",
				color: "red",
				vehicles: ["41745", "41767", "41768", "41769", "41770", "7757", "7758"],
				height: "not-full",
			},
			{
				type: "Vätgas kort",
				color: "red",
				vehicles: ["41771", "41772"],
			},
		],
	],
};

export default v as any;
