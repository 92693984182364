import { useState } from "react";
import InputDate from "../../components/inputDate";
import Textarea from "../../components/textarea";
import { fetchResolvedTicketMachines } from "../../helper/network";

export default function TicketReport() {
	const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);
	const [endDate, setEndDate] = useState(new Date().toISOString().split("T")[0]);
	const [cookie, setCookie] = useState("");

	const handleSubmit = async () => {
		if (
			parseInt(startDate) === 0 ||
			parseInt(endDate) === 0 ||
			cookie === "" ||
			cookie.length < 100
		) {
			alert("Du har skrivit in fel kakor eller datum");
		}

		const [dS, mS, yS] = new Date(startDate).toLocaleDateString().split("/");
		const [dE, mE, yE] = new Date(endDate).toLocaleDateString().split("/");

		const startDateStr = `${yS}-${mS}-${dS}`;
		const endDateStr = `${yE}-${mE}-${dE}`;

		const res = await fetchResolvedTicketMachines(cookie, startDateStr, endDateStr);

		console.log(JSON.stringify(res, null, 4));
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-20">Biljettmaskinrapport</h1>
			<p className="mb-3 text-sm">Skapa rapport gällande reparerade av biljettmaskiner</p>
			<div className="flex flex-col mt-6">
				<div className="flex flex-col">
					<div className="flex flex-row">
						<div className="mr-2">
							<InputDate
								placeholder="Startdatum"
								onChange={setStartDate}
								value={startDate}
							/>
						</div>
						<div className="mr-4">
							<InputDate
								placeholder="Slutdatum"
								onChange={setEndDate}
								value={endDate}
							/>
						</div>
					</div>
					<div className="mt-2">
						<Textarea placeholder="Kakor" onChange={setCookie} value={cookie} />
					</div>
				</div>
			</div>
			<button
				onClick={() => handleSubmit()}
				disabled={cookie === "" || parseInt(startDate) === 0 || parseInt(endDate) === 0}
				className="mt-16 text-xs font-medium py-3 px-12 bg-slate-200 self-center rounded disabled:bg-gray-100 disabled:text-gray-300 hover:opacity-60"
			>
				Skapa rapport
			</button>
		</>
	);
}
