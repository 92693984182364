import { NavLink } from "react-router-dom";
import { getNavigation } from "../helper/navigation";
import { safeUserStorage } from "../helper/storage";

export default function Start() {
	const user = JSON.parse(safeUserStorage() || "{}");

	const navItems = getNavigation(user);

	return (
		<div className="flex flex-1 flex-col">
			<h1 className="font-bold text-2xl mt-20 mb-3">Start</h1>
			<div className="flex flex-col">
				{navItems.map((cat) => {
					const title = cat[0];
					const catItems: any = cat[1];

					return (
						<div
							className="flex flex-col md:flex-row md:flex-wrap"
							key={"cat." + title}
						>
							<h2 className="flex w-full font-semibold">{title}</h2>
							{catItems.map((item: any) => (
								<NavLink
									to={"/" + item[0]}
									key={item[0]}
									className="flex bg-slate-100 dark:bg-stone-950 w-full md:w-[24.233%] h-20 px-4 box-border mb-2 text-center dark:text-stone-300 justify-center items-center mr-[0.6%] rounded hover:bg-slate-200 font-medium text-base dark:hover:opacity-80 dark:hover:bg-stone-900"
								>
									{item[1]}
									{item[2] ? (
										<span className="text-xs px-1 py-0.5 rounded font-semibold text-blue-400 bg-blue-100 ml-2 dark:bg-slate-800">
											{item[2]}
										</span>
									) : null}
								</NavLink>
							))}
						</div>
					);
				})}
			</div>
		</div>
	);
}
