import { Link } from "react-router-dom";
import { BIG_TESTS_CATEGORIES, TESTS_CATEGORIES } from "../data/education/items";

export default function Edu() {
	return <p>WIW</p>

	/* return (
		<div className="flex flex-1 mt-8 w-full flex-col">
			<h1 className="mb-6 text-2xl font-bold">Utbildning</h1>
			<div>
				<div className="flex flex-col">
					<div className="">
						<p className="font-semibold mt-8">Tidigare resultat</p>
						<div className="flex mb-4">
							{[].length === 0 ? (
								<p className="font-medium text-sm text-stone-500">
									Inga resultat att visa
								</p>
							) : (
								<div>
									<p>Resultat</p>
								</div>
							)}
						</div>
					</div>

					<span className="font-semibold mb-2 flex flex-row items-center mt-4">
						Testa dina kunskaper
						<p className="ml-1 text-xs text-stone-500">({TESTS_CATEGORIES.length}st)</p>
					</span>
					<div className="flex flex-1 gap-2.5 flex-wrap">
						{TESTS_CATEGORIES.map((category) => (
							<div
								key={category.id}
								className="flex flex-1 min-w-[24.4%] max-w-[24.4%] select-none flex-col relative transition-colors bg-slate-100 dark:bg-stone-950 dark:border-stone-900 border-slate-200 border px-4 py-4 rounded"
							>
								<p className="font-semibold">{category.name}</p>

								<p className="text-xs">{category.desc}</p>

								<p className="font-semibold text-xs mb-2 mt-1 whitespace-nowrap">
									{category.questions.length}st - ca{" "}
									{Math.ceil(category.questions.length * 0.4)}min
								</p>

								<Link
									to={`/edu/t/${category.id}`}
									className="bg-blue-100 dark:bg-slate-950 transition-colors text-center text-sm font-semibold hover:opacity-80 text-blue-600 rounded-md px-2 w-full py-1 mt-4"
								>
									Påbörja
								</Link>
							</div>
						))}
					</div>

					<span className="font-semibold mt-8 flex flex-row items-center mb-2">
						Övergripande prov
						<p className="ml-1 text-xs text-stone-500">
							({BIG_TESTS_CATEGORIES.length}st)
						</p>
					</span>
					<div className="flex flex-col gap-2.5">
						{BIG_TESTS_CATEGORIES.map((category) => (
							<div
								key={category.id}
								className="flex flex-1 select-none flex-col relative transition-colors bg-slate-100 dark:bg-stone-950 dark:border-stone-900 border-slate-200 border px-4 py-4 rounded"
							>
								<p className="font-semibold">{category.name}</p>
								<p className="text-xs">{category.desc}</p>
								<p className="font-semibold text-xs mb-2 mt-1 whitespace-nowrap">
									{category.questions.length}st - ca{" "}
									{Math.ceil(category.questions.length * 0.6)}min
								</p>
								<Link
									to={`/edu/t/${category.id}`}
									className="bg-blue-100 dark:bg-slate-950 transition-colors text-center text-sm font-semibold hover:opacity-80 text-blue-600 rounded-md px-2 w-full py-1 mt-4"
								>
									Påbörja
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	); */
}
