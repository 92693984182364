const data: any = {
	"3670200400": "0400",
	"3670200401": "0401",
	"3670200402": "0402",
	"3670200403": "0403",
	"3670200404": "0404",
	"3670200405": "0405",
	"3670200406": "0406",
	"3670200407": "0407",
	"3670200408": "0408",
	"3670200409": "0409",
	"3670200410": "0410",
	"3670200411": "0411",
	"3670200412": "0412",
	"3670200413": "0413",
	"3670200414": "0414",
	"3670200415": "0415",
	"3670200416": "0416",
	"3670200417": "0417",
	"3670200418": "0418",
	"3670200419": "0419",
	"3670200420": "0420",
	"3670200421": "0421",
	"3670200422": "0422",
	"3670200423": "0423",
	"3670200424": "0424",
	"3670200425": "0425",
	"3670200426": "0426",
	"3670200427": "0427",
	"3670200428": "0428",
	"3670200429": "0429",
	"3670200430": "0430",
	"3670200431": "0431",
	"3670200432": "0432",
	"3670200433": "0433",
	"3670200434": "0434",
	"3670200435": "0435",
	"3670200436": "0436",
	"3670200437": "0437",
	"3670200438": "0438",
	"3670200439": "0439",
	"3670200440": "0440",
	"3670200441": "0441",
	"3670200442": "0442",
	"3670200443": "0443",
	"3670200444": "0444",
	"3670200445": "0445",
	"3670200446": "0446",
	"3670200447": "0447",
	"3670200448": "0448",
	"3670200449": "0449",
	"3670200450": "0450",
	"3670200451": "0451",
	"3670200452": "0452",
	"3670200453": "0453",
	"3670200454": "0454",
	"3670200455": "0455",
	"3670200456": "0456",
	"3670200457": "0457",
	"3670200458": "0458",
	"3670200459": "0459",
	"3670200460": "0460",
	"3670200461": "0461",
	"3670200462": "0462",
	"3670200463": "0463",
	"3670200464": "0464",
	"3670200465": "0465",
	"3670200466": "0466",
	"3670200467": "0467",
	"3670200468": "0468",
	"3670200469": "0469",
	"3670200470": "0470",
	"3670200471": "0471",
	"3670200472": "0472",
	"3670200473": "0473",
	"3670200474": "0474",
	"3670200475": "0475",
	"3670200476": "0476",
	"3670200477": "0477",
	"3670200478": "0478",
	"3670200479": "36702-00479",
	"3670200480": "0480",
	"3670200481": "0481",
	"3670200482": "0482",
	"3670200483": "0483",
	"3670200484": "0484",
	"3670200485": "0485",
	"3670200486": "0486",
	"3670200490": "0490",
	"3670200491": "0491",
	"3670200492": "0492",
	"3670200493": "0493",
	"3670200494": "0494",
	"3670205000": "5000",
	"3670205001": "5001",
	"3670205002": "5002",
	"3670205003": "5003",
	"3670205004": "5004",
	"3670205005": "5005",
	"3670205006": "5006",
	"3670205007": "5007",
	"3670205008": "5008",
	"3670205009": "5009",
	"3670205010": "5010",
	"3670205011": "5011",
	"3670205012": "5012",
	"3670205013": "5013",
	"3670205014": "5014",
	"3670205015": "5015",
	"3670205016": "5016",
	"3670205017": "5017",
	"3670205018": "5018",
	"3670205019": "5019",
	"3670205020": "5020",
	"3670205021": "5021",
	"3670205022": "5022",
	"3670205023": "5023",
	"3670205024": "5024",
	"3670205025": "5025",
	"3670205026": "5026",
	"3670205027": "5027",
	"3670205028": "5028",
	"3670205029": "5029",
	"3670205030": "5030",
	"3670205031": "5031",
	"3670205032": "5032",
	"3670205033": "5033",
	"3670205034": "5034",
	"3670205035": "5035",
	"3670205036": "5036",
	"3670205037": "5037",
	"3670205038": "5038",
	"3670205039": "5039",
	"3670205040": "5040",
	"3670205041": "5041",
	"3670205042": "5042",
	"3670205043": "5043",
	"3670205044": "5044",
	"3670205045": "5045",
	"3670205046": "5046",
	"3670205047": "5047",
	"3670205048": "5048",
	"3670205049": "5049",
	"3670205050": "5050",
	"3670205051": "5051",
	"3670205052": "5052",
	"3670205053": "5053",
	"3670205054": "5054",
	"3670205055": "5055",
	"3670205056": "5056",
	"3670205057": "5057",
	"3670205058": "5058",
	"3670205059": "5059",
	"3670205060": "5060",
	"3670205061": "5061",
	"3670205062": "5062",
	"3670205063": "5063",
	"3670205064": "5064",
	"3670205065": "5065",
	"3670205066": "5066",
	"3670205067": "5067",
	"3670205068": "5068",
	"3670205069": "5069",
	"3670205070": "5070",
	"3670205071": "5071",
	"3670205072": "5072",
	"3670205073": "5073",
	"3670205074": "5074",
	"3670205075": "5075",
	"3670205076": "5076",
	"3670205077": "5077",
	"3670205078": "5078",
	"3670205079": "5079",
	"3670205080": "5080",
	"3670205081": "5081",
	"3670205082": "5082",
	"3670205083": "5083",
	"3670205084": "5084",
	"3670205085": "5085",
	"3670205086": "5086",
	"3670205087": "5087",
	"3670205088": "5088",
	"3670205089": "5089",
	"3670205090": "5090",
	"3670205091": "5091",
	"3670205092": "5092",
	"3670205093": "5093",
	"3670205094": "5094",
	"3670205095": "5095",
	"3670205096": "5096",
	"3670205097": "5097",
	"3670205098": "5098",
	"3670205099": "5099",
	"3670205100": "5100",
	"3670205101": "5101",
	"3670205102": "5102",
	"3670205103": "5103",
	"3670205104": "5104",
	"3670205105": "5105",
	"3670205106": "5106",
	"3670205107": "5107",
	"3670205108": "5108",
	"3670205109": "5109",
	"3670205110": "5110",
	"3670205111": "5111",
	"3670205112": "5112",
	"3670205113": "5113",
	"3670205114": "5114",
	"3670205115": "5115",
	"3670205116": "5116",
	"3670205117": "5117",
	"3670205118": "5118",
	"3670205119": "5119",
	"3670205120": "5120",
	"3670205121": "5121",
	"3670205122": "5122",
	"3670205123": "5123",
	"3670205124": "5124",
	"3670205126": "5126",
	"3670205127": "5127",
	"3670205128": "5128",
	"3670205129": "5129",
	"3670205130": "5130",
	"3670205131": "5131",
	"3670205132": "5132",
	"3670205133": "5133",
	"3670205134": "5134",
	"3670205135": "5135",
	"3670205136": "5136",
	"3670205137": "5137",
	"3670205138": "5138",
	"3670205139": "5139",
	"3670205140": "5140",
	"3670205141": "5141",
	"3670205142": "5142",
	"3670205143": "5143",
	"3670205144": "5144",
	"3670205145": "5145",
	"3670205146": "5146",
	"3670205147": "5147",
	"3670205148": "5148",
	"3670205150": "5150",
	"3670205151": "5151",
	"3670205152": "5152",
	"3670205153": "5153",
	"3670205154": "5154",
	"3670205155": "5155",
	"3670205156": "5156",
	"3670205157": "5157",
	"3670205158": "5158",
	"3670205159": "5159",
	"3670205160": "5160",
	"3670205161": "5161",
	"3670205162": "5162",
	"3670205163": "5163",
	"3670205164": "5164",
	"3670205165": "5165",
	"3670205166": "5166",
	"3670205167": "5167",
	"3670205168": "5168",
	"3670205169": "5169",
	"3670205170": "5170",
	"3670205171": "5171",
	"3670205172": "5172",
	"3670205173": "5173",
	"3670205174": "5174",
	"3670205175": "5175",
	"3670205176": "5176",
	"3670205177": "5177",
	"3670205178": "5178",
	"3670205179": "5179",
	"3670205180": "5180",
	"3670205181": "5181",
	"3670205182": "5182",
	"3670205183": "5183",
	"3670205184": "5184",
	"3670205185": "5185",
	"3670205186": "5186",
	"3670205187": "5187",
	"3670205188": "5188",
	"3670205189": "5189",
	"3670205190": "5190",
	"3670205191": "5191",
	"3670205192": "5192",
	"3670205193": "5193",
	"3670205194": "5194",
	"3670205195": "5195",
	"3670205196": "5196",
	"3670205197": "5197",
	"3670205201": "5201",
	"3670205202": "5202",
	"3670205203": "5203",
	"3670205204": "5204",
	"3670205205": "5205",
	"3670205206": "5206",
	"3670205207": "5207",
	"3670205208": "5208",
	"3670205209": "5209",
	"3670205210": "5210",
	"3670205211": "5211",
	"3670205212": "5212",
	"3670205213": "5213",
	"3670205214": "5214",
	"3670205215": "5215",
	"3670205216": "5216",
	"3670205217": "5217",
	"3670205500": "5500",
	"3670205501": "5501",
	"3670205502": "5502",
	"3670205503": "5503",
	"3670205504": "5504",
	"3670205505": "5505",
	"3670205506": "5506",
	"3670205507": "5507",
	"3670205508": "5508",
	"3670205509": "5509",
	"3670205510": "5510",
	"3670205511": "5511",
	"3670205512": "5512",
	"3670205513": "5513",
	"3670205514": "5514",
	"3670205515": "5515",
	"3670205516": "5516",
	"3670205517": "5517",
	"3670205518": "5518",
	"3670205519": "5519",
	"3670205520": "5520",
	"3670205521": "5521",
	"3670205522": "5522",
	"3670205523": "5523",
	"3670205524": "5524",
	"3670205525": "5525",
	"3670205526": "5226",
	"3670205527": "5527",
	"3670205528": "5528",
	"3670205529": "5529",
	"3670205530": "5530",
	"3670205531": "5531",
	"3670205532": "5532",
	"3670205533": "5533",
	"3670205534": "5534",
	"3670205535": "5535",
	"3670205536": "5536",
	"3670205537": "5537",
	"3670205538": "5538",
	"3670205539": "5539",
	"3670205540": "5540",
	"3670205541": "5541",
	"3670205542": "5542",
	"3670205543": "5543",
	"3670205544": "5544",
	"3670205545": "5545",
	"3670205546": "5546",
	"3670205547": "5547",
	"3670205548": "5548",
	"3670205549": "5549",
	"3670205550": "5550",
	"3670205551": "5551",
	"3670205552": "5552",
	"3670205553": "5553",
	"3670205554": "5554",
	"3670205555": "5555",
	"3670205556": "5556",
	"3670205557": "5557",
	"3670205558": "5558",
	"3670205559": "5559",
	"3670205560": "5560",
	"3670205561": "5561",
	"3670205562": "5562",
	"3670205563": "5563",
	"3670205564": "5564",
	"3670205565": "5565",
	"3670205566": "5566",
	"3670205567": "5567",
	"3670205568": "5568",
	"3670205569": "5569",
	"3670205570": "5570",
	"3670205571": "5571",
	"3670205572": "5572",
	"3670205573": "5573",
	"3670205574": "5574",
	"3670205575": "5575",
	"3670205576": "5576",
	"3670205577": "5577",
	"3670205578": "5578",
	"3670205579": "5579",
	"3670205580": "5580",
	"3670205581": "5581",
	"3670205582": "5582",
	"3670205583": "5583",
	"3670205584": "5584",
	"3670205585": "5585",
	"3670205586": "5586",
	"3670205587": "5587",
	"3670205588": "5588",
	"3670205589": "5589",
	"3670205590": "5590",
	"3670205591": "5591",
	"3670205592": "5592",
	"3670205593": "5593",
	"3670205594": "5594",
	"3670205595": "5595",
	"3670205596": "5596",
	"3670205597": "5597",
	"3670205598": "5598",
	"3670205599": "5599",
	"3670205600": "5600",
	"3670205601": "5601",
	"3670205602": "5602",
	"3670205603": "5603",
	"3670205604": "5604",
	"3670205605": "5605",
	"3670205606": "5606",
	"3670205607": "5607",
	"3670205608": "5608",
	"3670205609": "5609",
	"3670205610": "5610",
	"3670206058": "6058",
	"3670206059": "6059",
	"3670206600": "6600",
	"3670206601": "6601",
	"3670206602": "6602",
	"3670206603": "6603",
	"3670206604": "6604",
	"3670206605": "6605",
	"3670206606": "6606",
	"3670206607": "6607",
	"3670206608": "6608",
	"3670206610": "6610",
	"3670206611": "6611",
	"3670206612": "6612",
	"3670206613": "6613",
	"3670206614": "6614",
	"3670206615": "6615",
	"3670206616": "6616",
	"3670297404": "edtest",
	"3670299994": "36702-99994",
	"3670299996": "36702-99996",
	"3670300760": "9031021000557760",
	"3670307700": "9031021000557700",
	"3670307701": "9031021000557701",
	"3670307702": "9031021000557702",
	"3670307703": "9031021000557703",
	"3670307704": "9031021000557704",
	"3670307705": "9031021000557705",
	"3670307706": "9031021000557706",
	"3670307707": "9031021000557707",
	"3670307708": "9031021000557708",
	"3670307709": "9031021000557709",
	"3670307710": "9031021000557710",
	"3670307711": "9031021000557711",
	"3670307712": "9031021000557712",
	"3670307713": "9031021000557713",
	"3670307714": "9031021000557714",
	"3670307715": "9031021000557715",
	"3670307716": "9031021000557716",
	"3670307717": "9031021000557717",
	"3670307718": "9031021000557718",
	"3670307719": "9031021000557719",
	"3670307720": "9031021000557720",
	"3670307721": "9031021000557721",
	"3670307722": "9031021000557722",
	"3670307723": "9031021000557723",
	"3670307724": "9031021000557724",
	"3670307725": "9031021000557725",
	"3670307726": "9031021000557726",
	"3670307727": "9031021000557727",
	"3670307728": "9031021000557728",
	"3670307729": "9031021000557729",
	"3670307730": "9031021000557730",
	"3670307731": "9031021000557731",
	"3670307732": "9031021000557732",
	"3670307733": "9031021000557733",
	"3670307734": "9031021000557734",
	"3670307735": "9031021000557735",
	"3670307736": "9031021000557736",
	"3670307737": "9031021000557737",
	"3670307738": "9031021000557738",
	"3670307739": "9031021000557739",
	"3670307740": "9031021000557740",
	"3670307741": "9031021000557741",
	"3670307742": "9031021000557742",
	"3670307743": "9031021000557743",
	"3670307758": "9031021000557758",
	"3670307759": "9031021000557759",
	"3670307760": "9031021000557760",
	"3670307761": "9031021000557761",
	"3670307762": "9031021000557762",
	"3670307763": "9031021000557763",
	"3670307764": "9031021000557764",
	"3670307765": "9031021000557765",
	"3670307766": "9031021000557766",
	"3670307767": "9031021000557767",
	"3670307768": "9031021000557768",
	"3670307769": "9031021000557769",
	"3670307770": "9031021000557770",
	"3670307772": "9031021000557772",
	"3670307773": "9031021000557773",
	"3670307774": "9031021000557774",
	"3670307775": "9031021000557775",
	"3670307776": "9031021000557776",
	"3670307777": "9031021000557777",
	"3670307778": "9031021000557778",
	"3670307779": "9031021000557779",
	"3670307780": "9031021000557780",
	"3670341702": "9031021000557758",
	"3670341758": "9031021000557758",
	"3670341759": "9031021000551759",
	"3670341760": "36703-41760",
	"3670341761": "36703-41761",
	"3670341762": "36703-41762",
	"3670341763": "36703-41763",
	"3670341764": "36703-41764",
	"3670341765": "36703-41765",
	"3670341766": "36703-41766",
	"3670341767": "36703-41767",
	"3670341768": "36703-41768",
	"3670341769": "36703-41769",
	"3670341770": "36703-41770",
	"3670341772": "9031021000557772",
	"3670397404": "36703-97404",
	"3670399969": "9031021000499969",
	"3670399980": "9031021000599980",
	"3670399981": "9031021000599981",
};

export default data;
