import { useState } from "react";
import * as XLSX from "xlsx";

import InputFile from "../components/inputFile";
import { createXLSXFile, getWSJSON } from "../helper/xlsx";
import TRIP_TRAFFIC_AREA_OWBER from "../data/lines/lines";

const HEADERS = [
	"Datum",
	"Linje",
	"Tur",
	"Hållplats",
	"Hållplats sekvens",
	"Hållplats lat",
	"Hållplats lon",
	"Ankomisttid",
	"Avgångstid",
];

const markSpecialTrip = (trip: any) => {
	if (trip[1] === "626" && (trip[2] === "11605" || trip[2] === "20630")) {
		return "EXT_COMPANY";
	}

	if (
		(trip[1] === "636" && trip[2] === "11524") ||
		(trip[1] === "634" && trip[2] === "12150") ||
		("632" === trip[1] && trip[2] === "11846")
	) {
		return "NO_NEED_COMPLETE";
	}
};

const combineUniqueItems = (list: any[], key: string) => {
	const map: any = {};
	const res: any = [];

	for (let i = 0; i < list.length; i++) {
		const item = list[i];

		if (map[item[key]]) {
			map[item[key]] = map[item[key]] + 1;
		} else {
			map[item[key]] = 1;
		}
	}

	for (const [key, value] of Object.entries(map)) {
		res.push(`${key} (${value})`);
	}

	return res;
};

const getStopsPerLine = (list: any[]) => {
	const map: any = {};
	const res: any = [];

	for (let i = 0; i < list.length; i++) {
		const item = list[i];

		if (map[item["Name"]]) {
			map[item["Name"]] = map[item["Name"]] + 1;
		} else {
			map[item["Name"]] = 1;
		}
	}

	for (const k of Object.entries(map)) {
		res.push(k);
	}

	// Get top 10.
	return res
		.sort((a: any, b: any) => b[1] - a[1])
		.slice(0, 10)
		.map((item: any) => `- ${item[0]} (${item[1]})`)
		.join("\n");
};

const checkIfIsBookingTrip = (linje: number, tur: number) => {
	if (linje === 649 && tur === 21356) {
		return 1;
	}

	if (
		(linje === 640 &&
			(tur === 21923 ||
				tur === 11900 ||
				tur === 21336 ||
				tur === 11313 ||
				tur === 21117 ||
				tur === 11054 ||
				tur === 20923 ||
				tur === 10900)) ||
		(linje === 648 &&
			(tur === 21752 ||
				tur === 11710 ||
				tur === 20951 ||
				tur === 10910 ||
				tur === 21923 ||
				tur === 11900 ||
				tur === 21323 ||
				tur === 11300 ||
				tur === 21123 ||
				tur === 11100 ||
				tur === 20923 ||
				tur === 10900)) ||
		(linje === 653 &&
			(tur === 21455 ||
				tur === 11413 ||
				tur === 21225 ||
				tur === 11143 ||
				tur === 20930 ||
				tur === 20833)) ||
		(linje === 662 && (tur === 20833 || tur === 10807)) ||
		(linje === 663 && (tur === 11611 || tur === 11510)) ||
		(linje === 667 &&
			(tur === 11745 ||
				tur === 21712 ||
				tur === 11245 ||
				tur === 21216 ||
				tur === 10824 ||
				tur === 20756)) ||
		(linje === 667 &&
			(tur === 21926 ||
				tur === 11903 ||
				tur === 21353 ||
				tur === 11330 ||
				tur === 21126 ||
				tur === 11103 ||
				tur === 20951 ||
				tur === 10908))
	) {
		return 1;
	}

	return 0;
};

const checkIfIsPartialTrip = (linje: number, tur: number) => {
	if (linje === 626 && (tur === 11605 || tur === 20630)) {
		return 1;
	}

	if (
		(linje === 636 && (tur === 11524 || tur === 12050)) ||
		(linje === 634 && (tur === 11718 || tur === 12150)) ||
		(linje === 632 && (tur === 11846 || tur === 11656)) ||
		(linje === 649 && (tur === 11326 || tur === 11505))
	) {
		return 1;
	}

	return 0;
};

const LINES_WITH_VARIANTS: any = {
	602: ["602", "602X"],
	612: ["612", "612X"],
	620: ["620", "620X"],
	622: ["622", "622X"],
	623: ["623V", "623H"],
	624: ["624", "624C"],
	628: ["628", "628C"],
	631: ["631", "631X"],
	637: ["637", "637Ã", "637Ã"],
	640: ["640", "640Z"],
	641: ["641", "641X"],
	648: ["648", "648Z"],
	653: ["653", "653V"],
	670: ["670", "670X"],
	676: ["676", "676X"],
};

const doesTripExist = (trips: any[], trip: any) => {
	if (LINES_WITH_VARIANTS[trip.slice(10, 13)]) {
		const variants = LINES_WITH_VARIANTS[trip.slice(10, 13)];

		for (let i = 0; i < variants.length; i++) {
			const variant = variants[i];

			if (trips[trip.slice(0, 10) + variant + trip.slice(13, trip.length)]) {
				console.log("RAN");
				return trips[trip.slice(0, 10) + variant + trip.slice(13, trip.length)];
			}
		}
	}

	return trips[trip];
};

export default function MissingTripData() {
	const [step, setStep] = useState<number>(0);
	const [sqlTrips, setSQLTrips] = useState<any>([]);
	const [slTrips, setSLTrips] = useState<any>([]);
	const [wb, setWb] = useState<any>(null);
	const [prettyReport, setPrettyReport] = useState<string>("");

	const handleSQLFile = (file: any) => {
		if (!file) {
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				const content = evt.target.result;
				const decodedContent = new TextDecoder("utf-8").decode(content);
				setSQLTrips(JSON.parse(decodedContent));
			};

			reader.readAsArrayBuffer(file);
		} catch (error) {
			alert("Något gick fel");
		}
	};

	const handleSlData = (file: any) => {
		if (!file) {
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				// Parse data
				const bstr = event.target.result;

				setSLTrips(getWSJSON(bstr));
			};
			reader.readAsBinaryString(file);
		} catch (error) {}
	};

	const getTripData = (item: any) => {
		let avvikelse = 0;

		if (item["Avgångar helt utförda"] === 0) {
			avvikelse = 1;
		}

		return [
			item["Linje"],
			1,
			avvikelse,
			0,
			item["Avgångar delvis utförda"],
			item["Avgångar helt utförda"] === 0 && item["Avgångar delvis utförda"] === 0 ? 1 : 0,
			isNaN(parseInt(item["Completeness"])) ? 0 : parseInt(item["Completeness"]),
		];
	};

	const handleNext = () => {
		if (slTrips.length === 0 || sqlTrips.length === 0) {
			return;
		}

		const UNIUQE_TRIPS: any = {};
		const UNIQUE_ROUTES: any = {};
		const UNIQUE_LINES: any = {};
		const UNIUQ_STOPS: any = {};
		const ALL_TRIPS: any = [];

		const PATTERN_TRIPS: any = {};

		for (let i = 0; i < sqlTrips.length; i++) {
			const item = sqlTrips[i];

			const uniqueTrip =
				item.OperatingCalendarDay.split("T")[0] + item.LineNameLong + item.JourneyName;
			const uniqueRoute = item.LineNameLong + item.JourneyName;

			// Removed trips that arent real.
			if (
				!(
					parseInt(item.LineNameLong) > 177 &&
					item.LineNameLong !== "TOM" &&
					item.LineNameLong !== "TOM2" &&
					item.JourneyName.length === 5 &&
					(item.JourneyName[0] === "1" ||
						item.JourneyName[0] === "2" ||
						item.JourneyName[0] === "3" ||
						item.JourneyName[0] === "4")
				)
			) {
				continue;
			}

			const trafficArea = TRIP_TRAFFIC_AREA_OWBER[item.LineNameLong];

			// IF not E35 or E38, skip.
			if (!trafficArea || (trafficArea !== "E35" && trafficArea !== "E38")) {
				continue;
			}

			if (PATTERN_TRIPS[uniqueRoute]) {
				// CHeck if trip for this day already exists.
				if (PATTERN_TRIPS[uniqueRoute][item.OperatingCalendarDay]) {
					continue;
				} else {
					PATTERN_TRIPS[uniqueRoute][item.OperatingCalendarDay] = 1;
				}
			} else {
				PATTERN_TRIPS[uniqueRoute] = {
					[item.OperatingCalendarDay]: 1,
				};
			}

			// Check if key exists.
			if (UNIQUE_ROUTES[uniqueRoute]) {
				UNIQUE_ROUTES[uniqueRoute].push(item);
			} else {
				UNIQUE_ROUTES[uniqueRoute] = [item];
			}

			// Check if key exists.
			if (UNIUQE_TRIPS[uniqueTrip]) {
				UNIUQE_TRIPS[uniqueTrip].push(item);
			} else {
				UNIUQE_TRIPS[uniqueTrip] = [item];
			}

			// Check if key exists.
			if (UNIQUE_LINES[item.LineNameLong]) {
				UNIQUE_LINES[item.LineNameLong].push(item);
			} else {
				UNIQUE_LINES[item.LineNameLong] = [item];
			}

			if (UNIUQ_STOPS[item.Name]) {
				UNIUQ_STOPS[item.Name].push(item);
			} else {
				UNIUQ_STOPS[item.Name] = [item];
			}

			ALL_TRIPS.push([
				item.OperatingCalendarDay,
				item.LineNameLong,
				item.JourneyName,
				item.Name,
				item.SequenceInJourney,
				item.Latitude,
				item.Longitude,
				item.PlannedArrivalTime,
				item.PlannedDepartureTime,
			]);
		}

		// Combine pattern tirps.
		const PATTERN_TRIPS_ARRAY: any[] = Object.entries(PATTERN_TRIPS);
		const PATTERN_TRIPS_COMBINED: any = [];

		for (let i = 0; i < PATTERN_TRIPS_ARRAY.length; i++) {
			const [trip, days] = PATTERN_TRIPS_ARRAY[i];

			const keys = Object.keys(days);

			for (let j = 0; j < keys.length; j++) {
				const key = keys[j];

				PATTERN_TRIPS_COMBINED.push([key.split("T")[0], trip]);
			}
		}

		const PATTERN_TRIPS_COMBINED_2: any = {};

		for (let i = 0; i < PATTERN_TRIPS_ARRAY.length; i++) {
			const [trip, days] = PATTERN_TRIPS_ARRAY[i];

			const keys = Object.keys(days);

			for (let j = 0; j < keys.length; j++) {
				const key = keys[j];

				if (PATTERN_TRIPS_COMBINED_2[trip]) {
					PATTERN_TRIPS_COMBINED_2[trip] = PATTERN_TRIPS_COMBINED_2[trip] + 1;
				} else {
					PATTERN_TRIPS_COMBINED_2[trip] = 1;
				}
			}
		}

		const SL_TRIPS_MISSING: any = [];
		const SL_TRIPS_LINE_MAP: any = {};

		for (let i = 0; i < slTrips.length; i++) {
			const item = slTrips[i];

			const date = item["Trafikdygn"].toString();
			const year = date.substring(0, 4);
			const month = date.substring(4, 6);
			const day = date.substring(6, 8);
			const dateStr = `${year}-${month}-${day}`;

			const uniqueTrip = `${dateStr}${item["Linje"]}${item["Tur"]}`;

			if (
				item["Linje"] === 646 ||
				item["Linje"] === 648 ||
				item["Linje"] === 669 ||
				item["Linje"] === 653 ||
				item["Linje"] === 640 ||
				item["Linje"] === 663 ||
				item["Linje"] === 649 ||
				item["Linje"] === 667
			) {
				continue;
			}

			if (!doesTripExist(UNIUQE_TRIPS, uniqueTrip) && item["Completeness"] === "-") {
				SL_TRIPS_MISSING.push([dateStr, item["Linje"], item["Tur"], item["Completeness"]]);
			}

			const avvikelse = getTripData(item);

			if (SL_TRIPS_LINE_MAP[item["Linje"]]) {
				let temp = [...SL_TRIPS_LINE_MAP[item["Linje"]]];

				temp[1] = temp[1] + avvikelse[1];
				temp[2] = temp[2] + avvikelse[2];
				temp[4] = temp[4] + avvikelse[4];
				temp[5] = temp[5] + avvikelse[5];
				temp[6] = temp[6] + avvikelse[6];

				SL_TRIPS_LINE_MAP[item["Linje"]] = temp;
			} else {
				SL_TRIPS_LINE_MAP[item["Linje"]] = avvikelse;
			}
		}

		const SL_TRIPS_ARRAY: any = Object.values(SL_TRIPS_LINE_MAP);

		for (let i = 0; i < SL_TRIPS_ARRAY.length; i++) {
			const item: any = SL_TRIPS_ARRAY[i];

			item[3] = Math.round((item[2] / item[1]) * 100);
			item[6] = Math.round(item[6] / item[1]);
			item[7] = UNIQUE_LINES[item[0]]
				? getStopsPerLine(UNIQUE_LINES[item[0]])
				: "Avvikelse saknas i CTS";
		}

		const UNIQUE_ROUTES_ARRAY: any[] = Object.values(UNIQUE_ROUTES);
		const UNIQUE_LINES_ARRAY: any[] = Object.values(UNIQUE_LINES);
		const UNIQUE_STOPS_ARRAY: any[] = Object.values(UNIUQ_STOPS);

		const TRIPS_SUMMARY = [];
		const LINES_SUMMARY = [];
		const STOPS_SUMMARY = [];

		let EXTERN_COMPANY = 0;
		let TRIPS_WITHOUT_NEED_COMPLETE = 0;

		for (let i = 0; i < ALL_TRIPS.length; i++) {
			const item = ALL_TRIPS[i];

			const specialTrip = markSpecialTrip(item);

			if (specialTrip === "EXT_COMPANY") {
				EXTERN_COMPANY++;
			}

			if (specialTrip === "NO_NEED_COMPLETE") {
				TRIPS_WITHOUT_NEED_COMPLETE++;
			}
		}

		for (let i = 0; i < UNIQUE_ROUTES_ARRAY.length; i++) {
			TRIPS_SUMMARY.push([
				UNIQUE_ROUTES_ARRAY[i][0].LineNameLong,
				UNIQUE_ROUTES_ARRAY[i][0].JourneyName,
				UNIQUE_ROUTES_ARRAY[i].length,
			]);
		}

		for (let i = 0; i < UNIQUE_LINES_ARRAY.length; i++) {
			LINES_SUMMARY.push([
				UNIQUE_LINES_ARRAY[i][0].LineNameLong,
				UNIQUE_LINES_ARRAY[i].length,
				...combineUniqueItems(UNIQUE_LINES_ARRAY[i], "Name"),
			]);
		}

		for (let i = 0; i < UNIQUE_STOPS_ARRAY.length; i++) {
			STOPS_SUMMARY.push([
				UNIQUE_STOPS_ARRAY[i][0].Name,
				UNIQUE_STOPS_ARRAY[i].length,
				...combineUniqueItems(UNIQUE_STOPS_ARRAY[i], "LineNameLong"),
			]);
		}

		const SUMMARY = XLSX.utils.aoa_to_sheet([
			[`Saknade hållplatser:`, ALL_TRIPS.length],
			[`Hållplatser körs ej av Transdev: `, EXTERN_COMPANY],
			[`Hållplatser som inte behövs trafikeras när tom:`, TRIPS_WITHOUT_NEED_COMPLETE],
			["Avvikelser som inte matchar SL", SL_TRIPS_MISSING.length],
		]);

		const SL_MOST_OFF = XLSX.utils.aoa_to_sheet([
			[
				"Linje",
				"Antal turer",
				"Antal avvikelse",
				"Andel avvikelser",
				"Delvis",
				"Ej utförda",
				"Completness",
			],
			...SL_TRIPS_ARRAY,
		]);

		const ALL_TRIPS_SHEET = XLSX.utils.aoa_to_sheet([HEADERS, ...ALL_TRIPS]);
		const LINES_SHEET = XLSX.utils.aoa_to_sheet([["Linje", "Antal"], ...LINES_SUMMARY]);
		const TRIPS_SHEET = XLSX.utils.aoa_to_sheet([["Linje", "Tur", "Antal"], ...TRIPS_SUMMARY]);
		const STOPS_SHEET = XLSX.utils.aoa_to_sheet([["Hållplats", "Antal"], ...STOPS_SUMMARY]);
		const SL_SHEET = XLSX.utils.aoa_to_sheet([[], ...SL_TRIPS_MISSING]);
		const SL_COMMON_TRIPS = XLSX.utils.aoa_to_sheet([[], ...PATTERN_TRIPS_COMBINED]);
		const SL_COMMON_TRIPS_2 = XLSX.utils.aoa_to_sheet([
			[],
			...Object.entries(PATTERN_TRIPS_COMBINED_2),
		]);

		// Create workbook
		const NEW_WB = XLSX.utils.book_new();

		// Add the worksheet to the workbook
		XLSX.utils.book_append_sheet(NEW_WB, SUMMARY, "Sammanfattning");
		XLSX.utils.book_append_sheet(NEW_WB, ALL_TRIPS_SHEET, "Alla CTS Turer");
		XLSX.utils.book_append_sheet(NEW_WB, LINES_SHEET, "CTS per linje");
		XLSX.utils.book_append_sheet(NEW_WB, TRIPS_SHEET, "CTS per turer");
		XLSX.utils.book_append_sheet(NEW_WB, STOPS_SHEET, "CTS per stopställe");
		XLSX.utils.book_append_sheet(NEW_WB, SL_SHEET, "Turer som inte reg hos SL");
		XLSX.utils.book_append_sheet(NEW_WB, SL_MOST_OFF, "Mest populära avvikelser");
		XLSX.utils.book_append_sheet(NEW_WB, SL_COMMON_TRIPS, "Vanligaste turer");
		XLSX.utils.book_append_sheet(NEW_WB, SL_COMMON_TRIPS_2, "Vanligaste turer 2");

		setWb(NEW_WB);

		const TOTAL_SL_LINE_STATS: any = {};
		let TOTAL_TRIPS = 0;
		let TOTAL_DEVIATIONS = 0;
		let TOTAL_PARTIAL_DATA = 0;
		let TOTAL_NO_DATA = 0;
		let TOTAL_BOOKING_TRIPS = 0;
		let TOTAL_PARTIAL_TRIPS = 0;
		let TOTAL_DEVIATIONS_ON_BOOKING_TRIPS = 0;
		let TOTAL_DEVIATIONS_ON_PARTIAL_TRIPS = 0;

		for (let i = 0; i < slTrips.length; i++) {
			const item = slTrips[i];

			const deviation = item["Avgångar helt utförda"] === 0 ? 1 : 0;
			const partialData = item["Avgångar delvis utförda"] === 1 ? 1 : 0;
			const noData = item["Ej utförda avgångar"] === 1 ? 1 : 0;
			const completeness = isNaN(parseInt(item["Completeness"]))
				? 0
				: parseInt(item["Completeness"]);

			if (TOTAL_SL_LINE_STATS[item["Linje"]]) {
				let temp = { ...TOTAL_SL_LINE_STATS[item["Linje"]] };

				temp.trips = temp.trips + 1;
				temp.deviations = temp.deviations + deviation;
				temp.partialData = temp.partialData + partialData;
				temp.noData = temp.noData + noData;
				temp.completeness = temp.completeness + completeness;

				TOTAL_SL_LINE_STATS[item["Linje"]] = temp;
			} else {
				TOTAL_SL_LINE_STATS[item["Linje"]] = {
					trips: 1,
					deviations: deviation,
					partialData: partialData,
					noData,
					completeness: completeness,
				};
			}

			TOTAL_TRIPS++;
			TOTAL_DEVIATIONS += deviation;
			TOTAL_PARTIAL_DATA += partialData;
			TOTAL_NO_DATA += noData;

			// Check if booking trip.
			if (checkIfIsBookingTrip(item["Linje"], item["Tur"])) {
				TOTAL_BOOKING_TRIPS++;

				// Check if deviation.
				if (deviation) {
					TOTAL_DEVIATIONS_ON_BOOKING_TRIPS++;
				}
			}

			// CHeck if partial trip.
			if (checkIfIsPartialTrip(item["Linje"], item["Tur"])) {
				TOTAL_PARTIAL_TRIPS++;

				// Check if deviation.
				if (deviation) {
					TOTAL_DEVIATIONS_ON_PARTIAL_TRIPS++;
				}
			}
		}

		// Pretty report.
		let prettyReport = "";

		// Add total trips.
		prettyReport += `Antal turer: ${TOTAL_TRIPS}st\n`;

		// Add total deviations.
		prettyReport += `Antal avvikelser: ${TOTAL_DEVIATIONS}st\n`;

		// Add total partial data.
		prettyReport += `TAntal delvis utförda: ${TOTAL_PARTIAL_DATA}st\n`;

		// Add total no data.
		prettyReport += `Antal ej utförda: ${TOTAL_NO_DATA}st\n`;

		// Add total booking trips.
		prettyReport += `Antal bokningsturer: ${TOTAL_BOOKING_TRIPS}st\n`;

		// Add total partial trips.
		prettyReport += `Antal turer som bara behöver trafikeras delvis: ${TOTAL_PARTIAL_TRIPS}st\n\n`;

		const TPD = (TOTAL_DEVIATIONS / TOTAL_TRIPS) * 100;

		// Procent of trips with deviation.
		prettyReport += `Procent avvikelser: ${TPD.toFixed(3)}%\n`;

		const AVB = (TOTAL_DEVIATIONS_ON_BOOKING_TRIPS / TOTAL_TRIPS) * 100;

		// Procent of booking trips with deviation.
		prettyReport += `Andel avvikelser på bokningsturer: ${AVB.toFixed(3)}%\n`;

		const ADT = (TOTAL_DEVIATIONS_ON_PARTIAL_TRIPS / TOTAL_TRIPS) * 100;

		// Procent of partial trips with deviation.
		prettyReport += `Andel avvikelser på delvis turer: ${ADT.toFixed(3)}%\n`;

		// Procentage of trips completed.
		prettyReport += `Totalt utförd (Ej delvis eller bokingsturer räknade): ${(
			100 -
			TPD +
			AVB +
			ADT
		).toFixed(3)}%\n\n`;

		prettyReport += `\n\n\n`;

		// Only report these areas.
		const trafficAreasToReport: any = { E35: true, E38: true };

		// All lines in array sorted by traffic area.
		const linesArray: any[] = Object.entries(TRIP_TRAFFIC_AREA_OWBER).sort((a: any, b: any) => {
			if (a[1] < b[1]) {
				return -1;
			} else if (a[1] > b[1]) {
				return 1;
			} else {
				return 0;
			}
		});

		// Loop through all lines.
		for (let i = 0; i < linesArray.length; i++) {
			// Throw away lines that arent in the report.
			if (!trafficAreasToReport[linesArray[i][1]]) {
				continue;
			}

			const SL_STATS = TOTAL_SL_LINE_STATS[linesArray[i][0].slice(0, 3)];

			if (SL_STATS === undefined) {
				console.log(linesArray[i][0]);
				continue;
			}

			let lineTotal = "";

			// Add Line name.
			lineTotal += `Linje ${linesArray[i][0]}\n\n`;

			// Number of trips
			lineTotal += `Turer: ${SL_STATS.trips}st\n`;

			// Trips with deviation.
			lineTotal += `Avvikelser: ${SL_STATS.deviations}st\n`;

			// Procent of trips with deviation.
			lineTotal += `Procent avvikelser: ${Math.round(
				(SL_STATS.deviations / SL_STATS.trips) * 100
			)}%\n`;

			// Procentage of trips completed.
			lineTotal += `Totalt utförd: ${
				Math.round((SL_STATS.completeness / SL_STATS.trips) * 100) / 100
			}%\n\n`;

			// Stops with deviation.
			lineTotal += `Hållplatser med avvikelser i CTS:\n${
				UNIQUE_LINES[linesArray[i][0]]
					? getStopsPerLine(UNIQUE_LINES[linesArray[i][0]])
					: "Avvikelse saknas i CTS"
			}\n\n\n`;

			lineTotal += `Problem\n\n`;

			lineTotal += `Åtgärd\n`;

			prettyReport += lineTotal + "\n\n\n";
		}

		setPrettyReport(prettyReport);
		setStep(1);
	};

	const handleDownload = () => {
		createXLSXFile(wb, "rapport.xlsx");
	};

	const writeToClipboard = async (s: string) => {
		try {
			await navigator.clipboard.writeText(s);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-20">Saknad turdata</h1>
			<p className="mb-3 text-sm">Skapa rapport för saknad turdata</p>

			{step === 0 && (
				<>
					<p className="mt-6 text-sm font-semibold">
						Turer som saknar data CTS
						<span className="ml-1 text-gray-500 font-normal text-[10px]">(EXTDB)</span>
					</p>

					<InputFile name="turdata" accept=".json" onHandleFile={handleSQLFile} />
					<p className="mt-6 text-sm font-semibold">
						Turer som saknar data SL
						<span className="ml-1 text-gray-500 font-normal text-[10px]">(SL)</span>
					</p>
					<InputFile name="sldata" accept=".xlsx" onHandleFile={handleSlData} />

					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => handleNext()}
					>
						Skapa
					</button>
				</>
			)}
			{step === 1 && (
				<>
					<p className="mt-6 text-sm font-semibold">Lyckades</p>
					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => handleDownload()}
					>
						Ladda ner excel
					</button>
					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => writeToClipboard(prettyReport)}
					>
						Kopiera till pages
					</button>
				</>
			)}
		</>
	);
}
