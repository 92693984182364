export default function InputSelect({
	value,
	onChange,
	placeholder,
	items,
	required = false,
	name = "",
}: {
	value: string;
	onChange: any;
	placeholder: string;
	items: [string, string, boolean?][];
	required?: boolean;
	name?: string;
}) {
	return (
		<div className="relative select-none">
			<span className="font-medium text-slate-400 text-xs ml-1 px-1 absolute bg-white -top-2 dark:bg-black dark:text-stone-200">
				{placeholder}
			</span>
			<select
				className="bg-white border border-slate-200 py-2 px-2 appearance-none cursor-pointer w-full dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
				onChange={(e) => onChange(e.target.value, name)}
				value={value}
				required={required}
				name={name}
			>
				<option disabled value="">
					Välj {placeholder.toLowerCase()}
				</option>
				{items.map(([key, value, disabled]) => (
					<option className="font-medium" key={key} value={key} disabled={disabled}>
						{value}
					</option>
				))}
			</select>
		</div>
	);
}
