import { useState } from "react";
import InputFile from "../components/inputFile";
import TRIP_TRAFFIC_AREA_OWBER from "../data/lines/lines";

export default function TrafficAreaReport() {
	const [step, setStep] = useState<number>(0);
	const [cpString, setCpString] = useState<string>("");
	const [earlyStartTripsCPString, setEarlyStartTripsCPString] = useState<string>("");
	const [endLaateTripsCPString, setEndLaateTripsCPString] = useState<string>("");
	const [endEarlyTripsCPString, setEndEarlyTripsCPString] = useState<string>("");

	const handleSQLFile = (file: any) => {
		if (!file) {
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const sqlTrips = JSON.parse(evt.target.result);

				const SQL_TRIP_MAP: any = {};

				for (let i = 0; i < sqlTrips.length; i++) {
					const item = sqlTrips[i];
					const key =
						item.OperatingCalendarDay.split("T")[0] +
						item.LineNameLong +
						item.JourneyName;

					// Removed trips that arent real.
					if (
						!(
							parseInt(item.LineNameLong) > 178 &&
							item.LineNameLong !== "TOM" &&
							item.LineNameLong !== "TOM2" &&
							item.JourneyName.length === 5 &&
							(item.JourneyName[0] === "1" ||
								item.JourneyName[0] === "2" ||
								item.JourneyName[0] === "3" ||
								item.JourneyName[0] === "4")
						)
					) {
						continue;
					}

					// Check if key exists.
					if (SQL_TRIP_MAP[key]) {
						SQL_TRIP_MAP[key].push(item);
					} else {
						SQL_TRIP_MAP[key] = [item];
					}
				}

				const trips: any[] = Object.values(SQL_TRIP_MAP);

				const prettyTrips = [];

				let thorwnAway = 0;

				const tripsPerLine: any = {};

				for (let i = 0; i < trips.length; i++) {
					const trip = trips[i];
					let startDiff = 0;
					let endDiff = 0;

					if (trip[0].ActualDepartureTime !== null) {
						startDiff = Math.round(
							(new Date(trip[0].ActualDepartureTime).getTime() -
								new Date(trip[0].PlannedDepartureTime).getTime()) /
								1000
						);

						if (startDiff < -3600 || startDiff > 3600) {
							startDiff = 0;
						}
					} else {
						thorwnAway++;
					}

					if (trip[1].ActualArrivalTime !== null) {
						thorwnAway++;
						endDiff = Math.round(
							(new Date(trip[1].ActualArrivalTime).getTime() -
								new Date(trip[1].PlannedArrivalTime).getTime()) /
								1000
						);

						if (endDiff < -3600 || endDiff > 3600) {
							endDiff = 0;
						}
					} else {
						thorwnAway++;
					}

					prettyTrips.push({
						day: trip[0].OperatingCalendarDay.split("T")[0],
						line: trip[0].LineNameLong,
						trip: trip[0].JourneyName,
						trafficArea: TRIP_TRAFFIC_AREA_OWBER[trip[0].LineNameLong],
						plannedStart: trip[0].PlannedDepartureTime,
						plannedEnd: trip[1].PlannedArrivalTime,
						actualStart: trip[0].ActualDepartureTime,
						actualEnd: trip[1].ActualArrivalTime,
						startDiff,
						endDiff,
					});

					if (tripsPerLine[trip[0].LineNameLong]) {
						tripsPerLine[trip[0].LineNameLong]++;
					} else {
						tripsPerLine[trip[0].LineNameLong] = 1;
					}
				}

				// Calculate average start and end diff per traffic area.
				const trafficAreaMap: any = {};

				for (let i = 0; i < prettyTrips.length; i++) {
					const trip = prettyTrips[i];

					if (trafficAreaMap[trip.trafficArea]) {
						trafficAreaMap[trip.trafficArea].push(trip);
					} else {
						trafficAreaMap[trip.trafficArea] = [trip];
					}
				}

				const trafficAreas = Object.keys(trafficAreaMap);

				const trafficAreaAverages: any = {};

				const earlyStartTrips: any = [];
				const endLateStartTrips: any = [];
				const endEarlyTrips: any = [];

				for (let i = 0; i < trafficAreas.length; i++) {
					const trafficArea = trafficAreas[i];

					const trips = trafficAreaMap[trafficArea];

					// Caluclate trips that start 60 seconds before planned time.
					// Caluclate trips that start 60 seconds after planned time.
					// Caluclate trips that end 300 seconds before planned time.
					// Caluclate trips that end 60 seconds after planned time.
					let startEarly = 0;
					let startLate = 0;
					let endEarly = 0;
					let endLate = 0;
					let startSum = 0;
					let endSum = 0;
					let startDistruption = 0;
					let endDistruption = 0;
					let distruptedTrips = 0;
					let distruptedTripDirectionOne = 0;
					let distruptedTripDirectionTwo = 0;

					const mostDistruptedLine: any = {};
					const monstDIstrupedLineProcentage: any = {};

					for (let j = 0; j < trips.length; j++) {
						const trip = trips[j];
						let tripDistupted = false;

						startSum += trip.startDiff;
						endSum += trip.endDiff;

						const lineData = {
							startEarly: 0,
							startLate: 0,
							endEarly: 0,
							endLate: 0,
							distruptedTrips: 0,
							distruptDirOne: 0,
							distruptDirTwo: 0,
							earlyStartTrips: {},
						};

						if (trip.startDiff < -50) {
							startEarly++;
							startDistruption++;
							tripDistupted = true;
							lineData.startEarly++;
							earlyStartTrips.push(trip);
						} else if (trip.startDiff > 180) {
							startLate++;
							startDistruption++;
							tripDistupted = true;
							lineData.startLate++;
						}

						if (trip.endDiff < -300) {
							endEarly++;
							endDistruption++;
							tripDistupted = true;
							lineData.endEarly++;
							endEarlyTrips.push(trip);
						} else if (trip.endDiff > 60) {
							endLate++;
							endDistruption++;
							tripDistupted = true;
							lineData.endLate++;
							endLateStartTrips.push(trip);
						}

						if (tripDistupted) {
							distruptedTrips++;
							lineData.distruptedTrips++;

							if (trip.trip[0] === "1") {
								distruptedTripDirectionOne++;
								lineData.distruptDirOne++;
							} else {
								distruptedTripDirectionTwo++;
								lineData.distruptDirTwo++;
							}

							if (mostDistruptedLine[trip.line]) {
								mostDistruptedLine[trip.line] = {
									startEarly:
										mostDistruptedLine[trip.line].startEarly +
										lineData.startEarly,
									startLate:
										mostDistruptedLine[trip.line].startLate +
										lineData.startLate,
									endEarly:
										mostDistruptedLine[trip.line].endEarly + lineData.endEarly,
									endLate:
										mostDistruptedLine[trip.line].endLate + lineData.endLate,
									distruptedTrips:
										mostDistruptedLine[trip.line].distruptedTrips +
										lineData.distruptedTrips,
									distruptDirOne:
										mostDistruptedLine[trip.line].distruptDirOne +
										lineData.distruptDirOne,
									distruptDirTwo:
										mostDistruptedLine[trip.line].distruptDirTwo +
										lineData.distruptDirTwo,
								};
							} else {
								mostDistruptedLine[trip.line] = lineData;
							}
						}
					}

					const mostDistruptedLineKeys = Object.keys(mostDistruptedLine);

					for (let j = 0; j < mostDistruptedLineKeys.length; j++) {
						const key = mostDistruptedLineKeys[j];
						monstDIstrupedLineProcentage[key] = Math.round(
							(mostDistruptedLine[key].distruptedTrips / distruptedTrips) * 100
						);
					}

					trafficAreaAverages[trafficArea] = {
						startDiff: Math.round(startSum / trips.length),
						endDiff: Math.round(endSum / trips.length),
						startEarly: Math.round((startEarly / trips.length) * 100),
						startLate: Math.round((startLate / trips.length) * 100),
						endEarly: Math.round((endEarly / trips.length) * 100),
						endLate: Math.round((endLate / trips.length) * 100),
						puncuality: Math.round(
							((trips.length - distruptedTrips) / trips.length) * 100
						),
						puncualityStart: Math.round(
							((trips.length - startDistruption) / trips.length) * 100
						),
						puncualityEnd: Math.round(
							((trips.length - endDistruption) / trips.length) * 100
						),
						distruptedTrips,
						startDistruption,
						endDistruption,
						distruptedTripDirectionOne,
						distruptedTripDirectionTwo,
						directionOneProcentage: Math.round(
							(distruptedTripDirectionOne / distruptedTrips) * 100
						),
						directionTwoProcentage: Math.round(
							(distruptedTripDirectionTwo / distruptedTrips) * 100
						),
						mostDistruptedLine,
						monstDIstrupedLineProcentage,
						totalTrips: trips.length,
					};
				}

				let excelString = "";

				for (let i = 0; i < trafficAreas.length; i++) {
					const trafficArea = trafficAreas[i];
					const trafficAreaData = trafficAreaAverages[trafficArea];

					excelString += "Trafikområde:\t" + trafficArea + "\n";
					excelString += "Antal turer:\t " + trafficAreaData.totalTrips + "\n";
					excelString += "Start avvikelse:\t" + trafficAreaData.startDiff + "\n";
					excelString += "Slutt avvikelse:\t" + trafficAreaData.endDiff + "\n";
					excelString += "Starttid tidigt:\t" + trafficAreaData.startEarly + "\n";
					excelString += "Starttid sent:\t" + trafficAreaData.startLate + "\n";
					excelString += "Sluttid tidigt:\t" + trafficAreaData.endEarly + "\n";
					excelString += "Sluttid sent:\t" + trafficAreaData.endLate + "\n";
					excelString += "Total Punktlighet:\t" + trafficAreaData.puncuality + "%\n";
					excelString += "Punktlighet start:\t" + trafficAreaData.puncualityStart + "%\n";
					excelString += "Punktlighet slut:\t" + trafficAreaData.puncualityEnd + "%\n";
					excelString += "Antal turer:\t" + trafficAreaData.totalTrips + "\n";
					excelString +=
						"Turer med avvikelser:\t" + trafficAreaData.distruptedTrips + "\n";
					excelString +=
						"Avvikelse vid start:\t" + trafficAreaData.startDistruption + "\n";
					excelString += "Avvikelser vid slut:\t" + trafficAreaData.endDistruption + "\n";
					excelString +=
						"Avvikelse i riktning 1:\t" +
						trafficAreaData.distruptedTripDirectionOne +
						"\n";
					excelString +=
						"Avvikelse i riktning 2:\t" +
						trafficAreaData.distruptedTripDirectionTwo +
						"\n";
					excelString +=
						"Avvikelse i riktning 1:\t" +
						trafficAreaData.directionOneProcentage +
						"%\n";
					excelString +=
						"Avvikelse i riktning 2:\t" +
						trafficAreaData.directionTwoProcentage +
						"%\n";

					excelString +=
						"\nAvvikelser per linje:\tAvvikelser\tAvvikelser riktning 1\tAvvikelser riktning 2\tTidig start\tSen start\tTidig slut\tSent slut\n";

					let excelStringTripsDistrupted = "";

					const mdl = Object.keys(trafficAreaData.mostDistruptedLine);

					for (let i = 0; i < mdl.length; i++) {
						const item = trafficAreaData.mostDistruptedLine[mdl[i]];

						if (mdl[i] === "620") {
							console.log(item.distruptedTrips);
							console.log(
								`L${mdl[i]}:\t${item.distruptedTrips}\t${item.distruptDirOne}\t${item.distruptDirTwo}\t${item.startEarly}\t${item.startLate}\t${item.endEarly}\t${item.endLate}\n`
							);
						}

						excelStringTripsDistrupted += `L${mdl[i]}:\t${item.distruptedTrips}\t${item.distruptDirOne}\t${item.distruptDirTwo}\t${item.startEarly}\t${item.startLate}\t${item.endEarly}\t${item.endLate}\n`;
					}

					excelString += excelStringTripsDistrupted;

					excelString += "\n";
				}

				let earlyStartExceString =
					"Datum\tTrafikområde\tLinje\tTur\tPlanerad start\tVerklig start\tAvvikelse\n";

				for (let i = 0; i < earlyStartTrips.length; i++) {
					const trip = earlyStartTrips[i];
					earlyStartExceString += `${trip.day}\t${trip.trafficArea}\t${trip.line}\t${trip.trip}\t${trip.plannedStart}\t${trip.actualStart}\t${trip.startDiff}\n`;
				}

				let endEarlyExcelString =
					"Datum\tTrafikområde\tLinje\tTur\tPlanerad start\tVerklig start\tAvvikelse\n";

				for (let i = 0; i < endEarlyTrips.length; i++) {
					const trip = endEarlyTrips[i];
					endEarlyExcelString += `${trip.day}\t${trip.trafficArea}\t${trip.line}\t${trip.trip}\t${trip.plannedEnd}\t${trip.actualEnd}\t${trip.endDiff}\n`;
				}

				let endLateExceString =
					"Datum\tTrafikområde\tLinje\tTur\tPlanerad start\tVerklig start\tAvvikelse\n";

				for (let i = 0; i < endLateStartTrips.length; i++) {
					const trip = endLateStartTrips[i];
					endLateExceString += `${trip.day}\t${trip.trafficArea}\t${trip.line}\t${trip.trip}\t${trip.plannedEnd}\t${trip.actualEnd}\t${trip.endDiff}\n`;
				}

				setEarlyStartTripsCPString(earlyStartExceString);
				setEndEarlyTripsCPString(endEarlyExcelString);
				setEndLaateTripsCPString(endLateExceString);
				setCpString(excelString);
				setStep(1);
				console.log(trafficAreaAverages);
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			alert("Något gick fel");
		}
	};

	const writeToClipboard = async (s: string) => {
		try {
			await navigator.clipboard.writeText(s);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-20">Körtidsrapport Trafikområde</h1>
			<p className="mb-3 text-sm">
				Skapa rapport om körtiderna för de olika linjerna i ett trafikområde
			</p>

			{step === 0 && (
				<>
					<p className="mt-6 text-sm font-semibold">
						Turer i trafikområde
						<span className="ml-1 text-gray-500 font-normal text-[10px]">(EXTDB)</span>
					</p>

					<InputFile name="turdata" accept=".json" onHandleFile={handleSQLFile} />
				</>
			)}
			{step === 1 && (
				<>
					<p className="mt-6 text-sm font-semibold">Lyckades</p>
					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => writeToClipboard(cpString)}
					>
						Kopiera grunddata till excel
					</button>
					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => writeToClipboard(earlyStartTripsCPString)}
					>
						Kopiera tidiga avgångar till excel
					</button>
					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => writeToClipboard(endEarlyTripsCPString)}
					>
						Kopiera tidig ankomist till excel
					</button>
					<button
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300"
						onClick={() => writeToClipboard(endLaateTripsCPString)}
					>
						Kopiera sen ankomst till excel
					</button>
				</>
			)}
		</>
	);
}
