import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import { CustomMarker } from "../components/CustomMarker";

import { useState } from "react";

/* import data_trip_raw from "../data/retransmission/all-vehicle-data";
import data_hpl from "../data/retransmission/planned-data";

import tripTimestamps from "../data/retransmission/trip-timestamps";

import failed_Trips from "../data/retransmission/failed-trips"; */

const calculateTypeColor = (type: string) => {
	switch (type) {
		case "arrival":
			return "red";
		case "departure":
			return "green";
		case "passing":
			return "gray";
		default:
			return "black";
	}
};

const createTripKey = (trip: any) => {
	if (!trip) return "";

	return `${trip[0].OperatingCalendarDay.split("T")[0]}${trip[0].LineNameLong}/${
		trip[0].JourneyName
	}`;
};

export default function TripAnalyzer() {
	const [selectedTrip, setSelectedTrip] = useState(0);

	const [countCancelled, setCountCancelled] = useState(0);

	return <p>Wow</p>

	/* const trip = tripTimestamps[selectedTrip];

	const trip_key = `${createTripKey(trip)}`;

	const MapEvents: any = () => {
		useMapEvents({
			click(e) {
				// setState your coords here
				// coords exist in "e.latlng.lat" and "e.latlng.lng"
				console.log(`[${e.latlng.lat}, ${e.latlng.lng}]`);

				// Copy to clipboard.
				navigator.clipboard.writeText(`[${e.latlng.lat}, ${e.latlng.lng}]`);
			},
		});
		return null;
	};

	return (
		<div className="flex relative w-full flex-1">
			<div className="absolute bottom-0 w-full z-50 py-2 bg-white flex justify-between dark:bg-stone-950">
				<div className="flex flex-row">
					<h2 className="font-medium mr-2 text-lg dark:text-stone-200">
						Turer: {tripTimestamps.length}st
					</h2>
					<h2 className="font-medium mr-2 text-lg dark:text-stone-200">
						Avvikelser: {countCancelled}st
					</h2>
				</div>

				<div className="flex flex-row">
					<button className="mr-4" onClick={() => setCountCancelled(countCancelled + 1)}>
						Inställd
					</button>
					<select
						name=""
						id=""
						className="bg-white appearance-none border-gray-300 border px-2 py-1 border-solid dark:bg-stone-950 dark:border-stone-600 dark:text-stone-300"
						onChange={(e) => setSelectedTrip(parseInt(e.target.value))}
					>
						{tripTimestamps.map((trip: any, i: number) => (
							<option
								key={i}
								value={i}
								className=""
								onClick={() => setSelectedTrip(i)}
							>
								{new Date(trip[0].OperatingCalendarDay).toDateString()} L
								{trip ? trip[0]?.LineNameLong : ""}/
								{trip ? trip[0]?.JourneyName : null}{" "}
								{trip[trip.length - 1]?.StopPointName}{" "}
								{failed_Trips[createTripKey(trip)]?.length > 0
									? `(${failed_Trips[createTripKey(trip)].length})`
									: ""}
							</option>
						))}
					</select>
				</div>
			</div>
			{failed_Trips[trip_key] ? (
				<div className="absolute top-0 right-0 z-50 py-2 px-2 rounded bg-white flex justify-between flex-col dark:bg-stone-950">
					{failed_Trips[trip_key].map((trip: any, i: number) => (
						<div key={i} className="flex flex-row">
							<h2 className="font-medium mr-2 text-lg">{trip}</h2>
						</div>
					))}
				</div>
			) : null}
			<div className="w-full flex flex-1">
				<MapContainer center={[trip[0].Latitude, trip[0].Longitude]} zoom={11}>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					<MapEvents />
					{data_trip_raw[selectedTrip].map((marker: any, i: number) => {
						if (!marker.Latitude || !marker.Longitude) {
							return null;
						}

						const step = Math.ceil(360 / data_trip_raw[selectedTrip].length);

						return (
							<CustomMarker
								key={`${marker.Latitude}-${i}-${marker.Longitude}`}
								position={[marker.Latitude, marker.Longitude]}
								eventHandlers={{
									click: () => {
										console.log(JSON.stringify(marker, null, 4));
									},
								}}
							>
								<div
									className="bg-red-500 rounded hover:bg-reed-200 z-40 group"
									style={{
										width: "6px",
										height: "12px",
										transform: "rotate(" + marker.Heading + "deg)",
										border: "1px solid black",
										backgroundColor: `hsl(${(i * step) % 360}, 70%, 50%)`,
									}}
								>
									<p
										className="bg-white rounded w-40 mt-5 font-bold hidden group-hover:block dark:bg-stone-950 dark:text-stone-300"
										style={{
											transform: "rotate(-" + marker.Heading + "deg)",
										}}
									>
										{marker.Date}
									</p>
								</div>
							</CustomMarker>
						);
					})}

					{tripTimestamps[selectedTrip].map((marker: any, i: number) => {
						if (!marker.Latitude || !marker.Longitude) {
							console.log(marker);
							return null;
						}

						return (
							<CustomMarker
								key={`${marker.Latitude}-${i}-${marker.Longitude}`}
								position={[marker.Latitude, marker.Longitude]}
							>
								<div
									className="border-black border-[1px] border-solid rounded-xl z-50 group"
									style={{
										width: "10px",
										height: "28px",
										transform: "rotate(" + marker.Heading + "deg)",
										backgroundColor: calculateTypeColor(marker.type),
									}}
								>
									<p
										className="bg-white rounded w-40 mt-5 font-bold hidden group-hover:block dark:bg-stone-950 dark:text-stone-300"
										style={{
											transform: "rotate(-" + marker.Heading + "deg)",
										}}
									>
										{marker.Date}
									</p>
								</div>
							</CustomMarker>
						);
					})}

					{data_hpl[selectedTrip].map((marker: any, i: number) => (
						<CustomMarker
							key={`${marker.StopPointLatitude}-${i}-${marker.StopPointLongitude}`}
							position={[marker.StopPointLatitude, marker.StopPointLongitude]}
						>
							<div className="bg-blue-700 border-blue-800 border border-solid opacity-60 w-7 h-7 rounded-full -z-10 group">
								<p
									className="bg-white rounded w-40 mt-5 font-bold hidden group-hover:block dark:bg-stone-950 dark:text-stone-300"
									style={{
										transform: "rotate(-" + marker.Heading + "deg)",
									}}
								>
									{marker.StopPointName}{" "}
									{marker.PlannedArrivalTime
										? marker.PlannedArrivalTime
										: marker.PlannedDepartureTime}
								</p>
							</div>
						</CustomMarker>
					))}
				</MapContainer>
			</div>
		</div>
	); */
}
