import { useState } from "react";
import Input from "../components/input";
import InputSelect from "../components/inputSelect";

const default_tasks = [
	{
		date: 1713317790465,
		vehicle: "5169",
		type: "coolantLeakage",
		desc: "Glykol läcker från kylaren",
		newVehicle: null,
	},
	{
		date: 1713317790465,
		vehicle: 5189,
		type: "windshieldBroken",
		desc: "",
		newVehicle: 5190,
	},
];

const default_history = [
	{
		date: 1713317790465,
		vehicle: "5169",
		type: "Glykoläckage",
		desc: "Glykol läcker från kylaren",
	},
	{
		date: 1713317790465,
		vehicle: "5500",
		type: "Bränslebrist",
		desc: "",
	},
	{
		date: 1713317790465,
		vehicle: "5189",
		type: "Däckexplosion",
		desc: "",
	},
];

const types: any = {
	coolantLeakage: {
		title: "Glykolläckage",
		color: "#f54242",
	},
	fuelShortage: {
		title: "Bränslebrist",
		color: "#f5cb42",
	},
	windshieldBroken: {
		title: "Sprucken vindruta",
		color: "#42f57b",
	},
};

export default function TVA() {
	const [tasks, setTasks] = useState(default_tasks);

	const [vehicle, setVehicle] = useState("");
	const [type, setTyoe] = useState("");
	const [desc, setDesc] = useState("");

	const addTask = (e: any) => {
		e.preventDefault();

		// CHeck if valid vehicle.
		if (vehicle.length !== 4 || isNaN(vehicle as any)) {
			return;
		}

		console.log(type)

		// CHeck if valid type.
		if (type === "") {
			return;
		}

		console.log("RAN")

		const newTasks = [
			...tasks,
			{
				date: new Date().getTime(),
				vehicle,
				type,
				desc,
				newVehicle: null,
			},
		];

		setTasks(newTasks);
	};

	return (
		<div className="flex flex-1 flex-col">
			<h1 className="font-bold text-2xl mt-20">TVA</h1>
			<div className="flex flex-col mt-10 max-w-[1200px] justify-between">
				<form className="flex flex-row mb-5" onSubmit={addTask}>
					<div className="flex flex-col mr-2">
						<Input placeholder="Vagn" value={vehicle} onChange={setVehicle} />
					</div>
					<div className="flex flex-col mr-2">
						<InputSelect
							placeholder="Kateogori"
							value={type}
							onChange={(value: any) => setTyoe(value)}
							items={Object.entries(types).map(([key, val]: any) => [key, val.title])}
						/>
					</div>
					<div className="flex flex-col mr-2">
						<Input placeholder="Beskrivning" value={desc} onChange={setDesc} />
					</div>
					<input
						type="submit"
						className="bg-stone-200 text-black font-semibold rounded px-4 py-1 dark:bg-stone-900 dark:text-stone-100"
						value="Lägg till"
					/>
				</form>
				<div className="flex flex-row mb-5">
					<div className="flex flex-col mr-10">
						<p className="text-sm font-medium">To-do</p>
						<div className="flex flex-row mt-1">
							{tasks.map((item, index) => (
								<div
									key={index}
									className="flex flex-col relative bg-stone-200 min-w-52 rounded px-4 py-2 mb-1 mr-1 dark:bg-stone-900 dark:text-stone-30"
									style={{ backgroundColor: types[item.type].color }}
								>
									<p className="text-xl font-semibold mr-1">{item.vehicle}</p>
									<p className="text-sm absolute top-2 right-2 font-medium mr-1">
										{new Date(item.date)
											.toTimeString()
											.split(" ")[0]
											.substring(0, 5)}
									</p>
									<div>
										<p className="text-sm mt-1 font-medium">
											{types[item.type].title}
										</p>
										<p className="text-sm mt-1">{item.desc}</p>
										{item?.newVehicle ? (
											<p className="text-sm font-semibold">
												Ny vagn: {item?.newVehicle}
											</p>
										) : null}
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="flex flex-col ml-auto">
						<p className="text-sm font-medium mb-1">Info</p>
						<textarea
							className="bg-stone-100 rounded min-h-[60vh] px-2 py-2 dark:bg-stone-900 dark:text-stone-100"
							value={"DAS res: 5000, 5168"}
						/>
					</div>
				</div>
				<div className="flex flex-col">
					<p className="text-sm font-medium">Historik</p>
					<div className="flex flex-row mt-1">
						{default_history.map((item, index) => (
							<div
								key={index}
								className="flex flex-row bg-stone-100 rounded px-1 py-0.5 mb-1 mr-1 dark:bg-stone-900 dark:text-stone-300"
							>
								<p className="text-xs font-medium mr-1">{item.vehicle}</p>
								<p className="text-xs">{item.type}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
