import { Link, useParams } from "react-router-dom";
import { TESTS_CATEGORIES } from "../data/education/items";
import { useState } from "react";

export default function EduTest() {
	const { testId } = useParams<{ testId: string }>();

	const [step, setStep] = useState(0);

	const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

	const handleNext = (question: any, answer: number) => {
		if (isCorrect !== null) {
			return;
		}

		if (question.answers[answer].correct) {
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
	};

	if (!testId) {
		return (
			<>
				<h1 className="mt-8 mb-6 text-2xl font-bold">Utbildning</h1>
				<p className="">Något gick fel. Gick inte att hämta test.</p>
			</>
		);
	}

	const test = TESTS_CATEGORIES.find((test) => test.id === testId);

	if (!test) {
		return (
			<>
				<h1 className="mt-8 mb-6 text-2xl font-bold">Utbildning</h1>
				<p className="">Något gick fel. Gick inte att hämta test.</p>
			</>
		);
	}

	return <p>WIO</p>

	/* if (test.questions.length === 0) {
		return (
			<div className="flex flex-1 mt-8 w-full flex-col">
				<p className="font-semibold">Utbildning</p>
				<h1 className="text-2xl font-bold">{test.name}</h1>
				<p className="mt-8">Inga frågor hittades</p>
				<Link
					to="/education"
					className="px-3 py-1 mt-6 bg-slate-200 self-start rounded font-medium"
				>
					Tillbaka till utbildning
				</Link>
			</div>
		);
	}

	const question = test.questions[step];

	console.log(isCorrect);

	return (
		<div className="flex flex-1 mt-8 w-full flex-col">
			<p className="font-semibold">Utbildning</p>
			<h1 className="text-2xl font-bold">{test.name}</h1>
			<TestView
				question={question}
				step={step}
				isCorrect={isCorrect}
				handleNext={handleNext}
			/>
		</div>
	); */
}

const TestView = ({
	question,
	step,
	isCorrect,
	handleNext,
}: {
	question: { answers: { answer: string; correct: boolean }[]; question: string };
	step: number;
	isCorrect: boolean | null;
	handleNext: (question: any, answer: number) => void;
}) => {
	return (
		<div className="flex flex-1 mt-40 items-center w-full flex-col">
			<p className="font-semibold">Fråga {step + 1}</p>
			<h1 className="text-2xl font-bold">{question.question}</h1>

			<div className="flex flex-col w-full max-w-6xl">
				{question.answers.map((answer: any, i: number) => (
					<div
						key={answer.id}
						onClick={() => handleNext(question, i)}
						className={
							"flex flex-1 cursor-pointer flex-col mt-4 px-8 rounded py-6 w-full " +
							getButtonColor(isCorrect, answer.correct)
						}
					>
						<p className="font-semibold">{answer.answer}</p>
					</div>
				))}
			</div>

			{isCorrect !== null ? (
				<button
					onClick={() => {}}
					className="px-10 py-2 mt-6 bg-slate-200 dark:bg-stone-800 rounded font-semibold self-center"
				>
					Nästa
				</button>
			) : null}
		</div>
	);
};

const getButtonColor = (isCorrect: boolean | null, correct: boolean) => {
	if (isCorrect === null || !correct) {
		return "bg-slate-100 dark:bg-stone-950 dark:border-stone-900 border border-slate-200";
	}

	if (isCorrect) {
		return "bg-green-200 dark:bg-green-900 dark:border-green-900 border border-green-200 dark:text-green-200 text-green-800";
	}

	return "bg-red-200 dark:bg-red-900 dark:border-red-900 border border-red-200 dark:text-red-200 text-red-800";
};
