export default function DepotSelector({
	value,
	onChange,
	placeholder,
	items,
}: {
	value: string;
	onChange: any;
	placeholder: string;
	items: [string, string][];
}) {
	return (
		<div className="relative select-none">
			<span className="font-medium text-slate-400 text-xs ml-5 px-1 absolute bg-white -top-2 dark:bg-black dark:text-stone-200">
				{placeholder}
			</span>
			<select
				className="bg-white border border-slate-200 py-1 px-6 appearance-none cursor-pointer font-semibold dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
				onChange={(e) => onChange(e.target.value)}
				value={value}
			>
				<option disabled value="">
					Välj {placeholder.toLowerCase()}
				</option>
				{items.map(([key, value]) => {
					const v = value[0] === "0" ? value.slice(1) : value;

					return (
						<option disabled={v !== value} key={key} value={key}>
							{v}
						</option>
					);
				})}
			</select>
		</div>
	);
}
