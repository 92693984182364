import { EducationTest } from "../../types";

export const BIG_TESTS_CATEGORIES = [
	{
		name: "Trafikledare",
		desc: "Allt du ska kunna som trafikledare",
		id: "tf",
		questions: [
			{
				name: "Planering & övervakning",
				desc: "Planering och övervakning av trafiken",
				id: "pog",
				chapters: []
			},
		],
	},
];

export const TESTS_CATEGORIES: EducationTest[] = [
	{
		name: "Planering & övervakning",
		desc: "Planering och övervakning av trafiken",
		id: "pog",
		chapters: [
		],
	},
	{
		name: "Problemlösning",
		desc: "Lösning av diverse problem",
		id: "plo",
		chapters: [],
	},
	{
		name: "Trafikområdeskännedom",
		desc: "Kännedom om våra trafikområden",
		id: "tod",
		chapters: [],
	},
	{
		name: "Allvarliga händelser",
		desc: "Hantera allvarliga händelser",
		id: "ah",
		chapters: [],
	},
	{
		name: "Fordonsteknik",
		desc: "Kunskap om våra fordon",
		id: "ftk",
		chapters: [],
	},
	{
		name: "Avtalskunskap",
		desc: "Avtal och regler som berör oss",
		id: "akp",
		chapters: [],
	},
	{
		name: "Service och bemötande",
		desc: "Hur vi bemöter våra kunder",
		id: "sbm",
		chapters: [],
	},
	{
		name: "Ledarskap",
		desc: "Ledarskap",
		id: "led",
		chapters: [],
	},
	{
		name: "Skadehantering",
		desc: "Skadehantering",
		id: "skad",
		chapters: [],
	},
	{
		name: "Rutiner",
		desc: "Rutiner",
		id: "rut",
		chapters: [],
	},
	{
		name: "Lagar & regler",
		desc: "Kör- och arbetsmiljölagar",
		id: "lagr",
		chapters: [],
	},
	{
		name: "Datakunskap",
		desc: "Hur vi använder våra system",
		id: "dat",
		chapters: [],
	},
	{
		name: "Omläggningar",
		desc: "Hur vi hanterar omläggningar",
		id: "oml",
		chapters: [],
	},
	{
		name: "Yttre trafikledare",
		desc: "Arbete som yttre trafikledare",
		id: "ake",
		chapters: [],
	},
	{
		name: "Ekonomi",
		desc: "Ekonomi",
		id: "eko",
		chapters: [],
	},
	{
		name: "Transdev",
		desc: "Om företage",
		id: "tra",
		chapters: [],
	},
];
