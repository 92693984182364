import { zeroed } from "../helper/date";
import { DivionsType, ResultsStats, TripsStats } from "../types";

const StatBox = ({ title, part, whole }: { title: string; part: number; whole: number }) => {
	return (
		<span className="rounded bg-blue-100 px-2 py-1 text-xs font-semibold dark:bg-blue-950 dark:text-blue-400 flex justify-center items-center">
			<p className="font-normal mr-1">{title}:</p> {Number(part).toLocaleString("sv")}
			<p className="text-xs text-stone-700 dark:text-blue-400 dark:opacity-70 ml-1">
				({((part / whole) * 100).toFixed(2)}
				%)
			</p>
		</span>
	);
};

const available_divisions = ["E31", "E35", "E38"];

const DataFollowupStats = ({
	tripsStats,
	resultsStats,
	divisions,
}: {
	tripsStats: TripsStats;
	resultsStats: ResultsStats;
	divisions: DivionsType;
}) => {
	return (
		<div>
			<div className="flex flex-col mt-2 mb-6">
				<div className="flex w-full flex-row flex-wrap gap-2">
					<div className="flex w-full flex-row flex-wrap gap-1.5 mb-3">
						{available_divisions.map((division) => {
							if (divisions[division]) {
								let divisionTot = 0;

								let earliestDate = 99999999;
								let latestDate = 0;

								const values = Object.entries(divisions[division]);

								for (let i = 0; i < values.length; i++) {
									const [date, amount] = values[i];

									divisionTot += amount;

									if (earliestDate > parseInt(date)) {
										earliestDate = parseInt(date);
									}

									if (latestDate < parseInt(date)) {
										latestDate = parseInt(date);
									}
								}

								return (
									<span
										key={division}
										className="rounded bg-blue-100 px-2 py-1 text-xs font-semibold  dark:bg-blue-950 dark:text-blue-400 flex justify-center items-center"
									>
										<p className="font-normal mr-1">{division}:</p>{" "}
										<div className="flex flex-row">
											{zeroed(Math.floor((earliestDate % 10000) / 100))}/
											{zeroed(earliestDate % 100)}
											{"-"}
											{zeroed(Math.floor((latestDate % 10000) / 100))}/
											{zeroed(latestDate % 100)}{" "}
											<p className="font-normal ml-1">{divisionTot}st</p>
										</div>
									</span>
								);
							}

							return null;
						})}
					</div>
					<div className="flex w-full flex-row flex-wrap gap-2 mb-3">
						<span className="rounded bg-blue-100 px-2 py-1 text-xs font-semibold dark:bg-blue-950 dark:text-blue-400 flex justify-center items-center">
							<p className="font-normal mr-1">Totalt antal:</p>{" "}
							{Number(tripsStats.totalTrips).toLocaleString("sv")}
						</span>
						<StatBox
							title="Delvis utförda"
							part={tripsStats.tripsPartiallyCompleted}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Ej utförda"
							part={tripsStats.tripsNotCompleted}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Undantag"
							part={tripsStats.tripsWithException}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Inställda turer"
							part={tripsStats.tripsCancelled}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Omläggningar"
							part={tripsStats.tripsTrafficDiversion}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Förstärkningsturer"
							part={tripsStats.tripsReinforment}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Turer som saknas i Hastus"
							part={tripsStats.tripsMissingHastus}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Fordon som saknas i Hastus"
							part={tripsStats.vehicleMissingHastus}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Turer som inte kunde hittas i Hastus"
							part={tripsStats.vehicleMissingHastus}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Turer som saknas i CTS"
							part={tripsStats.tripMissingCTS}
							whole={tripsStats.totalTrips}
						/>
						<StatBox
							title="Rapporterat innan eftersändning"
							part={
								tripsStats.tripsCompleted +
								tripsStats.tripsWithException +
								tripsStats.tripsCancelled +
								tripsStats.tripsTrafficDiversion +
								tripsStats.tripsReinforment
							}
							whole={tripsStats.totalTrips}
						/>
					</div>
					{resultsStats.completeFromCTS > 0 ? (
						<StatBox
							title="Turer fullständiga från CTS"
							part={resultsStats.completeFromCTS}
							whole={tripsStats.totalTrips}
						/>
					) : null}
					{resultsStats.createdCompleteTrips > 0 ? (
						<StatBox
							title="Turer genererade fullständiga"
							part={resultsStats.createdCompleteTrips}
							whole={tripsStats.totalTrips}
						/>
					) : null}
					<StatBox
						title="Helt utförda turer"
						part={
							tripsStats.tripsCompleted +
							tripsStats.tripsWithException +
							tripsStats.tripsCancelled +
							resultsStats.completeFromCTS +
							tripsStats.tripsTrafficDiversion +
							resultsStats.createdCompleteTrips +
							tripsStats.tripsReinforment
						}
						whole={tripsStats.totalTrips}
					/>
				</div>
			</div>
		</div>
	);
};

export default DataFollowupStats;
