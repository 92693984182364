export default function InputFileMulti({
	onHandleFile,
	accept,
	name,
	loading,
}: {
	onHandleFile: any;
	accept: string;
	name: string;
	loading?: boolean;
}) {
	const changeHandler = (event: any) => {
		if (!event) {
			return;
		}
		
		onHandleFile(event.target.files);
	};

	return (
		<form className="relative h-28 w-full" id={"form-" + name}>
			{loading ? (
				<div className="absolute w-full h-28 bg-slate-100 flex flex-col justify-center items-center z-50 dark:bg-stone-800">
					<svg
						className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
						></path>
					</svg>

					<p className="text-sm font-semibold">Laddar upp fil...</p>
				</div>
			) : null}

			<p className="absolute w-full text-center mt-12 leading-6 font-medium text-xs pointer-events-none dark:text-stone-300">
				Klicka eller släpp fil för att ladda upp {accept}
			</p>
			<input
				type="file"
				name={name}
				accept={accept}
				onChange={changeHandler}
				multiple={true}
				className="hover:bg-gray-100 rounded text-black h-28 w-full cursor-pointer bg-slate-100 transition-all ease dark:bg-stone-950 dark:text-stone-200 dark:hover:bg-stone-950 dark:hover:opacity-90"
			/>
		</form>
	);
}
