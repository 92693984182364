import { Link, useRouteError } from "react-router-dom";
import Navbar from "../components/navbar";

export default function ErrorPage() {
	const error: any = useRouteError();
	console.error(error);

	return (
		<div className="flex flex-1 flex-row h-screen w-full">
			<div className="py-4 max-w-7xl m-auto flex flex-1 h-screen flex-col box-border px-4 mt-20">
				<h1>Oops!</h1>
				<p>Ursäkta, ett oväntat fel inträffade.</p>
				<Link to="/start" className="text-blue-600 font-medium underline">
					{" "}
					Gå till första sidan
				</Link>
				<br />
				<br />
				<p>
					Kod: <i>{error.statusText || error.message}</i>
				</p>
			</div>
		</div>
	);
}
