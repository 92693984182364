import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { DataFollowUpViews } from "../types";
import { createWBFromRows, createXLSXFile } from "../helper/xlsx";

export const parseTripsCSVWithKeys = (bstr: any) => {
	const trips = bstr.split("\n");
	const DATA: any = {};

	for (let i = 0; i < trips.length; i++) {
		const row = trips[i].split(";");

		const date = row[0].replaceAll("/", "");

		if (row[2] === undefined) {
			console.log(row);
			continue
		}

		let line = row[2].trim();

		// If empty is not line.
		if (line === "") {
			continue;
		}

		// Check if is valid line.
		if (line.length !== 3 && line.length !== 4) {
			continue;
		}

		let appendix = "";

		// Remove last letter on line.
		if (line.length === 4) {
			appendix = line.charAt(3);
			line = line.slice(0, 3);
		}

		// Check if is not a number.
		if (isNaN(line)) {
			continue;
		}

		const trip = row[4].trim();

		const keyTrip = `${date}-${line + appendix}-${trip}`;



		const newTrip: any = {
			date: date,
			line: `${line + appendix}`,
			trip: trip,
			distance: row[3].trim(),
			duration: row[6].trim(),
			start: row[7].trim(),
			end: row[8].trim(),
			comment1: row[9].trim(),
			comment2: row[10].trim(),
		};

		if (appendix) {
			newTrip.appendix = appendix;
		}

		DATA[keyTrip] = newTrip;
	}

	return DATA;
};

export default function SpecialTrips() {
	const [view, setView] = useState<DataFollowUpViews>("start");
	const [specielTripsE31, setSpecialTripsE31] = useState<any>([]);
	const [specielTripsE35, setSpecialTripsE35] = useState<any>([]);
	const [specielTripsE38, setSpecialTripsE38] = useState<any>([]);

	const handleHastusData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (evt: any) => {
			// Parse data
			const bstr = evt.target.result;

			const tripsKeys = parseTripsCSVWithKeys(bstr);
			console.log(tripsKeys)
			const trips = Object.values(tripsKeys).filter(
				(trip: any) => trip.trip.startsWith("7") || trip.trip.startsWith("8")
			);

			const tripsE31 = trips.filter((trip: any) => trip.line === "574");
			const tripsE35 = trips.filter((trip: any) => trip.line === "626");
			const tripsE38 = trips.filter(
				(trip: any) => trip.line !== "626" && trip.line !== "574"
			);

			console.log(tripsE31);
			console.log(tripsE35);
			console.log(tripsE38);

			setSpecialTripsE31(tripsE31);
			setSpecialTripsE35(tripsE35); 
			setSpecialTripsE38(tripsE38);
		};
		reader.readAsBinaryString(file);
	};

	const downloadArea = (area: string) => {
		let trips: any[] = [];

		if (area === "E31") {
			trips = specielTripsE31;
		} else if (area === "E35") {
			trips = specielTripsE35;
		} else if (area === "E38") {
			trips = specielTripsE38;
		}

		const rows = trips.map((trip: any) => [
			trip.date,
			trip.line,
			trip.trip,
			trip.distance,
			trip.duration,
			trip.start,
			trip.end,
			trip.comment1,
			trip.comment
		]);

		const wb = createWBFromRows(rows, [
			"Datum",
			"Linje",
			"Tur",
			"Planerad KM",
			"Planerad tid",
			"Start",
			"Slut",
			"Kommentar 1",
			"Kommentar 2"
		]);
	
		createXLSXFile(wb, area + "-special-trips.xlsx");
	}

	return (
		<>
			<h1 className="mt-12 text-2xl font-bold">Specialtrips</h1>
			<p className="mb-3 text-sm"></p>

			<View show={view === "start"}>
				<p className="mt-4 text-sm font-semibold">Trip rapport</p>

				<InputFile name="trips" accept=".exp" onHandleFile={handleHastusData} />

				<button
					className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => setView("query-all-records")}
				>
					Nästa
				</button>
				{specielTripsE31.length > 0 ? (
					<button
						className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
						onClick={() => downloadArea("E31")}
					>
						Ladda ner E31
					</button>
				) : null}

				{specielTripsE35.length > 0 ? (
					<button
						className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
						onClick={() => downloadArea("E35")}
					>
						Ladda ner E35
					</button>
				) : null}

				{specielTripsE38.length > 0 ? (
					<button
						className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
						onClick={() => downloadArea("E38")}
					>
						Ladda ner E38
					</button>
				) : null}
			</View>
		</>
	);
}
