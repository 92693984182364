export default function Switch({
	items,
	value,
	onChange,
}: {
	items: string[];
	value: string;
	onChange: (val: string) => void;
}) {
	return (
		<div className="flex self-end justify-center gap-1 px-1 items-center bg-stone-100 dark:bg-stone-900 rounded">
			{items.map((item) => (
				<div
					key={"switch-" + item}
					onClick={() => onChange(item)}
					className={
						"flex py-1 px-3 my-1 rounded text-sm hover:text-gray-600 cursor-pointer" +
						(item === value ? " bg-blue-100 font-medium dark:bg-stone-700 dark:text-blue-400" : " bg-white dark:bg-stone-800")
					}
				>
					{item}
				</div>
			))}
		</div>
	);
}
