const ReroutedTrips: any = {
	532: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
			51047: true, // Solvägen runsa
		},
	},
	533: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
		},
	},
	534: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
		},
	},
	537: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
		},
	},
	536: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
			50667: true, // Norra berget
		},
	},
	598: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
			50667: true, // Norra berget
		},
	},
	570: {
		start: "2024-02-13T16:00:00",
		end: "2024-02-13T20:25:00",
		stops: {
			52009: true, // Stora brännbo 1
			52012: true, // Stora brännbovägen
			52013: true, // Falkvägen
			52010: true, // Stora brännbo 2
		},
	},
	601: {
		start: "2024-02-29T09:00:00",
		end: "2024-04-30T23:59:00",
		stops: {
			60616: true, // Djursholm centrum
			60647: true, // Djursholm centrum
		},
	},
	602: {
		start: "2024-02-29T09:00:00",
		end: "2024-04-30T23:59:00",
		stops: {
			60616: true, // Djursholm centrum
			60647: true, // Djursholm centrum
			60617: true, // Svitiodvägen,
			60618: true, // Svitiodvägen.
			60619: true, // NOrrängsgården,
			60620: true, // NOrrängsgården.
			60239: true, // Östberga.
			60240: true, // Östberga.
			60631: true, // Lillkalmarsvägen.
			60632: true, // Lillkalmarsvägen.
		},
	},
	606: {
		start: "2024-02-29T09:00:00",
		end: "2024-04-30T23:59:00",
		stops: {
			60616: true, // Djursholm centrum
			60647: true, // Djursholm centrum
		},
	},
	684: {
		start: "2024-02-08T00:00:00",
		end: "2024-05-24T23:59:59",
		stops: {
			50665: true, // Väsby
			50667: true, // Norra berget
		},
	},
	579: {
		start: "2024-03-04T00:00:00",
		end: "2024-04-01T23:59:59",
		stops: {
			68133: true, // Kalmar livs
			68134: true, // Kalmar livs
			68131: true, // Draget
			68132: true, // Draget
		},
	},
	664: {
		start: "2024-05-01T00:00:00",
		end: "2024-05-01T23:59:59",
		stops: {
			66522: true, // Kragstalund station
			66526: true, // Musseronvägen
			66527: true,
			66523: true,
		},
	},
};

export default ReroutedTrips;
