import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { getWSJSON } from "../helper/xlsx";

import { parseTripsCSVWithKeys } from "../helper/csv";
import { zeroed } from "../helper/date";

import { CancelledData, DataFollowUpViews, HastusTrips } from "../types";

export default function DataMergeVehicle() {
	const [view, setView] = useState<DataFollowUpViews>("start");
	const [hastusTrips, setHastusTrips] = useState<HastusTrips | null>(null);

	const handleHastusData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (evt: any) => {
			// Parse data
			const bstr = evt.target.result;

			const trips = parseTripsCSVWithKeys(bstr);

			setHastusTrips({ ...trips });
		};
		reader.readAsBinaryString(file);
	};

	const handleCancelledTrips = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const rows: CancelledData[] = getWSJSON(bstr, 3);

			let string = "";

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i];

				const date = new Date(row["Trafikdatum"]);

				const key = `${date.getFullYear()}${zeroed(date.getMonth() + 1)}${zeroed(
					date.getDate(),
				)}-${row["Linje"]}-${row["Tur"]}`;

				let FordonsID = "";

				if (hastusTrips && hastusTrips[key]) {
					FordonsID = hastusTrips[key].vehicles.split(",")[0] ?? "";
				}

				string += `${FordonsID}\n`;
			}

			console.log(string);
		};

		reader.readAsBinaryString(file);
	};

	return (
		<>
			<h1 className="mt-12 text-2xl font-bold">Eftersändning</h1>
			<p className="mb-3 text-sm">
				Följ upp vilka turer som har genomförts och skapa turdata automatiskt
			</p>

			<View show={view === "start"}>
				<p className="mt-4 text-sm font-semibold">
					Turer från Hastus
					<span className="text-[10px] font-normal text-gray-500">
						{" "}
						(Trips.exp från Hastus)
					</span>
				</p>

				<InputFile name="trips" accept=".exp" onHandleFile={handleHastusData} />

				<div className="flex flex-row">
					{hastusTrips ? (
						<p className="text-böack mr-2 mt-2 rounded bg-green-200 px-2 py-1 text-sm dark:bg-green-950 dark:text-green-400">
							Hastus-data laddad
						</p>
					) : null}
				</div>

				<p className="mt-6 text-sm font-semibold">
					Inställda turer
					<span className="text-[10px] font-normal text-gray-500">
						{" "}
						(HEL/DEL turer från produktionsunderlag)
					</span>
				</p>

				<InputFile name="trips" accept=".xlsx" onHandleFile={handleCancelledTrips} />

				<button
					className="mx-auto mt-5 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => setView("query-all-records")}
				>
					Nästa
				</button>
			</View>
		</>
	);
}
