import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";

import { getWSJSON } from "../helper/xlsx";
import { zeroed } from "../helper/date";

export default function TripMerger() {
	const [view, setView] = useState("start");
	const [copy, setCopy] = useState("");

	const handleCancelledTrips = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = async (event: any) => {
				// Parse data
				const bstr = event.target.result;

				const rows = getWSJSON(bstr);

				const tripsMap: any = {};

				for (let i = 0; i < rows.length; i++) {
					const row = rows[i];

					const date = new Date(row["Datum"]);
					const dateString = `${date.getFullYear()}-${zeroed(
						date.getMonth() + 1
					)}-${zeroed(date.getDate())}`;

					if (!row["Linje"] || !row["Tur"]) {
						console.log("Missing line or trip", row);
					}

					const line = row["Linje"].toString();
					const trip = row["Tur"].toString();

					const key = `${dateString}-${line}-${trip}`;

					if (tripsMap[key]) {
						tripsMap[key] = {
							...tripsMap[key],
							distance: tripsMap[key].distance + parseFloat(row["Distans"]),
						};
					} else {
						tripsMap[key] = {
							date: dateString,
							line,
							trip,
							distance: parseFloat(row["Distans"]),
						};
					}
				}

				const tripsArray: any[] = Object.values(tripsMap);

				let xlsxString = "Datum\tLinje\tTur\tDistans\n";

				for (let i = 0; i < tripsArray.length; i++) {
					const trip = tripsArray[i];
					xlsxString += `${trip.date}\t${trip.line}\t${trip.trip}\t${trip.distance
						.toString()
						.replace(".", ",")}\n`;
				}

				setCopy(xlsxString);
				setView("complete");
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const copyToClipboard = () => {
		navigator.clipboard.writeText(copy);
	};

	return (
		<>
			<h1 className="mt-10 text-2xl font-bold">Trip merger</h1>
			<p className="mb-3 text-sm">
				Följ upp vilka turer som har genomförts och skapa turdata automatiskt
			</p>

			<View show={view === "start"}>
				<p className="mt-6 text-sm font-semibold">Turer att lägga ihop</p>

				<InputFile name="trips" accept=".xlsx" onHandleFile={handleCancelledTrips} />
			</View>

			<View show={view === "complete"}>
				<p>Raderna har skapats</p>

				<button
					className="mt-3 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={copyToClipboard}
				>
					Kopiera
				</button>
			</View>

			<View show={view === "error"}>
				<p className="text-sm font-semibold text-red-500">Något gick fel, försök igen</p>
			</View>
		</>
	);
}
