import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VASSDUPP_URL } from "../config";
import { safeUserStorage } from "../helper/storage";
import postman from "../helper/postman";

export default function Login() {
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [displayId, setDisplayId] = useState("");

	useEffect(() => {
		const user = JSON.parse(safeUserStorage() || "{}");

		if (user?.token) {
			navigate("/start");
		}
	}, []);

	const handleLogin = (e: any) => {
		e.preventDefault();

		if (username === "" || password === "") {
			alert("Du måste fylla i användarnamn och lösenord");
			return;
		}

		postman
			.post(VASSDUPP_URL + "auth", {
				username,
				password,
			})
			.then((res) => {
				localStorage.setItem("user", JSON.stringify(res.data));
				navigate("/start");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					alert("Fel användarnamn eller lösenord");
				} else {
					alert("Något gick fel");
				}
			});
	};

	const handleDisplayLogin = (e: any) => {
		e.preventDefault();

		if (displayId === "") {
			alert("Du måste fylla i skärm-ID");
			return;
		}

		// Check if valid UUID.
		if (
			!displayId.match(
				/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/
			)
		) {
			alert("Ogiltigt skärm-ID");
			return;
		}

		navigate("/display/" + displayId);
	};

	return (
		<div className="flex flex-row items-center justify-center w-full h-full fixed">
			<div className="flex flex-1 flex-col">
				<form
					className="flex flex-col items-center justify-center w-full"
					onSubmit={(e) => handleLogin(e)}
				>
					<div className="flex flex-1 flex-col justify-center items-center">
						<h1 className="font-bold text-4xl mt-28">VASSDUPP</h1>

						<div className="relative mt-6">
							<p className="absolute -top-2 text-[11px] left-5 px-1 bg-white dark:bg-black dark:text-stone-200">
								Användarnamn
							</p>
							<input
								className="bg-white border-2 border-gray-200 py-2 px-6 rounded select-none text-sm dark:bg-stone-900 dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
						</div>
						<div className="relative mt-4 select-none">
							<p className="absolute -top-2 text-[11px] left-5 px-1 bg-white dark:bg-black dark:text-stone-200">
								Lösenord
							</p>
							<input
								type="password"
								className="bg-white border-2 border-gray-200 py-2 px-6 rounded select-none text-sm dark:bg-stone-900 dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</div>
						<button className="mt-6 font-medium py-2 px-10 bg-gradient-to-r from-sky-500 to-blue-500 rounded mb-20 dark:from-sky-600 dark:to-blue-700 dark:text-black">
							Logga in
						</button>
					</div>
				</form>
				<form
					className="flex flex-col items-center justify-center w-full"
					onSubmit={handleDisplayLogin}
				>
					<div className="w-[300px] h-[1px] bg-slate-300 mx-auto relative">
						<div className="absolute -top-2 flex justify-center w-full">
							<p className="bg-white px-2 text-sm text-slate-600 dark:bg-black dark:text-stone-200">
								Eller
							</p>
						</div>
					</div>
					<div className="relative mt-20 select-none">
						<p className="absolute -top-2 text-[11px] left-2 px-1 bg-white dark:bg-black dark:text-stone-200">
							Skärm-ID
						</p>
						<input
							type="text"
							className="bg-white border-2 border-gray-200 py-2.5 px-3 select-none text-sm dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
							value={displayId}
							onChange={(e) => setDisplayId(e.target.value)}
							required
						/>
					</div>
					<button className="mt-4 text-sm font-medium py-1 px-4 bg-gradient-to-br from-slate-100 to-slate-200 rounded mb-10 dark:from-stone-900 dark:to-stone-900 dark:text-stone-100">
						Logga in på skärm
					</button>
				</form>
			</div>
			{/* <div className="flex flex-[1.6] bg-gradient-to-l from-sky-500 to-blue-500 h-full flex-wrap justify-between items-stretch">
				<div className="flex flex-col flex-1 justify-center py-20">
					{["Trafik", "Fordon", "Info", "Admin"].map((item) => (
						<h1
							key={"vassdupp-" + item}
							className="mx-4 font-bold text-8xl mt-4 text-blue-900 flex self-center"
						>
							{item}
						</h1>
					))}
				</div>
			</div> */}
		</div>
	);
}
