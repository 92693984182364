import { useEffect, useState } from "react";

import { fetchVehicleDepotParking } from "../helper/network";

import DepotSelector from "../components/depotSelector";
import { RAMP_PRETTIER, convertVehiclePerArea } from "../helper/vehicles";
import usePersistentState from "../helper/usePersistentState";
import PINCodeCalculator from "../helper/pincode";
import depot_pos from "../data/vehicles/depot_pos";
import busTypes from "../data/vehicles/bustypes";
import { MapContainer, TileLayer } from "react-leaflet";
import { CustomMarker } from "../components/CustomMarker";

export const DEPOT_FULL: any = {
	taga: "TÄGA",
	noga: "NOGA",
	maga: "MÄGA",
	gaga: "Gävle",
};

export const SELECT_ITEMS: any = {
	"norrort": "Norrort",
	"taga": "TÄGA",
	"-0": "0-",
	"norrtalje": "Norrtälje",
	"noga": "NOGA",
	"-1": "0-",
	"marsta": "Märsta",
	/* "maga": "MÄGA", */
	"gavleborg": "Gävleborg",
	/* "gaga": "Gävle", */
};

export const DEPOT_FULL_CONTAINER: any = {
	norrort: "taga",
	norrtalje: "noga",
	marsta: "maga",
	gavleborg: "gaga",
};

export default function BusParking() {
	const [depot, setDepot] = usePersistentState("selected_depot", "norrort");
	const [vehicles, setVehicles] = useState<any>([]);
	const [showHelp, setShowHelp] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const [latestUpdate, setLatestUpdate] = useState(new Date().getTime());
	const [currentTime, setCurrentTime] = useState(new Date().getTime());

	// set interval to update time every second
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const fetchVehicles = async () => {
		try {
			const res = (await fetchVehicleDepotParking(DEPOT_FULL_CONTAINER[depot] || depot)).data;

			setLatestUpdate(new Date().getTime());

			setVehicles((vehicles: any) => {
				/* if (vehicles.length !== 0) {
					// Find vehicles that have moved since last update.
					const movedVehicles = Object.keys(vehicles).filter(
						(vehicle) => vehicles[vehicle]?.ramp === res[vehicle]?.ramp
					);

					// Loop through moved vehicles and value to data.
					movedVehicles.map((vehicle) => {
						if (res[vehicle] !== vehicles[vehicle]) {
							res[vehicle].moved = true;
						}
					});
				} */

				return res;
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchVehicles();
		const interval = setInterval(fetchVehicles, 30000);

		return () => {
			clearInterval(interval);
		};
	}, [depot]);

	if (showMap) {
		const vehicleData = Object.entries(vehicles).map((vehicle: any) => {
			return {
				...vehicle[1],
				vehicle: vehicle[0],
				data: vehicle[1].history,
			};
		});

		return (
			<div className="w-full flex flex-1">
				<div className="flex gap-2 justify-center absolute right-2 top-2 mx-auto z-10 flex-col">
					{/* 	<select
						name=""
						id=""
						className="bg-white appearance-none border-gray-300 border px-2 py-1 border-solid font-medium dark:bg-stone-950 dark:border-stone-600 dark:text-stone-300"
						onChange={(e) => setSelectedTrip(parseInt(e.target.value))}
					>
						{vehicleData.map((trip: any, i: number) => (
							<option
								key={i}
								value={i}
								className=""
								onClick={() => setSelectedTrip(i)}
							>
								L{trip ? trip?.line : ""}/{trip ? trip?.trip : null}
								{" " + (trip.data.length === 0 ? "X" : "")}
							</option>
						))}
					</select>
					<div className="bg-white dark:bg-black py-1 px-2 rounded text-xs">
						<p>
							<span className="font-medium">Completeness:</span>{" "}
							{vehicleData[selectedTrip].completeness}%
						</p>
						<p>
							<span className="font-medium">Vagn:</span>{" "}
							{vehicleData[selectedTrip].vehicles}
						</p>
					</div> */}
				</div>

				<MapContainer
					center={[vehicleData[0].latitude, vehicleData[0].longitude]}
					zoom={13}
				>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					{vehicleData.map((marker: any, i: number) => {
						if (!marker.latitude || !marker.longitude) {

							return null;
						}

						return (
							<CustomMarker
								key={`${marker.latitude}-${i}-${marker.longitude}`}
								position={[marker.latitude, marker.longitude]}
								eventHandlers={{
									click: () => {
										console.log(JSON.stringify(marker, null, 4));
									},
								}}
							>
								<div
									className="rounded hover:bg-reed-200 z-40 group"
									style={{
										width: "6px",
										height: "6px",
										transform: "rotate(" + parseInt(marker.heading) + "deg)",
										border: "1.5px solid black",
										backgroundColor: `rgba(255, ${255 - i}, ${i})`,
									}}
								>
									<p
										className="bg-white rounded w-40 font-bold hidden group-hover:block dark:bg-stone-950 dark:text-stone-300"
										style={{
											transform: "rotate(-" + marker.heading + "deg)",
										}}
									>
										{marker.time}
									</p>
								</div>
							</CustomMarker>
						);
					})}
				</MapContainer>
			</div>
		);
	}

	return (
		<div className="flex flex-col flex-1 relative">
			<div className="flex flex-row justify-between align-top px-4 pt-2 relative">
				<div className="flex flex-row">
					<h1 className="font-bold text-2xl dark:text-stone-100">
						{RAMP_PRETTIER[SELECT_ITEMS[depot]] ?? SELECT_ITEMS[depot]}
					</h1>
					<span className="flex flex-row ml-2 items-center dark:text-stone-300">
						Tvättkod:{" "}
						<p className="font-semibold ml-1">
							{PINCodeCalculator.calculatePinCodeFromString(
								"666666" +
									new Date().toISOString().split("T")[0].replaceAll("-", "")
							)}
						</p>
					</span>
				</div>

				<div className="flex flex-row items-center">
					<p className="text-sm dark:text-stone-300 mr-2">
						Senast uppdaterad:{" "}
						{new Date(latestUpdate).toLocaleTimeString("sv-SE", {
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit",
						})}
					</p>
					<div className="w-24 h-2 dark:bg-stone-800 bg-stone-200 rounded-sm relative overflow-hidden">
						<div
							className="absolute h-full bg-blue-600"
							style={{
								width: `${(Math.abs(currentTime - latestUpdate) / 30000) * 100}%`,
							}}
						/>
					</div>
				</div>

				<div className="flex flex-row items-center">
					{"gavleborg" !== depot ? (
						<button
							className={
								"flex flex-row items-center px-3 rounded select-none font-semibold py-1 mr-1 " +
								(showHelp
									? "text-green-600 bg-green-100 dark:bg-green-800 dark:text-green-200"
									: "text-gray-300 bg-gray-100 dark:text-stone-300 dark:bg-stone-800")
							}
							onClick={() => setShowHelp(!showHelp)}
						>
							<p className="text-sm">Noob-mode{showHelp ? ": På" : ""}</p>
						</button>
					) : null}
					{/* <button
						className={
							"flex flex-row items-center px-3 rounded select-none font-semibold py-1 mr-1 " +
							(showHelp
								? "text-green-600 bg-green-100 dark:bg-green-800 dark:text-green-200"
								: "text-gray-300 bg-gray-100 dark:text-stone-300 dark:bg-stone-800")
						}
						onClick={() => setShowMap(!showMap)}
					>
						<p className="text-sm">Dev-mode{showMap ? ": På" : ""}</p>
					</button> */}
					<DepotSelector
						placeholder="Område"
						value={depot}
						items={Object.entries(SELECT_ITEMS)}
						onChange={setDepot}
					/>
				</div>
			</div>

			<div className="flex">
				<BusRamps vehicles={vehicles} depot={depot} extraData={true} showHelp={showHelp} />
			</div>
			<div className="self-center mt-auto flex flex-row">
				<p className="text-xs text-gray-500">Skapad av Artur Lindén</p>
			</div>
		</div>
	);
}

export const BusRamps = ({
	vehicles,
	depot,
	extraData,
	showHelp,
}: {
	vehicles: any;
	depot: any;
	extraData: boolean;
	showHelp: boolean;
}) => {
	if (!vehicles) {
		return <p>Laddar...</p>;
	}

	if (!DEPOT_FULL_CONTAINER[depot]) {
		const mapped_vehicle_ramps: any = {};

		Object.keys(vehicles).map((vehicle) => {
			const ramp = vehicles[vehicle].ramp;

			if (!mapped_vehicle_ramps[ramp]) {
				mapped_vehicle_ramps[ramp] = [];
			}

			mapped_vehicle_ramps[ramp].push({
				vehicle: vehicle,
				data: vehicles[vehicle],
				color: "text-red-400",
			});
		});

		return (
			<div className="flex flex-1 flex-row mt-2">
				{depot_pos[depot].map((ramp: any) => {
					if (ramp.name.length !== 1) {
						return null;
					}

					return (
						<div className="flex flex-1 flex-col pr-8 pl-4" key={"ramp-" + ramp.name}>
							<h3 className="font-semibold text-lg mb-2">{ramp.name}-ramp</h3>
							{[...Array(ramp.slots)].map((_, i) => {
								if (mapped_vehicle_ramps[ramp.name + (i + 1)] === undefined) {
									return null;
								}

								return (
									<div
										className="border-t border-y-stone-800 last:border-b flex flex-row"
										key={"ramp-" + ramp.name + "-slot-" + i}
									>
										<p className="text-sm min-w-[20px] mr-1 my-1 font-medium">
											{i + 1}
										</p>
										<div className="flex">
											{mapped_vehicle_ramps[ramp.name + (i + 1)]?.map(
												(vehicle: any) => {
													return (
														<div
															key={
																ramp.name +
																"ramp-veh-" +
																vehicle.vehicle
															}
															className={
																"mr-1 font-semibold flex flex-row items-end justify-center my-1 text-black px-1 rounded " +
																(busTypes[vehicle.vehicle].split(
																	"-"
																)[2] === "red"
																	? "bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-300"
																	: "bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300")
															}
														>
															<p className="font-semibold text-lg">
																{vehicle.vehicle}
															</p>
															{showHelp ? (
																<p className="text-xs mb-[4px] items-end">
																	{busTypes[vehicle.vehicle]
																		.split("-")[1]
																		.substring(0, 1)
																		.toUpperCase()}
																</p>
															) : null}
														</div>
													);
												}
											)}
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	}

	const vehicles_area = convertVehiclePerArea(vehicles, depot);

	return (
		<div className="flex flex-col mt-5 px-4">
			{vehicles_area.map((area: any) => (
				<div key={area[0]} className="flex flex-col mt-4">
					<h2 className="font-semibold text-2xl">{area[0]}</h2>
					<div className="flex flex-row flex-1 flex-wrap justify-start gap-2">
						{area[1].map((item: any) => {
							return (
								<div
									key={area[0] + item.vehicle}
									className={
										"text-center rounded font-semibold text-[22px] flex flex-row justify-between px-2 min-w-[114px] items-center " +
										(item?.color === "red"
											? "bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-300"
											: "bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300")
									}
								>
									<span className="flex flex-row justify-center items-center">
										<p className="flex">{item.vehicle}</p>
										{showHelp ? (
											<p className="text-xs mt-[10px] items-end">
												{busTypes[item.vehicle]
													.split("-")[1]
													.substring(0, 1)
													.toUpperCase()}
											</p>
										) : null}
									</span>
									{item.data?.ramp.length < 3 && item.data?.ramp !== "-" ? (
										<p className="text-xs">{item.data?.ramp}</p>
									) : null}
									<div className="flex flex-col justify-center items-end">
										{item?.data?.fuel ? (
											<p className={"flex text-xs font-bold"}>
												{parseInt(item?.data?.fuel)}{" "}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="7"
													height="7"
													viewBox="0 0 48 48"
													className={
														"mt-[2px] " +
														(item?.color === "red"
															? "fill-red-600 dark:fill-red-300"
															: "fill-blue-600 dark:fill-blue-300")
													}
												>
													<path d="M0 0h48v48h-48z" fill="none" />
													<path d="M39.54 14.46l.03-.03-7.45-7.43-2.12 2.12 4.22 4.22c-1.88.72-3.22 2.53-3.22 4.66 0 2.76 2.24 5 5 5 .71 0 1.39-.15 2-.42v14.42c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-2.21-1.79-4-4-4h-2v-14c0-2.21-1.79-4-4-4h-12c-2.21 0-4 1.79-4 4v32h20v-15h3v10c0 2.76 2.24 5 5 5s5-2.24 5-5v-19c0-1.38-.56-2.63-1.46-3.54zm-15.54 5.54h-12v-10h12v10zm12 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
												</svg>
											</p>
										) : null}
										{extraData && item?.data?.temp ? (
											<p
												className={
													"flex text-xs font-bold mt-[-5px] -my-0.5"
												}
											>
												{parseInt(item?.data?.temp)}°
											</p>
										) : null}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};
