import { useState } from "react";
import View from "../components/view";
import InputFileMulti from "../components/inputFileMulti";

const schedule = [
	{
		title: "Rad 1",
		items: [
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
			{
				type: "FRI",
				color: "GREEN",
				start: "2024-05-10",
				length: 60,
				desc: "",
			},
		],
	},
];

export default function Schedule() {
	const [files, setFiles] = useState<any[]>([]);

	return (
		<>
			<h1 className="mt-10 text-2xl font-bold">Schema</h1>

			<div className="flex flex-row">
				{schedule.map((row) => {
					return (
						<div className="flex">
							<p>{row.title}</p>
							<div className="flex flex-orw">
								{row.items.map((item) => (
									<div className=""></div>
								))}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}
