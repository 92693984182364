import { useState } from "react";
import InputFile from "../components/inputFile";
import { parseDriverReports } from "../helper/driverReports";
import { createTripsQuery } from "../helper/sql";

export default function DrivingReport() {
	const [driverTrips, setDriverTrips] = useState<any>(null);
	const [step, setStep] = useState<number>(0);
	const [res, setRes] = useState<any>(null);

	const handleSetDriverFile = (file: any) => {
		if (!file) {
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const bstr = evt.target.result;

				const trips = parseDriverReports(bstr);

				setDriverTrips(trips);
				setStep(1);
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			alert("Något gick fel");
		}
	};

	const handleSQLFile = (file: any) => {
		if (!file) {
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const sqlTrips = JSON.parse(evt.target.result);

				const SQL_TRIP_MAP: any = {};

				// Loop through sqlTrips and merge with driverstrip.
				for (let i = 0; i < sqlTrips.length; i++) {
					SQL_TRIP_MAP[
						sqlTrips[i].OperatingCalendarDay.split("T")[0] +
							sqlTrips[i].LineNameLong +
							sqlTrips[i].JourneyName
					] = sqlTrips[i];
				}

				const mergedTrips = driverTrips.map((trip: any, i: number) => {
					const ITEM = driverTrips[i];
					const MATCHED_TRIP = SQL_TRIP_MAP[ITEM.date + ITEM.line + ITEM.trip];

					const { permanentId, ...rest } = trip;

					if (MATCHED_TRIP) {
						let Avvikelse = Math.round(
							(new Date(MATCHED_TRIP.PlannedArrivalTime).getTime() -
								new Date(MATCHED_TRIP.ActualArrivalTime).getTime()) /
								1000
						);

						return {
							...rest,
							Avvikelse: Avvikelse > 10000 ? "Sluthållplats tid saknas" : Avvikelse,
						};
					}

					return {
						...trip,
						ActualArrivalTime: "",
						JourneyName: "",
						LineNameLong: "",
						OperatingCalendarDay: "",
						PlannedArrivalTime: "",
						Avvikelse: "-",
					};
				});

				let copyString = Object.keys(mergedTrips[0]).join("\t") + "\n";

				for (let i = 0; i < mergedTrips.length; i++) {
					copyString += Object.values(mergedTrips[i]).join("\t") + "\n";
				}

				setStep(2);
				setRes(copyString);
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			alert("Något gick fel");
		}
	};

	const writeToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(createTripsQuery(driverTrips));
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	const writeToClipboard2 = async () => {
		try {
			await navigator.clipboard.writeText(res);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	return (
		<>
			<h1 className="font-bold text-2xl mt-20">Körtidsrapport</h1>
			<p className="mb-3 text-sm">Skapa rapport om körtiderna för specifierade chaufförer</p>

			{step === 0 && (
				<>
					<p className="mt-6 text-sm font-semibold">
						Chaufförer med turer
						<span className="ml-1 text-gray-500 font-normal text-[10px]">(Hastus)</span>
					</p>

					<InputFile name="turdata" accept=".exp" onHandleFile={handleSetDriverFile} />
				</>
			)}

			{step === 1 && (
				<>
					<button
						onClick={() => writeToClipboard()}
						className="mt-16 text-xs font-medium py-3 px-12 bg-slate-200 self-center rounded hover:opacity-60"
					>
						Kopiera SQL Query
					</button>

					<p className="mt-6 text-sm font-semibold">
						Fil från SQL
						<span className="ml-1 text-gray-500 font-normal text-[10px]">(Hastus)</span>
					</p>
					<InputFile name="SQL" accept=".json" onHandleFile={handleSQLFile} />
				</>
			)}

			{step === 2 && (
				<>
					<p className="mt-6 text-sm font-semibold">Lyckades</p>
					<button
						onClick={() => writeToClipboard2()}
						className="mt-16 text-xs font-medium py-3 px-12 bg-slate-200 self-center rounded hover:opacity-60"
					>
						Kopiera resultat
					</button>
				</>
			)}

			{/* <p className="mt-6 text-sm font-semibold">
				Turdata
				<span className="ml-1 text-gray-500 font-normal text-[10px]">(CTS)</span>
			</p>

			<InputFile name="tripData" accept=".sql" onHandleFile={handleTripsFile} /> */}

			{/* <button
				onClick={() => {}}
				disabled={true}
				className="mt-16 text-xs font-medium py-3 px-12 bg-slate-200 self-center rounded disabled:bg-gray-100 disabled:text-gray-300 hover:opacity-60"
			>
				Nästa
			</button> */}
		</>
	);
}
