const busTypes: any = {
    "5000": "MAN-led-red",
    "5001": "MAN-led-red",
    "5002": "MAN-led-blue",
    "5003": "MAN-led-blue",
    "5004": "MAN-led-blue",
    "5005": "MAN-led-blue",
    "5006": "MAN-led-blue",
    "5007": "MAN-led-blue",
    "5008": "MAN-led-blue",
    "5009": "MAN-led-blue",
    "5010": "MAN-led-red",
    "5011": "MAN-led-red",
    "5012": "MAN-led-red",
    "5013": "MAN-led-red",
    "5014": "MAN-led-red",
    "5015": "MAN-led-red",
    "5016": "MAN-led-red",
    "5017": "MAN-led-red",
    "5018": "MAN-led-red",
    "5019": "MAN-led-red",
    "5020": "MAN-led-red",
    "5021": "MAN-led-red",
    "5022": "MAN-led-red",
    "5023": "MAN-led-red",
    "5024": "MAN-led-red",
    "5025": "MAN-led-red",
    "5026": "MAN-led-red",
    "5027": "MAN-led-red",
    "5028": "MAN-led-red",
    "5029": "MAN-led-red",
    "5030": "MAN-led-red",
    "5031": "MAN-led-red",
    "5032": "MAN-led-red",
    "5033": "MAN-led-red",
    "5034": "MAN-led-red",
    "5035": "MAN-led-red",
    "5036": "MAN-led-red",
    "5037": "MAN-led-red",
    "5038": "MAN-led-red",
    "5039": "MAN-led-red",
    "5040": "MAN-led-red",
    "5041": "MAN-led-red",
    "5042": "MAN-led-red",
    "5043": "MAN-led-red",
    "5044": "MAN-led-red",
    "5045": "MAN-led-red",
    "5046": "MAN-led-red",
    "5047": "MAN-led-red",
    "5048": "MAN-led-red",
    "5049": "MAN-led-red",
    "5050": "MAN-led-red",
    "5051": "MAN-led-red",
    "5052": "MAN-led-red",
    "5053": "MAN-led-red",
    "5054": "MAN-led-red",
    "5055": "MAN-led-red",
    "5056": "MAN-led-red",
    "5057": "MAN-led-red",
    "5058": "MAN-led-red",
    "5059": "MAN-led-red",
    "5060": "MAN-led-red",
    "5061": "MAN-led-red",
    "5062": "MAN-led-red",
    "5063": "MAN-led-red",
    "5064": "MAN-led-red",
    "5065": "MAN-led-red",
    "5066": "MAN-led-red",
    "5067": "MAN-led-red",
    "5068": "MAN-led-red",
    "5069": "MAN-led-red",
    "5070": "MAN-led-red",
    "5071": "MAN-led-red",
    "5072": "MAN-led-red",
    "5073": "MAN-led-red",
    "5074": "MAN-led-red",
    "5075": "MAN-led-red",
    "5076": "MAN-led-red",
    "5077": "MAN-led-red",
    "5078": "MAN-led-red",
    "5079": "MAN-led-red",
    "5080": "MAN-led-red",
    "5081": "MAN-led-red",
    "5082": "MAN-led-red",
    "5083": "MAN-led-red",
    "5084": "MAN-led-red",
    "5085": "MAN-led-red",
    "5086": "MAN-led-red",
    "5087": "MAN-led-red",
    "5088": "Volvo-normal-red",
    "5089": "Volvo-normal-red",
    "5090": "Volvo-normal-red",
    "5091": "Volvo-normal-red",
    "5092": "Volvo-normal-red",
    "5093": "Volvo-normal-red",
    "5094": "Volvo-normal-red",
    "5095": "Volvo-normal-red",
    "5096": "Volvo-normal-red",
    "5097": "Volvo-normal-red",
    "5098": "Volvo-normal-red",
    "5099": "Volvo-normal-red",
    "5100": "Volvo-normal-red",
    "5101": "Volvo-normal-red",
    "5102": "Volvo-normal-red",
    "5103": "Volvo-normal-red",
    "5104": "Volvo-normal-red",
    "5105": "Volvo-normal-red",
    "5106": "Volvo-boggi-red",
    "5107": "Volvo-boggi-red",
    "5108": "Volvo-boggi-red",
    "5109": "Volvo-boggi-red",
    "5110": "Volvo-boggi-red",
    "5111": "Volvo-boggi-red",
    "5112": "Volvo-boggi-red",
    "5113": "Volvo-boggi-red",
    "5114": "Volvo-boggi-red",
    "5115": "Volvo-boggi-red",
    "5116": "Volvo-boggi-red",
    "5117": "Volvo-boggi-red",
    "5118": "Volvo-boggi-red",
    "5119": "Volvo-boggi-red",
    "5120": "Volvo-boggi-red",
    "5121": "Volvo-boggi-red",
    "5122": "Volvo-boggi-red",
    "5123": "Volvo-boggi-red",
    "5124": "Volvo-boggi-red",
    "5126": "Volvo-boggi-red",
    "5127": "Volvo-boggi-red",
    "5128": "Volvo-boggi-red",
    "5129": "Volvo-boggi-red",
    "5130": "Volvo-boggi-red",
    "5131": "Volvo-boggi-red",
    "5132": "Volvo-boggi-red",
    "5133": "Volvo-boggi-red",
    "5134": "Volvo-boggi-red",
    "5135": "Volvo-boggi-red",
    "5136": "Volvo-boggi-red",
    "5137": "Volvo-boggi-red",
    "5138": "Volvo-boggi-red",
    "5139": "Volvo-boggi-red",
    "5140": "Volvo-boggi-red",
    "5141": "Volvo-boggi-red",
    "5142": "Volvo-boggi-red",
    "5143": "Volvo-boggi-red",
    "5144": "Volvo-boggi-red",
    "5145": "Volvo-boggi-red",
    "5146": "Volvo-boggi-red",
    "5147": "Volvo-boggi-red",
    "5148": "Volvo-boggi-red",
    "5150": "Volvo-boggi-red",
    "5151": "Volvo-boggi-red",
    "5152": "Volvo-boggi-red",
    "5153": "Volvo-boggi-red",
    "5154": "Volvo-boggi-red",
    "5155": "Volvo-boggi-red",
    "5156": "Volvo-boggi-red",
    "5157": "Volvo-boggi-red",
    "5158": "Volvo-boggi-red",
    "5159": "Volvo-boggi-red",
    "5160": "Volvo-boggi-red",
    "5161": "Volvo-boggi-red",
    "5162": "Volvo-boggi-red",
    "5163": "Volvo-boggi-red",
    "5164": "Volvo-boggi-red",
    "5165": "Volvo-boggi-red",
    "5166": "Volvo-boggi-red",
    "5167": "Volvo-boggi-red",
    "5168": "Volvo-boggi-red",
    "5169": "Volvo-boggi-red",
    "5170": "Volvo-boggi-red",
    "5171": "Volvo-boggi-red",
    "5172": "Volvo-boggi-red",
    "5173": "Volvo-boggi-red",
    "5174": "Volvo-boggi-red",
    "5175": "Volvo-boggi-red",
    "5176": "Volvo-boggi-red",
    "5177": "Volvo-boggi-red",
    "5178": "Volvo-boggi-red",
    "5179": "Volvo-boggi-red",
    "5180": "Volvo-boggi-red",
    "5181": "Volvo-boggi-red",
    "5182": "Volvo-boggi-red",
    "5183": "Volvo-dd-blue",
    "5184": "Volvo-dd-blue",
    "5185": "Volvo-dd-blue",
    "5186": "Volvo-dd-blue",
    "5187": "Volvo-dd-blue",
    "5188": "Volvo-dd-blue",
    "5189": "Volvo-dd-blue",
    "5190": "Volvo-dd-blue",
    "5191": "Volvo-dd-blue",
    "5192": "Volvo-dd-blue",
    "5193": "Volvo-dd-blue",
    "5194": "Volvo-dd-blue",
    "5195": "Volvo-dd-blue",
    "5196": "Volvo-dd-blue",
    "5197": "Merc-mb-red",
    "5201": "MAN-led-red",
    "5202": "MAN-led-red",
    "5203": "MAN-led-red",
    "5204": "MAN-led-red",
    "5205": "MAN-led-red",
    "5206": "MAN-led-red",
    "5207": "MAN-led-red",
    "5208": "MAN-led-red",
    "5209": "MAN-led-red",
    "5210": "MAN-led-red",
    "5211": "MAN-led-red",
    "5212": "MAN-led-red",
    "5213": "MAN-led-red",
    "5214": "MAN-led-red",
    "5215": "MAN-led-red",
    "5216": "MAN-led-red",
    "5217": "MAN-led-red",
    "5500": "MAN-led-blue",
    "5501": "MAN-led-blue",
    "5502": "MAN-led-blue",
    "5503": "MAN-led-blue",
    "5504": "MAN-led-blue",
    "5505": "MAN-led-blue",
    "5506": "MAN-led-blue",
    "5507": "MAN-led-red",
    "5508": "MAN-led-red",
    "5509": "MAN-led-red",
    "5510": "MAN-led-red",
    "5511": "MAN-led-red",
    "5512": "MAN-led-red",
    "5513": "MAN-led-red",
    "5514": "MAN-led-red",
    "5515": "MAN-led-red",
    "5516": "MAN-led-red",
    "5517": "MAN-led-red",
    "5518": "MAN-led-red",
    "5519": "MAN-led-red",
    "5520": "MAN-led-red",
    "5521": "MAN-led-red",
    "5522": "MAN-led-red",
    "5523": "MAN-led-red",
    "5524": "MAN-led-red",
    "5525": "MAN-led-red",
    "5526": "MAN-led-red",
    "5527": "MAN-led-red",
    "5528": "MAN-led-red",
    "5529": "MAN-led-red",
    "5530": "MAN-led-red",
    "5531": "MAN-led-red",
    "5532": "Volvo-normal-red",
    "5533": "Volvo-normal-red",
    "5534": "Volvo-normal-red",
    "5535": "Volvo-normal-red",
    "5536": "Volvo-normal-red",
    "5537": "Volvo-normal-red",
    "5538": "Volvo-normal-red",
    "5539": "Volvo-normal-red",
    "5540": "Volvo-normal-red",
    "5541": "Volvo-normal-red",
    "5542": "Volvo-normal-red",
    "5543": "Volvo-normal-red",
    "5544": "Volvo-normal-red",
    "5545": "Volvo-normal-red",
    "5546": "Volvo-normal-red",
    "5547": "Volvo-normal-red",
    "5548": "Volvo-normal-red",
    "5549": "Volvo-normal-red",
    "5550": "Volvo-normal-red",
    "5551": "Volvo-normal-red",
    "5552": "Volvo-normal-red",
    "5553": "Volvo-normal-red",
    "5554": "Volvo-normal-red",
    "5555": "Volvo-normal-red",
    "5556": "Volvo-normal-red",
    "5557": "Volvo-normal-red",
    "5558": "Volvo-normal-red",
    "5559": "Volvo-normal-red",
    "5560": "Volvo-boggi-blue",
    "5561": "Volvo-boggi-blue",
    "5562": "Volvo-boggi-blue",
    "5563": "Volvo-boggi-blue",
    "5564": "Volvo-boggi-blue",
    "5565": "Volvo-boggi-red",
    "5566": "Volvo-boggi-red",
    "5567": "Volvo-boggi-red",
    "5568": "Volvo-boggi-red",
    "5569": "Volvo-boggi-red",
    "5570": "Volvo-boggi-red",
    "5571": "Volvo-boggi-red",
    "5572": "Volvo-boggi-red",
    "5573": "Volvo-boggi-red",
    "5574": "Volvo-boggi-red",
    "5575": "Volvo-boggi-red",
    "5576": "Volvo-boggi-red",
    "5577": "Volvo-dd-blue",
    "5578": "Volvo-dd-blue",
    "5579": "Volvo-dd-blue",
    "5580": "Volvo-dd-blue",
    "5581": "Volvo-dd-blue",
    "5582": "Volvo-dd-blue",
    "5583": "Volvo-dd-blue",
    "5584": "Volvo-dd-blue",
    "5585": "Volvo-dd-blue",
    "5586": "Volvo-dd-blue",
    "5587": "Volvo-dd-blue",
    "5588": "Volvo-dd-blue",
    "5589": "Volvo-dd-blue",
    "5590": "Volvo-dd-blue",
    "5591": "Volvo-dd-blue",
    "5592": "Volvo-dd-blue",
    "5593": "Volvo-dd-blue",
    "5594": "Volvo-dd-blue",
    "5595": "Volvo-dd-blue",
    "5596": "Volvo-dd-blue",
    "5597": "Volvo-dd-blue",
    "5598": "Volvo-dd-blue",
    "5599": "Volvo-dd-blue",
    "5600": "Volvo-dd-blue",
    "5601": "Volvo-dd-blue",
    "5602": "Merc-mb-red",
    "5603": "Merc-mb-red",
    "5604": "BYD-el-red",
    "5605": "BYD-el-red",
    "5606": "BYD-el-red",
    "5607": "BYD-el-red",
    "5608": "BYD-el-red",
    "5609": "Merc-mb-red",
    "5610": "Merc-mb-red",
    "6600": "Volvo-boggi-red",
    "6601": "Volvo-boggi-red",
    "6602": "Volvo-boggi-red",
    "6603": "Volvo-boggi-red",
    "6604": "Volvo-boggi-red",
    "6605": "Volvo-boggi-red",
    "6606": "Volvo-boggi-red",
    "6607": "Volvo-boggi-red",
    "6608": "Volvo-boggi-red",
    "6609": "Volvo-boggi-red",
    "6610": "Volvo-boggi-red",
    "6611": "Volvo-boggi-red",
    "6612": "Volvo-boggi-red",
    "6613": "Volvo-boggi-red",
    "6614": "Volvo-boggi-red",
    "6615": "Volvo-boggi-red",
    "6616": "Volvo-boggi-red",
    "0443": "VDL-normal-red",
    "0444": "VDL-normal-red",
    "0445": "VDL-normal-red",
    "0446": "VDL-normal-red",
    "0447": "VDL-normal-red",
    "0448": "VDL-normal-red",
    "0449": "VDL-normal-red",
    "0450": "VDL-normal-red",
    "0451": "VDL-normal-red",
    "0452": "VDL-normal-red",
    "0453": "VDL-normal-red",
    "0454": "VDL-normal-red",
    "0455": "VDL-normal-red",
    "0456": "VDL-normal-red",
    "0457": "VDL-normal-red",
    "0458": "VDL-normal-red",
    "0400": "VDL-normal-red",
    "0401": "VDL-normal-red",
    "0402": "VDL-normal-red",
    "0403": "VDL-normal-red",
    "0404": "VDL-normal-red",
    "0405": "VDL-normal-red",
    "0406": "VDL-normal-red",
    "0407": "VDL-normal-red",
    "0408": "VDL-normal-red",
    "0409": "VDL-normal-red",
    "0410": "VDL-normal-red",
    "0411": "VDL-normal-red",
    "0412": "VDL-normal-red",
    "0413": "VDL-normal-red",
    "0414": "VDL-normal-red",
    "0415": "VDL-normal-red",
    "0416": "VDL-normal-red",
    "0417": "VDL-normal-red",
    "0418": "VDL-normal-red",
    "0419": "VDL-normal-red",
    "0420": "VDL-normal-red",
    "0421": "VDL-normal-red",
    "0422": "VDL-normal-red",
    "0423": "VDL-normal-red",
    "0424": "VDL-normal-red",
    "0425": "VDL-normal-red",
    "0426": "VDL-normal-red",
    "0427": "VDL-normal-red",
    "0428": "VDL-normal-red",
    "0429": "VDL-normal-red",
    "0430": "VDL-normal-red",
    "0431": "VDL-normal-red",
    "0432": "VDL-normal-red",
    "0433": "VDL-normal-red",
    "0434": "VDL-normal-red",
    "0435": "VDL-normal-red",
    "0436": "VDL-normal-red",
    "0437": "VDL-normal-red",
    "0438": "VDL-normal-red",
    "0439": "VDL-normal-red",
    "0440": "VDL-normal-red",
    "0441": "VDL-normal-red",
    "0442": "VDL-normal-red",
    "0459": "Scania-led-red",
    "0460": "Scania-led-red",
    "0461": "Scania-led-red",
    "0462": "Scania-led-red",
    "0463": "Scania-led-red",
    "0464": "Scania-led-red",
    "0465": "Scania-led-red",
    "0466": "Scania-led-red",
    "0467": "Scania-led-red",
    "0468": "Scania-led-red",
    "0469": "Scania-led-red",
    "0470": "Scania-led-red",
    "0471": "Scania-led-red",
    "0472": "Scania-led-red",
    "0473": "Scania-led-red",
    "0474": "Scania-led-red",
    "0475": "Scania-led-red",
    "0476": "Scania-led-red",
    "0477": "Scania-led-red",
    "0478": "Scania-led-red",
    "0479": "Scania-led-red",
    "0480": "Scania-led-red",
    "0481": "Scania-led-red",
    "0482": "Scania-led-red",
    "0483": "Scania-led-red",
    "0484": "Scania-led-red",
    "0485": "Scania-led-red",
    "0486": "Scania-led-red",
    "0490": "BYD-el-red",
    "0491": "BYD-el-red",
    "0492": "BYD-el-red",
    "0493": "BYD-el-red",
    "0494": "BYD-el-red"
}

export default busTypes;