import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import { useEffect } from "react";
import { safeUserStorage } from "../helper/storage";

const PATHS_WITHOUT_NAVBAR: any = {
	"/login": true,
};

export default function RootFull() {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const USER = safeUserStorage();

		if (USER === null) {
			navigate("/");
		}
	}, []);

	return (
		<div className="flex flex-1 flex-col md:flex-row w-full">
			{PATHS_WITHOUT_NAVBAR[location.pathname] ? null : <Navbar />}

			<div className="w-full mx-auto flex flex-1 flex-col box-border">
				{<Outlet />}
			</div>
		</div>
	);
}
