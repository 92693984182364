export const XLSX_KEYS_TO_KEEP = [
	"Avtal",
	"Period",
	"Trafikdygn",
	"Tur",
	"Linje",
	"StartTid",
	"Riktning",
	"Planerad sträcka",
	"Planerad tid",
	"Observerad sträcka",
	"Observerad tid",
	"Avgångar delvis utförda",
	"Completeness",
	"ControlActionName",
];

export function generateCTSReport(wsJSON: any[], trips: any[]) {
	const ROWS: any = [];

	for (let i = 0; i < wsJSON.length; i++) {
		const ROW = [];

		if (parseInt(wsJSON[i]["Avgångar helt utförda"]) !== 1) {
			for (let j = 0; j < XLSX_KEYS_TO_KEEP.length; j++) {
				ROW.push(wsJSON[i][XLSX_KEYS_TO_KEEP[j]] ?? "");
			}

			ROWS.push(ROW);
		}
	}

	const NEW_ROWS: any = [];

	for (let i = 0; i < ROWS.length; i++) {
		const ROW = ROWS[i];

		for (let j = 0; j < trips.length; j++) {
			if (trips[j].Route == ROW[4] && trips[j].Trip == ROW[3]) {
				const DATE = ROW[2].toString();
				const PRETTY_DATE = `${DATE.slice(0, 4)}-${DATE.slice(
					4,
					6
				)}-${DATE.slice(6, 8)}`;
				const PRETTY_START = `${PRETTY_DATE} ${ROW[5]} UTC`;

				let distance = parseInt(ROW[7]) - parseInt(ROW[9]);
				let time = parseInt(ROW[8]) - parseInt(ROW[10]);
				let type = "";

				if (distance < 0) {
					distance =
						parseInt(ROW[7]) *
						((100 - (isNaN(ROW[12]) ? 0 : parseInt(ROW[12]))) /
							100);
				}

				if (time < 0) {
					time =
						parseInt(ROW[8]) *
						((100 - (isNaN(ROW[12]) ? 0 : parseInt(ROW[12]))) /
							100);
				}

				// Check if
				if (parseInt(ROW[11]) === 0) {
					type = "Inställd avgång.";
				} else {
					type = "Delinställd avgång.";
				}

				NEW_ROWS.push([
					PRETTY_DATE,
					ROW[3],
					PRETTY_START,
					ROW[4],
					trips[j].PermanentNbr,
					ROW[0],
					trips[j]["Stop Description (Last )"] ?? "",
					type ?? "",
					"",
					"",
					"",
					(distance / 1000).toFixed(3).toString().replace(".", ","),
					Math.ceil(time / 60),
					"",
					ROW[12] !== "-" ? ROW[12] + "%" : "-",
					ROW[13] ?? "",
				]);

				break;
			}
		}
	}

	return NEW_ROWS;
}
