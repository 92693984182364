export default function CopyText({
	children,
	placeholder,
	value,
}: {
	children: any;
	placeholder: string;
	value: string;
}) {
	const handleClick = (e: any) => {
		e.preventDefault();
		navigator.clipboard.writeText(value);
		return false;
	};
	return (
		<div
			className="relative select-none group cursor-pointer hover:bg-gray-100 dark:hover:bg-stone-700 dark:text-black rounded p-0.5 -m-0.5"
			onClick={handleClick}
		>
			<span className="group-hover:block hidden font-medium text-slate-400 bg-gray-100 text-xs px-1 absolute rounded -top-3 dark:text-black dark:bg-stone-700">
				Kopiera {placeholder}
			</span>
			{children}
		</div>
	);
}
