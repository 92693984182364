import { useEffect, useState } from "react";

import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";

import "leaflet/dist/leaflet.css";

import { divIcon } from "leaflet";
import { prettyTime } from "../../helper/date";

const markerHtmlStyles = `
  background-color: #fff;
  border: solid 1px #000;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -0.5rem;
  top: -0.25rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  `;

const Icon = divIcon({
	iconAnchor: [0, 24],
	popupAnchor: [0, -36],
	html: `<span style="${markerHtmlStyles}" />`,
});

export default function DataFollowUpAuto({ tripsData }: { tripsData: any[] }) {
	const [step, setStep] = useState(0);

	useEffect(() => {
		const handleEsc = (event: any) => {
			if (tripsData.length !== 0) {
				if (event.key === "Enter") {
					setStep(step + 1);
				} else if (event.key.toLowerCase() === "x") {
					setStep(step + 1);
				}
			}
		};

		window.addEventListener("keydown", handleEsc);

		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, [step]);

	if (tripsData.length === 0) {
		return (
			<div className="flex flex-col mt-4 flex-1 items-center">
				<p className="self-start">
					Hämtar positionshistorik. <br />
					Detta kommer ta tid...
				</p>
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col mt-4">
				<div className="flex w-full flex-row mb-2 items-center">
					<p className="mr-6">
						<span className="px-2 py-0.5 text-2xl tracking-wide font-semibold">
							{step + 1}
							<span className="text-sm text-gray-600">/{tripsData.length}</span>
						</span>
					</p>
					<p className="mr-6">
						Linje <b>{tripsData[step]?.line}</b>
					</p>
					<p className="mr-6">
						Tur <b>{tripsData[step]?.trip}</b>
					</p>
					<p className="mr-6">
						Omlopp <b>{tripsData[step]?.omlopp}</b>
					</p>
					<p className="mr-6">
						Vagn <b>{tripsData[step]?.vehicle}</b>
					</p>
					<p className="mr-6">
						Fleetanalyzer-data{" "}
						<span
							className={
								"px-2 py-0.5 text-sm  border-2  font-semibold rounded " +
								(tripsData[step]?.positions.length === 0
									? "border-red-200 bg-red-100"
									: "border-green-200 bg-green-100")
							}
						>
							{tripsData[step]?.positions.length === 0 ? "Nej" : "Ja"}
						</span>
					</p>
				</div>
				<MapContainer
					center={[
						tripsData[step].positions[0].latitude,
						tripsData[step].positions[0].longitude,
					]}
					zoom={16}
				>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					{tripsData[step]?.positions.length !== 0
						? tripsData[step]?.positions.map((marker: any, i: number) => {
								console.log(marker);
								return (
									<Marker
										key={`${marker.latitude}-${i}-${marker.longitude}`}
										position={[marker.latitude, marker.longitude]}
										icon={Icon}
									>
										<Tooltip permanent>
											{prettyTime(new Date(marker.serverTimestamp).getTime())}
										</Tooltip>
									</Marker>
								);
						  })
						: null}
				</MapContainer>
				<p>
					<b>
						<i>Enter</i>
					</b>{" "}
					för att gå vidare
				</p>
				<p>
					<b>
						<i>X</i>
					</b>{" "}
					för att markera avvikelse
				</p>
			</div>
		</>
	);
}
