import { useState } from "react";
import View from "../components/view";
import InputFileMulti from "../components/inputFileMulti";

export default function MergeJSONFiles() {
	const [view, setView] = useState("start");
	const [files, setFiles] = useState<any[]>([]);

	const readFiles = (inputFiles: any) => {
		let tempFiles: Promise<any>[] = [];

		Array.from(inputFiles).map((file: any) => {
			const reader = new FileReader();

			const contentPromise = new Promise<any>((resolve) => {
				reader.onload = (event: any) => {
					if (!event || !event.target || !event.target.result) {
						return;
					}

					resolve({ file: event.target.result });
				};
			});
			tempFiles.push(contentPromise);
			reader.readAsBinaryString(file);
		});

		Promise.all(tempFiles).then((promises: any) => {
			let string = "";

			promises.map((promise: any) => {
				string += promise.file;
			});

			const newFiles = JSON.parse("[" + string + "]");

			setFiles([files, ...newFiles]);
			console.log("done")
		});
	};

	const downloadFiles = (file: any) => {
		const data = JSON.stringify(files);
		const blob = new Blob([data], { type: "text/plain" });
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "data.json";
		a.click();
		URL.revokeObjectURL(url);
	};

	return (
		<>
			<h1 className="mt-10 text-2xl font-bold">Datauppföljning</h1>
			<p className="mb-3 text-sm">
				Följ upp vilka turer som har genomförts och skapa turdata automatiskt
			</p>

			<View show={view === "start"}>
				<InputFileMulti name="files" accept=".json" onHandleFile={readFiles} />
				<button onClick={() => setView("complete")}>Go</button>
			</View>

			<View show={view === "complete"}>
				<button
					className="mt-3 self-start rounded bg-slate-200 px-6 py-3 text-xs font-semibold dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={downloadFiles}
				>
					Ladda ner filer
				</button>
			</View>
		</>
	);
}
