const EcoDrivingEC = [
	{
		driverGroupId: 109,
		driverGroupName: "Hans Magnusson 380018",
		driverGroupRevisionId: 217,
		performanceEvaluation: 0,
		performanceIndex: 34.0,
		overIdlingEvaluation: 0,
		overIdlingTimeMilliseconds: 137800477.0,
		overIdlingIndex: 5.0,
		overSpeedEvaluation: 2,
		overSpeedTimeMilliseconds: 1105259.0,
		overSpeedIndex: 0.0,
		aggressiveAccelerationEvaluation: 0,
		numberOfAggressiveAccelerations: 3349,
		aggressiveAccelerationsIndex: 9.9,
		coastingTimeEvaluation: 1,
		coastingTimeMilliseconds: 470532024.0,
		coastingTimeIndex: 14.5,
		harshBrakesEvaluation: 1,
		numberOfHarshBrakes: 6306,
		harshBrakesIndex: 18.7,
		aggressiveSideAccelerationsEvaluation: 1,
		numberOfAggressiveSideAccelerations: 10781,
		aggressiveSideAccelerationsIndex: 32.0,
		distanceTravelledKm: 33658.675,
		timeDrivingMilliseconds: 3235734208.0,
		loggedInTimeMilliseconds: 4566419527.0,
		revisionStartDate: "2023-10-31T23:00:00Z",
		drivingProfileId: 7,
		drivingProfileRevisionId: 40,
		drivingProfileName: "Stad",
	},
	{
		driverGroupId: 110,
		driverGroupName: "Vilson Gashi 380058",
		driverGroupRevisionId: 163,
		performanceEvaluation: 1,
		performanceIndex: 51.0,
		overIdlingEvaluation: 0,
		overIdlingTimeMilliseconds: 0.0,
		overIdlingIndex: 2.5,
		overSpeedEvaluation: 0,
		overSpeedTimeMilliseconds: 384163815.0,
		overSpeedIndex: 2.1,
		aggressiveAccelerationEvaluation: 1,
		numberOfAggressiveAccelerations: 7022,
		aggressiveAccelerationsIndex: 3.7,
		coastingTimeEvaluation: 1,
		coastingTimeMilliseconds: 2755727326.0,
		coastingTimeIndex: 14.8,
		harshBrakesEvaluation: 1,
		numberOfHarshBrakes: 29135,
		harshBrakesIndex: 15.5,
		aggressiveSideAccelerationsEvaluation: 2,
		numberOfAggressiveSideAccelerations: 24618,
		aggressiveSideAccelerationsIndex: 13.1,
		distanceTravelledKm: 187795.775,
		timeDrivingMilliseconds: 18665258657.0,
		loggedInTimeMilliseconds: 26334922845.0,
		revisionStartDate: "2023-10-31T23:00:00Z",
		drivingProfileId: 7,
		drivingProfileRevisionId: 40,
		drivingProfileName: "Stad",
	},
	{
		driverGroupId: 111,
		driverGroupName: "Kent Ullgren 380068",
		driverGroupRevisionId: 164,
		performanceEvaluation: 1,
		performanceIndex: 59.0,
		overIdlingEvaluation: 0,
		overIdlingTimeMilliseconds: 365681635.0,
		overIdlingIndex: 1.8,
		overSpeedEvaluation: 1,
		overSpeedTimeMilliseconds: 251542444.0,
		overSpeedIndex: 1.2,
		aggressiveAccelerationEvaluation: 1,
		numberOfAggressiveAccelerations: 7438,
		aggressiveAccelerationsIndex: 3.7,
		coastingTimeEvaluation: 1,
		coastingTimeMilliseconds: 2862625797.0,
		coastingTimeIndex: 14.0,
		harshBrakesEvaluation: 1,
		numberOfHarshBrakes: 29943,
		harshBrakesIndex: 14.9,
		aggressiveSideAccelerationsEvaluation: 2,
		numberOfAggressiveSideAccelerations: 22860,
		aggressiveSideAccelerationsIndex: 11.4,
		distanceTravelledKm: 200522.21,
		timeDrivingMilliseconds: 20423955498.0,
		loggedInTimeMilliseconds: 28678940165.0,
		revisionStartDate: "2023-10-31T23:00:00Z",
		drivingProfileId: 7,
		drivingProfileRevisionId: 40,
		drivingProfileName: "Stad",
	},
	{
		driverGroupId: 112,
		driverGroupName: "Anette Fält 380008",
		driverGroupRevisionId: 165,
		performanceEvaluation: 1,
		performanceIndex: 55.0,
		overIdlingEvaluation: 0,
		overIdlingTimeMilliseconds: 524799687.0,
		overIdlingIndex: 2.6,
		overSpeedEvaluation: 1,
		overSpeedTimeMilliseconds: 272373126.0,
		overSpeedIndex: 1.4,
		aggressiveAccelerationEvaluation: 1,
		numberOfAggressiveAccelerations: 7386,
		aggressiveAccelerationsIndex: 3.7,
		coastingTimeEvaluation: 1,
		coastingTimeMilliseconds: 2767689524.0,
		coastingTimeIndex: 13.9,
		harshBrakesEvaluation: 1,
		numberOfHarshBrakes: 31202,
		harshBrakesIndex: 15.8,
		aggressiveSideAccelerationsEvaluation: 2,
		numberOfAggressiveSideAccelerations: 28556,
		aggressiveSideAccelerationsIndex: 14.5,
		distanceTravelledKm: 197082.78,
		timeDrivingMilliseconds: 19862501631.0,
		loggedInTimeMilliseconds: 28368439325.0,
		revisionStartDate: "2023-10-31T23:00:00Z",
		drivingProfileId: 7,
		drivingProfileRevisionId: 40,
		drivingProfileName: "Stad",
	},
	{
		driverGroupId: 129,
		driverGroupName: "Najiib Said Nour 380078",
		driverGroupRevisionId: 191,
		performanceEvaluation: 0,
		performanceIndex: 36.0,
		overIdlingEvaluation: 0,
		overIdlingTimeMilliseconds: 0.0,
		overIdlingIndex: 4.0,
		overSpeedEvaluation: 1,
		overSpeedTimeMilliseconds: 172717893.0,
		overSpeedIndex: 1.8,
		aggressiveAccelerationEvaluation: 1,
		numberOfAggressiveAccelerations: 4375,
		aggressiveAccelerationsIndex: 4.5,
		coastingTimeEvaluation: 1,
		coastingTimeMilliseconds: 1156659720.0,
		coastingTimeIndex: 11.8,
		harshBrakesEvaluation: 0,
		numberOfHarshBrakes: 19919,
		harshBrakesIndex: 20.4,
		aggressiveSideAccelerationsEvaluation: 2,
		numberOfAggressiveSideAccelerations: 14925,
		aggressiveSideAccelerationsIndex: 15.3,
		distanceTravelledKm: 97421.69,
		timeDrivingMilliseconds: 9785721482.0,
		loggedInTimeMilliseconds: 13903922387.0,
		revisionStartDate: "2023-10-31T23:00:00Z",
		drivingProfileId: 7,
		drivingProfileRevisionId: 40,
		drivingProfileName: "Stad",
	},
	{
		driverGroupId: 134,
		driverGroupName: "Hossein Adl Kasravi ",
		driverGroupRevisionId: 198,
		performanceEvaluation: 1,
		performanceIndex: 54.0,
		overIdlingEvaluation: 0,
		overIdlingTimeMilliseconds: 560929914.0,
		overIdlingIndex: 3.1,
		overSpeedEvaluation: 0,
		overSpeedTimeMilliseconds: 708054532.0,
		overSpeedIndex: 4.0,
		aggressiveAccelerationEvaluation: 2,
		numberOfAggressiveAccelerations: 3930,
		aggressiveAccelerationsIndex: 2.0,
		coastingTimeEvaluation: 2,
		coastingTimeMilliseconds: 2656891965.0,
		coastingTimeIndex: 15.0,
		harshBrakesEvaluation: 1,
		numberOfHarshBrakes: 29420,
		harshBrakesIndex: 14.9,
		aggressiveSideAccelerationsEvaluation: 2,
		numberOfAggressiveSideAccelerations: 34757,
		aggressiveSideAccelerationsIndex: 17.7,
		distanceTravelledKm: 196837.39,
		timeDrivingMilliseconds: 17685019483.0,
		loggedInTimeMilliseconds: 25776667860.0,
		revisionStartDate: "2023-10-31T23:00:00Z",
		drivingProfileId: 7,
		drivingProfileRevisionId: 40,
		drivingProfileName: "Stad",
	},
];

export default EcoDrivingEC;
