import { useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import InputFile from "../components/inputFile";
import { getWSJSON } from "../helper/xlsx";
import { parseTripsCSV } from "../helper/csv";
import { vehicleTinder } from "../helper/network";
import { downloadJsonFile } from "../helper/downloadFile";
import { CustomMarker } from "../components/CustomMarker";

function TripTinder() {
	const [view, setView] = useState<"start" | "map">("start");

	return (
		<div className="flex flex-1 flex-col w-full relative">
			<div className="flex gap-2 justify-center absolute -left-[50px] right-0 mx-auto z-10">
				<div className="flex gap-2 mt-2 justify-center px-3 py-3 rounded  mx-auto w-auto z-10 self-center bg-white dark:bg-black items-center shadow-lg">
					<div
						className={
							"bg-stone-100 dark:bg-stone-800 px-4 py-1 rounded text-sm font-medium cursor-pointer" +
							(view === "start" ? " text-black dark:text-white" : " text-gray-200 dark:text-gray-400")
						}
						onClick={() => setView("start")}
					>
						Skapa historik
					</div>
					<div
						className={
							"bg-stone-100 dark:bg-stone-800 px-4 py-1 rounded text-sm font-medium cursor-pointer" +
							(view === "map" ? " text-black dark:text-white" : " text-gray-200 dark:text-gray-400")
						}
						onClick={() => setView("map")}
					>
						Granska historik
					</div>
				</div>
			</div>
			<div className="w-full flex flex-1">
				<Container view={view} setView={setView} />
			</div>
		</div>
	);
}

const Container = ({ view, setView }: any) => {
	const [hastusTrips, setHastusTrips] = useState<any[]>([]);
	const [tripsMissingData, setTripsMissingData] = useState<any[]>([]);
	const [vehicleData, setVehicleData] = useState<any>([]);
	const [selectedTrip, setSelectedTrip] = useState(0);

	const handleSlData = (file: any) => {
		if (!file) {
			return;
		}

		try {
			const reader = new FileReader();

			reader.onload = (event: any) => {
				// Parse data
				const bstr = event.target.result;

				const rows = getWSJSON(bstr);

				let tripsMissingData = [];

				for (let i = 0; i < rows.length; i++) {
					const row = rows[i];
					const line = parseInt(row["Linje"]);
					const trip = parseInt(row["Tur"]);

					if (parseInt(row["Completeness"]) !== 100 && row["Trafikdygn"] === 20240112) {
						tripsMissingData.push({
							date: row["Trafikdygn"],
							line,
							trip,
							completeness:
								row["Completeness"] === "-" ? 0 : parseInt(row["Completeness"]),
							cancelled: row["InställdAvgång"],
							controllActionName: row["ControlActionName"],
							plannedTime: row["Planerad tid"],
							observedTime: row["Observerad tid"],
							plannedDistance: row["Planerad sträcka"],
							observedDistance: row["Observerad sträcka"],
						});
					}
				}

				setTripsMissingData([...tripsMissingData, ...tripsMissingData]);
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			alert("Error");
		}
	};

	const handleTripsData = (file: any) => {
		if (!file) {
			alert("Error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const bstr = evt.target.result;

				const [_, parsed_trips_raw] = parseTripsCSV(bstr);

				setHastusTrips(parsed_trips_raw["20240112"]);
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			alert("Error");
		}
	};

	const handleNext = async () => {
		const tripsToFetch = [];

		for (let i = 0; i < tripsMissingData.length; i++) {
			const tripData = tripsMissingData[i];
			const line = tripData.line;
			const trip = tripData.trip;
			let vehicle: any = {};

			if (hastusTrips[line] && hastusTrips[line][trip]) {
				vehicle = hastusTrips[line][trip];
			} else {
				vehicle = { vehicle: "No-vehicle" };
			}

			tripsToFetch.push({
				...tripData,
				...vehicle,
			});
		}

		const vehicle_data = tripsToFetch.map((trip, i) => {
			const rawStart = trip.trip.toString().slice(1);
			let startHours = parseInt(rawStart.substring(0, 2), 10);
			let startMinutes = parseInt(rawStart.substring(2), 10);

			const start = new Date();
			start.setMonth(0);
			start.setDate(12);
			start.setHours(startHours);
			start.setMinutes(startMinutes);
			start.setSeconds(0);
			start.setMilliseconds(0);

			const end = new Date(start.getTime() + (trip.plannedTime + 18) * 1000);

			return { ...trip, start: start.getTime(), end: end.getTime() };
		});

		const vehicleHistory = (await vehicleTinder(vehicle_data)).data;

		downloadJsonFile(vehicleHistory);
	};

	const handleReview = (file: any) => {
		if (!file) {
			alert("Error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (evt: any) => {
				// Parse data
				const bstr = evt.target.result;

				const data = JSON.parse(bstr);

				setVehicleData(data);
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			alert("Error");
		}
	};

	if (view === "start") {
		return (
			<div className="flex flex-1 flex-col w-full justify-center items-center mx-10">
				<InputFile name="trips" accept=".exp" onHandleFile={handleTripsData} />

				<div className="w-full mt-2">
					<InputFile name="sldata" accept=".xlsx" onHandleFile={handleSlData} />
				</div>

				<button
					className="px-6 py-2 font-medium text-sm bg-stone-100 dark:bg-stone-900 rounded mt-4"
					onClick={() => handleNext()}
				>
					Nästa
				</button>
			</div>
		);
	}

	if (vehicleData.length === 0) {
		return (
			<div className="flex flex-1 flex-col w-full justify-center items-center mx-10">
				<InputFile name="trips" accept=".json" onHandleFile={handleReview} />
			</div>
		);
	}

	const middleTrip = Math.floor(vehicleData[selectedTrip].data.length / 2) ?? 0;

	return (
		<>
			<div className="flex gap-2 justify-center absolute right-2 top-2 mx-auto z-10 flex-col">
				<select
					name=""
					id=""
					className="bg-white appearance-none border-gray-300 border px-2 py-1 border-solid font-medium dark:bg-stone-950 dark:border-stone-600 dark:text-stone-300"
					onChange={(e) => setSelectedTrip(parseInt(e.target.value))}
				>
					{vehicleData.map((trip: any, i: number) => (
						<option key={i} value={i} className="" onClick={() => setSelectedTrip(i)}>
							L{trip ? trip?.line : ""}/{trip ? trip?.trip : null}
							{" " + (trip.data.length === 0 ? "X" : "")}
						</option>
					))}
				</select>
				<div className="bg-white dark:bg-black py-1 px-2 rounded text-xs">
					<p>
						<span className="font-medium">Completeness:</span>{" "}
						{vehicleData[selectedTrip].completeness}%
					</p>
					<p>
						<span className="font-medium">Vagn:</span>{" "}
						{vehicleData[selectedTrip].vehicles}
					</p>
				</div>
			</div>

			<MapContainer
				center={[
					vehicleData[selectedTrip]?.data[middleTrip]?.latitude ?? 50,
					vehicleData[selectedTrip]?.data[middleTrip]?.longitude ?? 50,
				]}
				zoom={13}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{vehicleData[selectedTrip].data.map((marker: any, i: number) => {
					if (!marker.latitude || !marker.longitude) {
						console.log(marker);
						return null;
					}

					const step = Math.ceil(255 / vehicleData[selectedTrip].data.length);

					console.log(step, i, step * i);

					return (
						<CustomMarker
							key={`${marker.latitude}-${i}-${marker.longitude}`}
							position={[marker.latitude, marker.longitude]}
							eventHandlers={{
								click: () => {
									console.log(JSON.stringify(marker, null, 4));
								},
							}}
						>
							<div
								className="rounded hover:bg-reed-200 z-40 group"
								style={{
									width: "10px",
									height: "10px",
									transform: "rotate(" + parseInt(marker.heading) + "deg)",
									border: marker.newItems
										? "5px solid green"
										: "1.5px solid black",
									backgroundColor: `rgba(255, ${255 - step * i}, ${step * i})`,
								}}
							>
								<p
									className="bg-white rounded w-40 font-bold hidden group-hover:block dark:bg-stone-950 dark:text-stone-300"
									style={{
										transform: "rotate(-" + marker.heading + "deg)",
									}}
								>
									{marker.time}
								</p>
							</div>
						</CustomMarker>
					);
				})}
			</MapContainer>
		</>
	);
};

export default TripTinder;
