import { useState } from "react";
import Modal from "../components/Modal";
import { fetchInfoPlaylists } from "../helper/network";
import postman from "../helper/postman";
import { Link, useNavigate } from "react-router-dom";
import Input from "../components/input";

export default function InfoPlaylists() {
	const { data, mutate } = fetchInfoPlaylists();
	const [showCreateDisplay, setShowCreateDisplay] = useState(false);

	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!name) {
			alert("Fyll i alla fält");
			return;
		}

		try {
			const res = await postman.post("/info/playlists", {
				name,
				description,
			});

			mutate((data: any) => [...data, res.data], false);

			// Navigate to the new display.
			navigate("/infoplaylists/" + res.data.Id);
		} catch (error: any) {
			// Check if network error or badrequest.
			if (error?.response?.status === 400) {
				alert("Något gick fel. Kontrollera att du har fyllt i alla fält korrekt");
			} else {
				alert("Något gick fel");
			}
		}
	};

	return (
		<div className="flex flex-1 flex-col">
			<Modal show={showCreateDisplay} closeModal={() => setShowCreateDisplay(false)}>
				<form
					className="flex flex-col justify-betweem w-full h-full items-center py-12 px-20"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Skapa spellista</h1>
						<p className="mt-2 text-slate-500 text-sm dark:text-stone-500">
							Fyll i fälten nedan för att skapa ett nytt spellista
						</p>
					</div>
					<div className="mt-6 w-full">
						<Input placeholder="Namn" value={name} onChange={setName} />
					</div>
					<div className="mt-6 w-full">
						<Input
							placeholder="Beskrivning"
							value={description}
							onChange={setDescription}
						/>
					</div>

					<div className="flex flex-row ] mt-6 justify-center items-center">
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
							value="Skapa spellista"
						/>
					</div>
				</form>
			</Modal>
			<h1 className="font-bold text-2xl mt-20 mb-3">Spellista</h1>
			<div className="flex justify-end">
				<div
					className="bg-blue-200 self-start px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => setShowCreateDisplay(true)}
				>
					Skapa spellista
				</div>
			</div>
			<div className="flex flex-1 flex-col justify-start items-center mt-6">
				{data ? (
					data?.map((playlist: any) => (
						<Link
							className="flex flex-row w-full justify-between items-center py-2 border-b border-gray-200 dark:border-stone-900 hover:bg-slate-100 dark:hover:bg-stone-900 px-4 cursor-pointer transition-all "
							key={"playlist-" + playlist.Id}
							to={"/infoplaylists/" + playlist.Id}
						>
							<div className="flex flex-col">
								<p className="text-sm text-slate-500">{playlist.Id}</p>
								<p className="font-semibold">{playlist.Name}</p>
								<p className="text-sm text-slate-500">{playlist.Description}</p>
								<p className="text-sm text-slate-500">{playlist.LastUpdatep}</p>
								<p className="text-sm text-slate-500">{playlist.LastUpdatedIp}</p>
								<p className="text-sm text-slate-500">{playlist.AddedBy}</p>
							</div>
						</Link>
					))
				) : (
					<p className="mt-10 text-slate-500 font-medium dark:text-stone-500">Inga spellistor skapade</p>
				)}
			</div>
		</div>
	);
}
