import useSWR from "swr";
import fetcher from "./fetcher";

const useFormatSWR = (url: string | null): any => {
	const { data, error, mutate } = useSWR(url, fetcher, {
		errorRetryCount: 1,
		errorRetryInterval: 4000,
		dedupingInterval: 10000,
		shouldRetryOnError: false,
	});

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
		notFound: error?.response?.status === 404,
	};
};

export default useFormatSWR;
