export const parseTripsCSV = (bstr: any) => {
	const trips = bstr.split("\n");
	const DATA: any = {};
	const DATES: any = [];

	for (let i = 0; i < trips.length; i++) {
		const row = trips[i].split(";");

		// Check if trip is on line.
		if (row.length !== 5) {
			continue;
		}

		const date = row[0].replaceAll("/", "");

		// If date does not exist yet.
		if (DATA[date] === undefined) {
			DATA[date] = {};
			DATES.push(date);
		}

		const DATA_DATE = DATA[date];

		let line = row[1].trim();

		// If empty is not line.
		if (line === "") {
			continue;
		}

		// Check if is valid line.
		if (line.length !== 3 && line.length !== 4) {
			continue;
		}

		let appendix = "";

		// Remove last letter on line.
		if (line.length === 4) {
			appendix = line.charAt(3);
			line = line.slice(0, 3);
		}

		// Check if is not a number.
		if (isNaN(line)) {
			continue;
		}

		// If line does not exist yet.
		if (DATA_DATE[line] === undefined) {
			DATA_DATE[line] = {};
		}

		const trip = row[2].trim();

		const new_vehicle = row[4].trim() ? row[4].trim() : "";

		DATA_DATE[line][trip] = {
			block: row[3].trim(),
			vehicles:
				DATA_DATE[line][trip] !== undefined
					? DATA_DATE[line][trip].vehicles + ", " + new_vehicle
					: new_vehicle,
		};

		if (appendix) {
			DATA_DATE[line][trip].appendix = appendix;
		}
	}

	return [DATES.sort(), DATA];
};

export const parseTripsCSVWithKeys = (bstr: any) => {
	const trips = bstr.split("\n");
	const DATA: any = {};

	for (let i = 0; i < trips.length; i++) {
		const row = trips[i].split(";");

		// Check if trip is on line.
		if (row.length !== 5) {
			continue;
		}

		const date = row[0].replaceAll("/", "");

		let line = row[1].trim();

		// If empty is not line.
		if (line === "") {
			continue;
		}

		// Check if is valid line.
		if (line.length !== 3 && line.length !== 4) {
			continue;
		}

		let appendix = "";

		// Remove last letter on line.
		if (line.length === 4) {
			appendix = line.charAt(3);
			line = line.slice(0, 3);
		}

		// Check if is not a number.
		if (isNaN(line)) {
			continue;
		}

		const trip = row[2].trim();

		const new_vehicle = row[4].trim() ? row[4].trim() : "";

		const keyTrip = `${date}-${line + appendix}-${trip}`;

		const newTrip: any = {
			date: date,
			line: `${line + appendix}`,
			trip: trip,
			block: row[3].trim(),
			vehicles:
				DATA[keyTrip] !== undefined
					? DATA[keyTrip].vehicles + ", " + new_vehicle
					: new_vehicle,
		};

		if (appendix) {
			newTrip.appendix = appendix;
		}

		DATA[keyTrip] = newTrip;
	}

	return DATA;
};
