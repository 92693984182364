import { fetchUsers } from "../helper/network";

import { Link, useNavigate } from "react-router-dom";
import { safeUserStorage } from "../helper/storage";

export default function TrafficTech() {
	const { data, mutate } = fetchUsers();
	const navigate = useNavigate();

	const user = JSON.parse(safeUserStorage() || "{}");

	return (
		<div className="flex flex-1 flex-col">
			<h1 className="font-bold text-2xl mt-20 mb-3">Trafikteknik</h1>
			<div className="flex justify-end">
				<div
					className={
						"bg-blue-200 self-start px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700 " +
						(user.role === "user" ? "hidden" : "")
					}
					onClick={() => navigate("/traffictech/create")}
				>
					Skapa meddelande
				</div>
			</div>
			<div className="flex flex-1 flex-col justify-start items-center mt-6">
				{false ? (
					data?.map((user: any) => (
						<Link
							className="flex flex-row w-full justify-between items-center py-2 border-b border-gray-200 hover:bg-slate-100 px-4 cursor-pointer transition-all"
							key={"user-" + user.Username}
							to={"/users/" + user.Username}
						>
							<div className="flex flex-col">
								<p className="text-sm text-slate-500">{user.Username}</p>
								<p className="font-semibold">
									{user.Firstname} {user.LastName}
								</p>
								<p className="text-sm text-slate-500">{user.Role}</p>
							</div>
						</Link>
					))
				) : (
					<p className="mt-10 text-slate-500 font-medium dark:text-stone-500">
						Inga trafikmeddelanden skapade
					</p>
				)}
			</div>
		</div>
	);
}
