export default function InputDate({
	value,
	onChange,
	placeholder,
}: {
	value: string;
	onChange: any;
	placeholder: string;
}) {
	return (
		<div className="relative select-none">
			<span className="font-medium text-slate-400 text-xs ml-1 px-1 absolute bg-white -top-2 dark:bg-black dark:text-stone-200">
				{placeholder}
			</span>
			<input
				type="date"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				className="bg-white border border-slate-200 py-1 px-6 appearance-none cursor-pointer dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
			/>
		</div>
	);
}
