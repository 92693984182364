import React, { useState } from "react";
import { Marker, MarkerProps } from "react-leaflet";
import ReactDOM from "react-dom/client";
import L, { divIcon } from "leaflet";

const Icon = divIcon({
	iconAnchor: [0, 0],
	iconSize: [1, 1],
});

interface Props extends MarkerProps {
	/**
	 * Options to pass to the react-lefalet L.divIcon that is used as the marker's custom icon
	 */
	iconOptions?: L.DivIconOptions;
  }
  

/**
 * React-leaflet marker that allows for fully interactive JSX in icon
 */
export const CustomMarker = React.forwardRef<L.Marker, Props>(
  ({ children, iconOptions, ...rest }, refInParent) => {
    const [ref, setRef]: any = useState<L.Marker>();

    const node: any = React.useMemo(
      () => (ref ? ReactDOM.createRoot(ref.getElement()) : null),
      [ref]
    );

    return (
      <>
        {React.useMemo(
          () => (
            <Marker
              {...rest}
              ref={(r) => {
                setRef(r as L.Marker);
                if (refInParent) {
                  // @ts-expect-error fowardref ts defs are tricky
                  refInParent.current = r;
                }
              }}
              icon={Icon}
            />
          ),
          []
        )}
        {ref && node.render(children)}
      </>
    );
  }
);
