import * as XLSX from "xlsx";

export const getWSJSON = (bstr: any, sheetIndex = 0): any[] => {
	const wb: any = XLSX.read(bstr, { type: "binary", cellStyles: true, cellDates: true });

	// Get first worksheet
	const wsname = wb.SheetNames[sheetIndex];
	const ws = wb.Sheets[wsname];

	return XLSX.utils.sheet_to_json(ws).map((row: any) => {
		const newRow: any = {};

		for (let key in row) {
			const newKey = key.replace(/\s/g, "");
			newRow[newKey] = row[key];
		}

		return newRow;
	});
};

export const createXLSXFile = (wb: any, fileName: string) => {
	XLSX.writeFileXLSX(wb, fileName, {
		cellStyles: true,
	});
};

export const createWBFromRows = (rows: any[], headers: any) => {
	const NEW_WS = XLSX.utils.aoa_to_sheet([headers, ...rows]);

	/* Create workbook */
	const NEW_WB = XLSX.utils.book_new();

	/* Add the worksheet to the workbook */
	XLSX.utils.book_append_sheet(NEW_WB, NEW_WS, "Blad1");

	return NEW_WB;
};

export const createXLSXCopyString = (rows: string[][]) => {
	let copyableTable = "";

	for (let i = 0; i < rows.length; i++) {
		copyableTable += `${rows[i].join("\t")}\n`;
	}

	return copyableTable;
};
