export default function Input({
	value,
	onChange,
	placeholder,
	type = "text",
	disabled = false,
	name = "",
	required = false,
}: {
	value: string;
	onChange: any;
	placeholder: string;
	type?: string;
	disabled?: boolean;
	name?: string;
	required?: boolean;
}) {
	return (
		<div className="relative select-none w-full">
			<span className="font-medium text-slate-400 text-xs ml-1 px-1 absolute bg-white -top-2 z-20 dark:bg-black dark:text-stone-200">
				{placeholder}
			</span>
			<input
				value={value}
				type={type}
				disabled={disabled}
				onChange={(e) => onChange(e.target.value, name)}
				className="bg-white border border-slate-200 py-2 px-2 appearance-none cursor-pointer w-full disabled:text-gray-500 dark:bg-stone-900 rounded dark:border-stone-900 dark:text-stone-200 dark:placeholder:text-stone-400"
				name={name}
				required={required}
			/>
		</div>
	);
}
