import Input from "./input";

const items = [
	[]
]

export default function UserPermissionSelector({
	value,
	onChange,
	placeholder,
	required = false,
	name = "",
}: {
	value: string;
	onChange: any;
	placeholder: string;
	required?: boolean;
	name?: string;
}) {
	return (
		<div className="relative select-none">
			
			<Input placeholder={placeholder} value={value} onChange={onChange} required={required} name={name} disabled />
		</div>
	);
}
