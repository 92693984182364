// parses CSV file in text format and returns an array of objects. Columns Date, line, trip, driver, permanentId.
type DriverReport = {
	date: string;
	line: string;
	trip: string;
	driver: string;
	permanentId: string;
};

export const parseDriverReports = (text: string): DriverReport[] => {
	const rows = text.split("\n");
	const driverReports: DriverReport[] = [];
	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];
		const [date, line, trip, driver, permanentId] = row
			.replaceAll("\r", "")
			.replace(/\s+/g, "")
			.split(";");

		driverReports.push({
			date: date.replaceAll("/", "-"),
			line,
			trip,
			driver,
			permanentId,
		});
	}

	return driverReports;
};
