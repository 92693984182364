const template_sql = `SELECT PJ.[OperatingCalendarDay], LineNameLong, JourneyName, PlannedArrivalTime, ActualArrivalTime FROM [I4M_EXTDB].[dbo].[PlannedJourneys] AS PJ
JOIN [I4M_EXTDB].[dbo].[Line] AS Lines ON PJ.[BelongsToLineId] = Lines.Id AND PJ.[OperatingCalendarDay] = Lines.[OperatingCalendarDay]
JOIN [I4M_EXTDB].[dbo].[Calls] AS Calls ON PJ.[Id] = [Calls].UsesPlannedJourneyId AND PJ.[OperatingCalendarDay] = Calls.[OperatingCalendarDay]
WHERE Calls.[PlannedDepartureTime] IS NULL
AND(`;

export const createTripsQuery = (trips: { line: string; trip: string; date: string }[]) => {
	let res = template_sql;

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];

		res += `(PJ.[OperatingCalendarDay] = '${trip.date} 00:00:00.000' AND Lines.[LineNameLong] = '${trip.line}' AND PJ.[JourneyName] = '${trip.trip}')`;
		if (i < trips.length - 1) {
			res += " OR ";
		} else {
			res += ")";
		}
	}

	return res;
};
